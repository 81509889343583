<app-mobile-header title="MANAGE USERS" [showBackLink]="true" backLink="/booking-management"></app-mobile-header>

<section id="submenu">
    <app-user-header id="main-submenu-links">
    </app-user-header>
</section>

<section id="page-manage-users">
    <app-user-filter></app-user-filter>
    <app-user-list (onPageEmit)="onPageEmit($event)" [p]="page" (onDeleteUser)="onDeleteUser($event)"
                   [totalItems]="totalItems" [userList]='users' (onResetPass)="onResetPassword($event)"></app-user-list>
</section>