<div>
  <header *ngIf="false === isUserLogin()" id="header">
    <a style="background: none;" href="/"
      ><img name="auslanLogo" alt="Auslan Connections" width="72" height="46" src="assets/img/auslan-logo.png" />
    </a>
  </header>
  <ng-container *ngIf="isValidModel()">
    <section id="submenu" *ngIf="checkUserAdminORBookOfficer()">
      <section>
        <a
          *ngIf="checkIsEditInterpreter() && model.employment_type === 'staff'"
          id="TABStaffCalendar"
          name="staff-calendar"
          [routerLink]="['/user-management/', userId, 'staff_calendar']"
        >
          Staff Calender</a
        >
        <a
          *ngIf="
            isUserDsqAdmin() &&
            (selectedRole === 'INTERPRETER' || selectedRole === 'INDIVIDUALCLIENT' || selectedRole === 'ORGANISATION')
          "
          id="interpreterPayroll"
          (click)="navigateToPayroll()"
        >
          Payroll</a
        >
      </section>
    </section>
    <section id="page-signup-organisation">
      <section>
        <header>
          <h1>
            {{ isUserLogin() === true ? (isDuplicate ? 'DUPLICATE' : isEdit ? 'EDIT' : 'CREATE') : 'SIGN UP AS' }}
            {{
              selectedRole === 'INDIVIDUALCLIENT'
                ? 'INDIVIDUAL CLIENT'
                : selectedRole === 'BOOKINGOFFICER'
                ? 'BOOKING OFFICER'
                : selectedRole === 'ORGANISATIONALREPRESENTATIVE'
                ? 'ORGANISATIONAL REPRESENTATIVE'
                : selectedRole === 'ORGANISATION'
                ? 'ORGANISATIONAL REPRESENTATIVE'
                : selectedRole
            }}
          </h1>
          <div class="edit-booking-subheader">
            <a (click)="goBack()" id="backBtn" *ngIf="isEdit">
              <span>&#8249;&#8249;</span> Back
            </a>
            <div [class.text-center]="!isEdit">
              Fields with * are required
            </div>
          </div>
        </header>

        <form #registerForm="ngForm" class="common-form reduced-bottom">
          <div class="lock-field"
              *ngIf="isLocked && isEdit && checkUserAdminORBookOfficer()">
              <div class="form-field">
                  <md-checkbox type="checkbox" name="lockStatus" [(ngModel)]="model.locked">
                      <span *ngIf="model.locked"><span class="fa fa-lock"></span> Locked</span>
                      <span *ngIf="!model.locked"><span class="fa fa-unlock"></span> Unlocked</span>
                  </md-checkbox>
              </div>
          </div>
          <div class="row small-collapse">
            <div class="row-wrapper">
              <div class="medium-6 columns">
                <div class="form-field">
                  <label>FIRST NAME * </label>
                  <input
                    type="text"
                    class="inline-icon inline-icon-user-circle valid"
                    placeholder="Enter your first name"
                    [(ngModel)]="model.first_name"
                    name="first_name"
                    #first_name="ngModel"
                    [rangeLength]="[2, 20]"
                    required
                  />
                  <span
                    *ngIf="first_name.touched && !first_name.errors?.required && !first_name.errors?.rangeLength"
                    class="inline-icon inline-icon-valid valid"
                  ></span>
                  <span
                    *ngIf="first_name.touched && (first_name.errors?.required || first_name.errors?.rangeLength)"
                    class="inline-icon error"
                  >
                    <span>Minimum 2 characters required.</span>!</span
                  >
                </div>
              </div>
              <div class="medium-6 columns">
                <div class="form-field">
                  <label>LAST NAME *</label>

                  <input
                    type="text"
                    class="inline-icon inline-icon-user-circle valid"
                    placeholder="Enter your last name"
                    [(ngModel)]="model.last_name"
                    name="last_name"
                    #last_name="ngModel"
                    [rangeLength]="[2, 30]"
                    required
                  />
                  <span
                    *ngIf="last_name.touched && !last_name.errors?.required && !last_name.errors?.rangeLength"
                    class="inline-icon inline-icon-valid valid"
                  ></span>
                  <span
                    *ngIf="last_name.touched && (last_name.errors?.required || last_name.errors?.rangeLength)"
                    class="inline-icon error"
                  >
                    <span>Minimum 2 characters required.</span>!</span
                  >
                </div>
              </div>
              <ng-container *ngIf="false === isEdit || isDuplicate">
                <div class="medium-12 columns password-rules">
                  <p>Please create a password with the following specifications:</p>
                  <ul>
                    <li>Is a minimum of 8 characters</li>
                    <li>Contains both upper and lower case characters (e.g., a-z, A-Z)</li>
                    <li>Includes numbers (0-9) - Includes special characters (e.g. @, #, $, *)</li>
                    <li>Cannot contain spaces, sequences or repeated characters</li>
                  </ul>
                </div>
                <div class="medium-6 columns">
                  <div class="form-field">
                    <label class="icon-lock"> PASSWORD *</label>
                    <input
                      id="pass1"
                      type="password"
                      placeholder="Enter your password"
                      [(ngModel)]="model.password"
                      name="password"
                      #password="ngModel"
                      required
                      minlength="8"
                      uppercase
                      lowercase
                      numeric
                      password
                      spaceChar
                      specialChar
                      [sequence]="3"
                      [repeatChar]="3"
                    />

                    <span *ngIf="password.touched && password.valid" class="inline-icon inline-icon-valid valid"></span>
                    <ng-container *ngIf="password.touched">
                      <span *ngIf="password.invalid" class="inline-icon error">
                        <span>Password does not meet requirements</span>!
                      </span>
                      <span *ngIf="password.errors?.required" class="inline-icon error">
                        <span>This field is required.</span>!
                      </span>
                    </ng-container>
                  </div>
                </div>
                <div class="medium-6 columns">
                  <div class="form-field">
                    <label class="icon-lock"> CONFIRM PASSWORD *</label>
                    <input
                      id="certainPassword"
                      name="certainPassword"
                      type="password"
                      placeholder="Confirm password"
                      [(ngModel)]="model.confirm_password"
                      name="certainPassword"
                      #certainPassword="ngModel"
                      [equalTo]="password"
                      required
                    />
                    <span
                      *ngIf="
                        certainPassword.touched &&
                        (!certainPassword.errors?.required && !certainPassword.errors?.equalTo)
                      "
                      class="inline-icon inline-icon-valid valid"
                    ></span>
                    <span
                      *ngIf="
                        certainPassword.touched && (certainPassword.errors?.required || certainPassword.errors?.equalTo)
                      "
                      class="inline-icon error"
                    >
                      <span>Password does not match</span>!
                    </span>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  selectedRole === 'INDIVIDUALCLIENT' ||
                  selectedRole === 'ORGANISATION' ||
                  selectedRole === 'INTERPRETER'
                "
              >
                <div class="medium-6 columns">
                  <div class="form-field">
                    <label
                      >GENDER
                      <span
                        *ngIf="selectedRole === 'INTERPRETER'"
                        mdTooltip="We would like to know your gender since some bookings require a Male or a Female"
                        ><i class="inline-icon-tip"></i></span
                    ></label>
                    <select [(ngModel)]="model.gender" name="gender" #gender="ngModel">
                      <option *ngFor="let item of ['Male', 'Female', 'Other']" [value]="item">
                        {{ item.toUpperCase() }}
                      </option>
                    </select>
                  </div>
                </div>
                <ng-container *ngIf="model.gender === 'Other'">
                  <div class="medium-6 columns">
                    <div class="form-field">
                      <label>SPECIFY YOUR GENDER</label>
                      <input
                        id="genderSpecify"
                        name="genderSpecify"
                        type="text"
                        placeholder="Please specify your gender"
                        [(ngModel)]="otherGender"
                        name="genderSpecify"
                        #genderSpecify="ngModel"
                      />
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="medium-6 columns">
                <div class="form-field">
                  <label class="icon-email"> EMAIL * </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    [(ngModel)]="model.email"
                    name="email"
                    #email="ngModel"
                    email
                    required
                  />
                  <span
                    *ngIf="email.touched && !email.errors?.email"
                    class="inline-icon inline-icon-valid valid"
                  ></span>
                  <span
                    *ngIf="(email.dirty || email.touched) && (email.errors?.required || email.errors?.email)"
                    class="inline-icon error"
                  >
                    <span>Email is required</span>!</span
                  >
                </div>
              </div>

              <div class="medium-6 columns">
                <div class="form-field">
                  <label class="icon-mobile">MOBILE PHONE *</label>
                  <input
                    type="tel"
                    placeholder="Enter your mobile"
                    [ngModel]="model.mobile | removeSpace"
                    (ngModelChange)="model.mobile = $event"
                    name="mobile"
                    #mobile="ngModel"
                    digits
                    [rangeLength]="[10, 11]"
                    required
                  />

                  <span *ngIf="mobile.touched && mobile.errors?.rangeLength" class="inline-icon error">
                    <span>Minimum 10 digits required.</span>!
                  </span>
                  <span *ngIf="mobile.touched && mobile.errors?.required" class="inline-icon error">
                    <span>Minimum 10 digits required.</span>!
                  </span>
                  <span *ngIf="mobile.touched && (mobile.errors?.digits || !model.mobile)" class="inline-icon error">
                    <span>Enter digits only.</span>!
                  </span>
                  <span
                    *ngIf="
                      mobile.touched &&
                      !mobile.errors?.rangeLength &&
                      !mobile.errors?.digits &&
                      model.mobile &&
                      model.mobile.trim().length
                    "
                    class="inline-icon inline-icon-valid valid"
                  ></span>
                </div>
              </div>
              <ng-container *ngIf="selectedRole === 'INDIVIDUALCLIENT' || selectedRole === 'ORGANISATION'">
                <div class="medium-6 columns">
                  <div class="form-field">
                    <label class="icon-mobile">PHONE * </label>
                    <input
                      type="tel"
                      placeholder="Enter your phone"
                      [ngModel]="model.phone | removeSpace"
                      (ngModelChange)="model.phone = $event"
                      name="phone"
                      #phone="ngModel"
                      digits
                      [rangeLength]="[10, 11]"
                      required
                    />

                    <span *ngIf="phone.touched && phone.errors?.rangeLength" class="inline-icon error">
                      <span>Minimum 10 digits required.</span>!
                    </span>
                    <span *ngIf="phone.touched && phone.errors?.required" class="inline-icon error">
                      <span>Minimum 10 digits required.</span>!
                    </span>
                    <span *ngIf="phone.touched && (phone.errors?.digits || !model.phone)" class="inline-icon error">
                      <span>Enter digits only.</span>!
                    </span>
                    <span
                      *ngIf="
                        phone.touched &&
                        !phone.errors?.rangeLength &&
                        !phone.errors?.digits &&
                        model.phone &&
                        model.phone.trim().length
                      "
                      class="inline-icon inline-icon-valid valid"
                    ></span>
                  </div>
                </div>
              </ng-container>
              <div [hidden]="false === isEdit || isDuplicate" class="medium-6 columns">
                <div class="form-field">
                  <label>STATUS</label>
                  <select name="selStatus" [(ngModel)]="selectedStatus">
                    <option *ngFor="let i of userStatusArray" [value]="i.name">{{ i.name }}</option>
                  </select>
                </div>
              </div>
              <div class="medium-6 columns">
                <div class="form-field">
                  <label>CHANGE PICTURE:</label>
                  <input type="file" id="filePicker" (change)="handleFileSelect($event)" />
                </div>
              </div>
              <div
                *ngIf="selectedRole !== 'BOOKINGOFFICER' && selectedRole !== 'ADMINISTRATOR'"
                class="medium-6 columns"
              >
                <div class="form-field">
                  <label>{{
                    selectedRole === 'INDIVIDUALCLIENT' || selectedRole === 'ORGANISATION'
                      ? 'IN WHICH STATE WILL MOST OF YOUR BOOKINGS TAKE PLACE * ?'
                      : 'WORK PREFERENCE *'
                  }}</label>
                  <select
                    aria-label="A"
                    [disabled]="isEdit"
                    [(ngModel)]="model.state_where_most_bookings_occur"
                    name="location_pref"
                    #location_pref="ngModel"
                    required
                  >
                    <option *ngFor="let loc_name of stateBookingOccur" [value]="loc_name">{{ loc_name }} </option>
                  </select>
                  <span
                    *ngIf="
                      location_pref.touched && !location_pref.errors?.required && !location_pref.errors?.rangeLength
                    "
                    class="inline-icon inline-icon-valid valid"
                  ></span>
                  <span
                    *ngIf="
                      location_pref.touched && (location_pref.errors?.required || location_pref.errors?.rangeLength)
                    "
                    class="inline-icon error"
                  >
                    <span>This field is required.</span>!
                  </span>
                </div>
              </div>
              <div *ngIf="checkUserAdminORBookOfficer() && selectedRole === 'INTERPRETER'" class="medium-6 columns">
                <div class="form-field">
                  <label>Staff/Casual</label>
                  <select
                    aria-label="A"
                    [(ngModel)]="model.employment_type"
                    name="employment_type"
                    #employment_type="ngModel"
                    required
                  >
                    <option *ngFor="let interpreter_type of ['staff', 'casual']" [value]="interpreter_type"
                      >{{ interpreter_type.toUpperCase() }}
                    </option>
                  </select>
                  <span
                    *ngIf="
                      employment_type.touched &&
                      !employment_type.errors?.required &&
                      !employment_type.errors?.rangeLength
                    "
                    class="inline-icon inline-icon-valid valid"
                  ></span>
                  <span
                    *ngIf="
                      employment_type.touched &&
                      (employment_type.errors?.required || employment_type.errors?.rangeLength)
                    "
                    class="inline-icon error"
                  >
                    <span>This field is required.</span>!
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  checkUserAdminORBookOfficer() &&
                  (selectedRole === 'INTERPRETER' || selectedRole === 'INDIVIDUALCLIENT')
                "
                class="medium-6 columns"
              >
                <div class="form-field">
                  <label>ACCOUNT NUMBER</label>
                  <input
                    type="text"
                    placeholder="Enter your account number"
                    [ngModel]="model.linked_account_number | removeSpace"
                    (ngModelChange)="model.linked_account_number = $event"
                    name="linked_account_number"
                    #linked_account_number="ngModel"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  checkUserAdminORBookOfficer() &&
                  isUserVicdeaf() &&
                  selectedRole === 'INTERPRETER' &&
                  model.employment_type === 'staff'
                "
                class="medium-6 columns"
              >
                <div class="form-field">
                  <label>STAFF ID</label>
                  <input
                    type="text"
                    placeholder="Enter your staff id"
                    [(ngModel)]="model.staff_id"
                    name="staff_id"
                    #staff_id="ngModel"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <app-org-rep
            [isDuplicate]="isDuplicate"
            [isEdit]="isEdit"
            [userModel]="model"
            [parentForm]="registerForm"
            #orgRepForm="ctOrgRepForm"
            *ngIf="selectedRole === 'ORGANISATION'"
          ></app-org-rep>
          <app-interpreter
            [displayCalendar]="isEdit && checkUserAdminORBookOfficer()"
            [parentForm]="registerForm"
            #interpreterForm="ctInterpreterForm"
            [userModel]="model"
            [canCalculateDistance]="true"
            *ngIf="selectedRole === 'INTERPRETER'"
          ></app-interpreter>
          <app-ind-client
            [parentForm]="registerForm"
            [userModel]="model"
            *ngIf="selectedRole === 'INDIVIDUALCLIENT'"
          ></app-ind-client>
          <br />

          <app-interpreter-box
            [isReadOnly]="isDuplicate"
            [isPreffered]="true"
            [isUserPage]="true"
            [selectedInterpreters]="model.prefferedInterpreters"
            [state_where_most_bookings_occur]="model.state_where_most_bookings_occur"
            *ngIf="selectedRole === 'ORGANISATION' || selectedRole === 'INDIVIDUALCLIENT'"
          ></app-interpreter-box>
          <br />
          <app-interpreter-box
            [isReadOnly]="isDuplicate"
            [isPreffered]="false"
            [isUserPage]="true"
            [selectedInterpreters]="model.prefferedInterpreters"
            [state_where_most_bookings_occur]="model.state_where_most_bookings_occur"
            *ngIf="selectedRole === 'ORGANISATION' || selectedRole === 'INDIVIDUALCLIENT'"
          ></app-interpreter-box>
          <br />
          <ng-container *ngIf="selectedRole === 'ORGANISATION' || selectedRole === 'INDIVIDUALCLIENT'">
            <h4 class="text-center" name="cert_heading">CERTIFICATIONS</h4>
            <div class="row">
              <p class="p-top-mrg" name="cert_required">
                Is one of the following required for the interpreters that will be working on your bookings? Please
                select multiple if relevant
              </p>
              <div class="row" id="user_cert-types">
                <div class="medium-3 columns">
                  <md-checkbox name="cb_blue_card" #cb_blue_card [(ngModel)]="model.blueCard">Blue CARD </md-checkbox>
                </div>
                <div class="medium-3 columns">
                  <md-checkbox name="cb_yellow_card" #cb_yellow_card [(ngModel)]="model.yellowCard">
                    YELLOW CARD
                  </md-checkbox>
                </div>
                <div class="medium-3 columns">
                  <md-checkbox name="cb_imminisations" #cb_imminisations [(ngModel)]="model.immunisations"
                    >IMMUNISATIONS
                  </md-checkbox>
                </div>
                <div class="medium-3 columns">
                  <md-checkbox name="cb_police_check" #cb_police_check [(ngModel)]="model.policeCheck"
                    >POLICE CHECK
                  </md-checkbox>
                </div>
                <div class="medium-3 columns">
                  <md-checkbox name="cb_form_27a" #cb_form_27a [(ngModel)]="model.form27A">FORM 27A </md-checkbox>
                </div>
                <div class="medium-3 columns">
                  <md-checkbox
                    name="cb_work_with_child_check"
                    #cb_work_with_child_check
                    [(ngModel)]="model.workWithChildCheck"
                    >WORKING WITH CHILDREN CHECK
                  </md-checkbox>
                </div>
              </div>
              <div class="medium-12 columns div-top-mrg">
                <div class="form-field">
                  <label name="certReqQue">Will the booking be recorded and published/distributed?</label>
                  <md-radio-group name="user_rdbookingRecorded" [(ngModel)]="model.isRecordedBooking">
                    <md-radio-button name="rdbookingRecordedYes" [value]="true">YES </md-radio-button>
                    <md-radio-button name="rdbookingRecordedNo" [value]="false">NO</md-radio-button>
                  </md-radio-group>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              checkUserAdminORBookOfficer() && (selectedRole === 'ORGANISATION' || selectedRole === 'INDIVIDUALCLIENT')
            "
          >
            <div class="medium-12 columns">
              <div class="form-field">
                <label>GENERAL NOTES</label>
                <textarea
                  type="text"
                  class="inline-icon inline-icon-txt"
                  [(ngModel)]="model.general_notes"
                  cols="30"
                  rows="10"
                  name="general_notes"
                >
                </textarea>
              </div>
            </div>
            <div class="medium-12 columns">
              <div class="form-field">
                <label>CLAIM NOTES</label>
                <textarea
                  type="text"
                  class="inline-icon inline-icon-txt"
                  [(ngModel)]="model.claim_notes"
                  cols="30"
                  rows="10"
                  name="claim_notes"
                >
                </textarea>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isDuplicate">
            <app-termandcondition
              (termAndConditionStatusChange)="tocChanged($event)"
              [termsAndConditionAccepted]="termsAndConditionAccepted"
            ></app-termandcondition>
          </ng-container>
          <div align="center">
            <button *ngIf="isUserLogin() === true"
              class="login button"
              name="register_user"
              type="submit"
              (click)="applyChanges(registerForm)"
              class="button">
              {{ isDuplicate ? 'Duplicate' : isEdit ? 'Edit' : 'Create' }}
            </button>
            <button *ngIf="isUserLogin() === false"
              [disabled]="registerForm.form.invalid"
              class="login button"
              name="register_user"
              type="submit"
              (click)="applyChanges(registerForm)"
              class="button">Register</button>
          </div>
        </form>
      </section>
    </section>
  </ng-container>
</div>
