<form #user_pass_form="ngForm" class="common-form reduced-top side-pad">
  <div class="row row-wrapper">
    <div class="medium-12 columns">
      <p *ngIf="resetPasswordMsg">
        <strong>
          <span *ngIf="hasTemporaryPassword">Your </span>
          <span *ngIf="!hasTemporaryPassword">Due to a security update from Sept 2020, your</span>
           password has been reset and needs to be changed. Please create
          a new password that follows the rules below. For more information about these changes and to see this
          information in Auslan, <a target="_blank" href="http://auslanconnections.com.au/whats-new/">click here</a>.
        </strong>
      </p>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label class="icon-lock"> CURRENT PASSWORD *</label>
        <input
          id="curr_pass"
          type="password"
          placeholder="Enter your password"
          [(ngModel)]="curr_password"
          name="curr_pass"
          #curr_pass="ngModel"
          required
        />

        <span
          *ngIf="curr_pass.touched && !curr_pass.errors?.required && curr_pass.errors?.rangeLength"
          class="inline-icon inline-icon-valid valid"
        ></span>
        <span
          *ngIf="curr_pass.touched && (curr_pass.errors?.required || curr_pass.errors?.rangeLength)"
          class="inline-icon error"
        >
          <span>Minimum 6 characters required.</span>!</span
        >
      </div>
    </div>
  </div>
  <div class="row row-wrapper">
    <div class="medium-6 columns password-rules medium-push-6">
      <p>Please create a password with the following specifications:</p>
      <ul>
        <li>Must be a minimum of 8 characters in length</li>
        <li>And contain both upper and lower case characters (e.g., a-z, A-Z)</li>
        <li>And include numbers (0-9)</li>
        <li>And include special characters (e.g. @, #, $, *)</li>
        <li>And does not contain spaces, sequences or repeated characters</li>
      </ul>
    </div>
    <div class="medium-6 columns medium-pull-6">
      <div class="form-field">
        <label class="icon-lock"> NEW PASSWORD *</label>
        <input
          id="pass"
          type="password"
          placeholder="Enter your password"
          [(ngModel)]="new_password"
          name="password"
          #password="ngModel"
          required
          minlength="8"
          uppercase
          lowercase
          numeric
          password
          spaceChar
          specialChar
          [sequence]="3"
          [repeatChar]="3"
        />

        <span *ngIf="password.touched && password.valid" class="inline-icon inline-icon-valid valid"></span>
        <ng-container *ngIf="password.touched">
          <span *ngIf="password.invalid" class="inline-icon error">
            <span>Password does not meet requirements</span>!</span
          >
          <span *ngIf="new_password === curr_password" class="inline-icon error">
            <span>The password cannot be same as old password</span>!
          </span>
          <span *ngIf="password.errors?.required" class="inline-icon error">
            <span>This field is required</span>!
          </span>
        </ng-container>
      </div>
    </div>
    <div class="medium-6 columns medium-pull-6">
      <div class="form-field">
        <label class="icon-lock"> NEW CONFIRM PASSWORD *</label>
        <input
          id="certainPassword"
          name="certainPassword"
          type="password"
          placeholder="Confirm password"
          [(ngModel)]="confirm_password"
          name="certainPassword"
          #certainPassword="ngModel"
          [equalTo]="password"
          required
        />
        <span
          *ngIf="certainPassword.touched && !certainPassword.errors?.required && !certainPassword.errors?.equalTo"
          class="inline-icon inline-icon-valid valid"
        ></span>
        <span
          *ngIf="certainPassword.touched && (certainPassword.errors?.required || certainPassword.errors?.equalTo)"
          class="inline-icon error"
        >
          <span>Password does not match</span>!</span
        >
      </div>
    </div>
  </div>

  <section class="actions"></section>
  <section>
    <button
      class="login button"
      name="edit_password"
      [disabled]="user_pass_form.invalid || new_password === curr_password"
      (click)="editUser(user_pass_form)"
    >
      SAVE
    </button>
  </section>
</form>
