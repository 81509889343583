<div class="separator" *ngIf="!isHidden"></div>
<h4 class="text-center" *ngIf="!isHidden">{{title}}</h4>
<section id="page-booking">
    <main>
        <form name="{{isPreffered ? 'interpreter_block_prefered' : 'interpreter_block_blocked'}}">
            <div class="row ext">
                <!-- div has no name property to bind -- big surprise -->
                <div class="form-field" *ngIf="!isHidden">
                    <label>DO YOU WANT TO ADD {{isHidden ? 'MORE' : ''}} {{isPreffered ? 'PREFERED' : 'BLOCKED'}} INTERPRETERS?
                        <span mdTooltip="You can add prefered or blocked interpreters in this section." ><i class="inline-icon-tip"></i></span>
                    </label>

                    <md-radio-group
                            name="{{'rdNeedInterpreter_' + isPreffered}} "
                                    [(ngModel)]="needInterpreter">
                        <md-radio-button [disabled]="isReadOnly" id="{{'yes_' + (isPreffered ? 'prefered' : 'blocked')}}"
                                [ngClass]="{'btnYes_prefered' : isPreffered ,
                         'btnYes_blocked' : !isPreffered}" [value]=true>YES</md-radio-button>
                        <md-radio-button [disabled]="isReadOnly" id="{{'no_' + (isPreffered ? 'prefered' : 'blocked')}}" [ngClass]="{'btnNo_prefered' : isPreffered ,
                         'btnNo_blocked' : !isPreffered}"  [value]=false>NO</md-radio-button>
                    </md-radio-group>
                </div>
                <section id="booking-preferences" name="booking-preferences">
                    <ng-container *ngFor="let interpreter of selectedInterpreters; let i = index">
                        <ng-container *ngIf="interpreter._destroy !== 1">
                        <section [ngClass]="{'interpreter_selected_preferred' : isPreffered === true,
                        'interpreter_selected_blocked' : isPreffered === false}"
                                 *ngIf="checkInterpreterPreference(interpreter)">
                            <button *ngIf="isEditable" name="{{'btnRemoveInterpreter_' + (isPreffered ? 'prefered_' : 'blocked_')+ i}}" (click)="removeInterpreter(interpreter)" type="button"><i class="icon-close"></i></button>
                            <strong>INTERPRETER {{getIndex(interpreter)}}</strong>
                            <figure>

                                <img class="ImgSize" src="{{interpreter.photo_url}}" alt="">
                            </figure>
                            <span>{{interpreter.first_name}} {{interpreter.last_name}}</span>
                    </section>
                        </ng-container>
                    </ng-container>
                    <button *ngIf="needInterpreter && isEditable"
                            name="{{'btnManageInterpreter_' + (isPreffered ? 'preferred' : 'blocked')}}"
                            (click)="manageInterpreter()">
                        <i class="inline-icon-add-file"></i>
                        ADD INTERPRETERS
                    </button>

                </section>
            </div>
        </form>
    </main>
</section>
