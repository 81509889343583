<a *ngIf="needInterpreter && isEditable" name="{{'btnManageInterpreter_' + (isPreferred ? 'preferred' : 'blocked')}}" href="#" (click)="$event.preventDefault(); manageInterpreter()"><span class="circus-add">+</span> Add interpreters</a><br />
<div class="col-wrapper">
    <div class="row-interpreter" *ngFor="let interpreter of selectedInterpreters; let i = index">
        <ng-container *ngIf="interpreter._destroy !== 1 && checkInterpreterPreference(interpreter)">
            <section [ngClass]="{'interpreter_selected_preferred' : isPreferred === true,
                        'interpreter_selected_blocked' : isPreferred === false}">
                <img [src]="interpreter.photo_url" alt="">
                <div class="interpreter"><span>Interpreter</span>{{interpreter.first_name}} {{interpreter.last_name}}</div>
                <a href="#" *ngIf="isEditable" name="{{'btnRemoveInterpreter_' + (isPreferred ? 'preferred_' : 'blocked_')+ i}}" (click)="$event.preventDefault(); removeInterpreter(interpreter)" class="circus-del">✖</a>
            </section>
        </ng-container>
    </div>
</div>
