<section id="filters">
  <section class="search-bar">
    <form #filterBySearch="ngForm" class="common-form compact" (ngSubmit)="filter()" novalidate>
      <input type="text" name="search" id="search" (blur)="filter()" [(ngModel)]="extRefNumber" (click)="'' !== extRefNumber && clear()" placeholder="Search External Ref No">
    </form>
    <button>
      <i (click)="filter()" class="icon-glass"></i>
    </button>
  </section>
</section>
