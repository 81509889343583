<header id="header">
    <a style="background: none;" href="/"><img name="auslanLogo" alt="Auslan Connections" width="72" height="46"
                                               src="assets/img/auslan-logo.png">
    </a>
</header>

<header id="header-mobile">
    <a style="background: none;" href="/"><img name="auslanLogo" alt="Auslan Connections" width="72" height="46"
                                               src="assets/img/auslan-logo.png">
    </a>
</header>
<section class="page-white-bg" style="padding: 0px 16px;" id="page-signup-organisation">
    <form class="common-form reduced-bottom" #resetForm="ngForm">

        <div class="row small-collapse">
            <div class="row-wrapper">

                <div class="medium-12 columns">
                    <div class="heading">
                        <h1 class="title">RESET PASSWORD FORM</h1>
                        <p class="subtitle">Fields with * are required</p>
                    </div>
                </div>
            </div>
            <div class="small-12 medium-4 columns">
                <div class="form-field">

                    <label><i class="icon-email"></i><span> EMAIL</span></label> <input type="email"
                                                                                        placeholder="Enter your email"
                                                                                        [(ngModel)]="emailAddress"
                                                                                        name="email" #email="ngModel"
                                                                                        email required> <span
                        *ngIf="email.touched && (!email.errors?.required && !email.errors?.email);"
                        class="inline-icon inline-icon-valid valid"></span> <span
                        *ngIf="(email.dirty || email.touched) && (email.errors?.required || email.errors?.email);"
                        class="inline-icon error"> <span>This field is
									required.</span>!
					</span>


                </div>
            </div>
        </div>


        <div class="row small-collapse">
            <div class="row-wrapper">

                <div class="medium-8 columns">
                    <div class="form-field">

                        <button name="reset_user" class="login" [disabled]="email.errors" (click)="resetUser()">
                            Reset Password
                        </button>

                    </div>
                </div>

            </div>
        </div>

    </form>
</section>