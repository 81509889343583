<div class="md-dialog">
	<md-dialog-content>
		<header>
			<h1>{{ title }}</h1>
			<button (click)="closeDialog(closeVal, true)" name='cancel-popup'><i class="icon-close"></i></button>
		</header>
		<main>
			<div>
				<p>{{popupMessage}}</p>
			</div>
			<app-cancel-reason-popup *ngIf="needReasonComponent" (cancellationReason)="cancellationReasonHandler($event)"></app-cancel-reason-popup>
		</main>
	</md-dialog-content>

	<div>
		<md-dialog-actions>
			<button *ngIf="cancelTitle.length > 0" name="noBtn"  (click)="closeDialog(false, false)" class="button red"> {{cancelTitle}} </button>
			<button name="yesBtn" (click)="closeDialog(true, false)" class="button"> {{okTitle}} </button>
		</md-dialog-actions>
	</div>
</div>
