<app-mobile-header
  title="CHANGE PASSWORD"
  [showBackLink]="true"
  backLink="/user-management/profile"
></app-mobile-header>

<section id="submenu">
  <app-user-header id="main-submenu-links"> </app-user-header>
</section>

<div id="page-common">
  <main>
    <app-user-password-form></app-user-password-form>
  </main>
</div>
