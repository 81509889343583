<form ngForm="calculationFields" #calculationFields="ngForm">
  <footer class="payroll-card__footer common-form">
    <div class="payroll-card__measure">
      <span class="payroll-card__measure-name">Inter. rate *</span>
      <div class="form-field">
        <input type="text"
               name="{{(isClient ? 'client_' : 'interpreter_') + 'interpreterRateOrd_' + index}}"
               #interpreterRateOrd="ngModel"
               [readonly]="isReadOnly"
               [placeholder]="interpRateOrdPlaceholder"
               class="payroll-card__measure-input"
               [ngModel]="payrollInvoice?.interpreting_rate"
               (ngModelChange)="payrollInvoice.interpreting_rate = $event"
               pattern="\$?(\d+|\d+(\.)\d+)">
        <span *ngIf="interpreterRateOrd.touched && (interpreterRateOrd.errors?.pattern);" class="inline-icon error">
          <span>Oops! Only numerical values and "." are allowed.</span>!
        </span>
      </div>
    </div>
    <div class="payroll-card__measure">
      <span class="payroll-card__measure-name">Prep. rate *</span>
      <div class="form-field">
        <input type="text"
               name="{{(isClient ? 'client_' : 'interpreter_') + 'prepRate_' + index}}"
               #prepRate="ngModel"
               [readonly]="isReadOnly"
               [placeholder]="prepRatePlaceholder"
               class="payroll-card__measure-input"
               [ngModel]="payrollInvoice?.preparation_rate"
               (ngModelChange)="payrollInvoice.preparation_rate = $event"
               pattern="\$?(\d+|\d+(\.|\:)\d+)">
        <span *ngIf="prepRate.touched && (prepRate.errors?.pattern);" class="inline-icon error">
          <span>Oops! Only numerical values and '.' are allowed.</span>!
        </span>
      </div>
    </div>
    <div class="payroll-card__measure">
      <span class="payroll-card__measure-name">Travel rate *</span>
      <div class="form-field">
        <input type="text"
               name="{{(isClient ? 'client_' : 'interpreter_') + 'travel_' + index}}"
               #travelRate="ngModel"
               [readonly]="isReadOnly"
               [placeholder]="travelRatePlaceholder"
               class="payroll-card__measure-input"
               [ngModel]="payrollInvoice?.travel_rate"
               (ngModelChange)="payrollInvoice.travel_rate = $event"
               pattern="\$?(\d+|\d+(\.|\:)\d+)">
        <span *ngIf="travelRate.touched && (travelRate.errors?.pattern);" class="inline-icon error">
          <span>Oops! Only numerical values and "." are allowed.</span>!
        </span>
      </div>
    </div>
    <div class="payroll-card__measure">
      <span class="payroll-card__measure-name">Total</span>
      <div class="form-field">
        <input type="text"
               name="{{(isClient ? 'client_' : 'interpreter_') + 'total_' + index}}"
               #total="ngModel"
               [readonly]="true"
               [placeholder]="totalPlaceholder"
               class="payroll-card__measure-input"
               [ngModel]="payrollInvoice?.total"
               (ngModelChange)="payrollInvoice.total = $event"
               pattern="\$?(\d+|\d+(\.)\d+)">
        <span *ngIf="total.touched && (total.errors?.pattern);" class="inline-icon error">
          <span>Oops! Only numerical values and '.' are allowed.</span>!
        </span>
      </div>
    </div>
  </footer>
  <footer class="payroll-card__footer common-form">
    <div class="payroll-card__measure" *ngIf='isNG'>
      <span class="payroll-card__measure-name">Inter. rate NG</span>
      <div class="form-field">
        <input type="text"
               name="{{(isClient ? 'client_' : 'interpreter_') + 'interRateNG_' + index}}"
               #interRateNG="ngModel"
               [readonly]="isReadOnly"
               [placeholder]="totalPlaceholder"
               class="payroll-card__measure-input"
               [ngModel]="payrollInvoice?.interpreting_night_rate"
               (ngModelChange)="payrollInvoice.interpreting_night_rate = $event"
               pattern="\$?(\d+|\d+(\.)\d+)">
        <span *ngIf="interRateNG.touched && (interRateNG.errors?.pattern);" class="inline-icon error">
          <span>Oops! Only numerical values and '.' are allowed.</span>!
        </span>
      </div>
    </div>
    <div class="payroll-card__measure" *ngIf='isWKN'>
      <span class="payroll-card__measure-name">Inter. rate WKN</span>
      <div class="form-field">
        <input type="text"
               name="{{(isClient ? 'client_' : 'interpreter_') + 'interRateWKN_' + index}}"
               #interRateWKN="ngModel"
               [readonly]="isReadOnly"
               [placeholder]="totalPlaceholder"
               class="payroll-card__measure-input"
               [ngModel]="payrollInvoice?.interpreting_weekend_rate"
               (ngModelChange)="payrollInvoice.interpreting_weekend_rate = $event"
               pattern="\$?(\d+|\d+(\.)\d+)">
        <span *ngIf="interRateWKN.touched && (interRateWKN.errors?.pattern);" class="inline-icon error">
          <span>Oops! Only numerical values and '.' are allowed.</span>!
        </span>
      </div>
    </div>
  </footer>
</form>
