<app-mobile-header
  title="{{ bookingHeading }}"
  backLink="/booking-management"
  [showBackLink]="true"
></app-mobile-header>
<section id="booking-details">
  <app-booking-header
    *ngIf="!isNewBooking()"
    [showButtons]="false"
    [bookingModel]="bookingModel"
    [oldModel]="oldBookingModel"
  ></app-booking-header>
  <div class="background-fill"></div>
  <div class="header">
    <section>
      <h4>{{ bookingHeading }}</h4>
      <a name="step-1" href="#" [class.active]="steps.isActive(1)" (click)="$event.preventDefault(); stepSet(1)">
        <span>01</span> Customer
      </a>
      <a name="step-2" href="#" [class.active]="steps.isActive(2)" (click)="$event.preventDefault(); stepSet(2)">
        <span>02</span> Type
      </a>
      <a name="step-3" href="#" [class.active]="steps.isActive(3)" (click)="$event.preventDefault(); stepSet(3)">
        <span>03</span> Appointment
      </a>
      <a name="step-4" href="#" [class.active]="steps.isActive(4)" (click)="$event.preventDefault(); stepSet(4)">
        <span>04</span> Details
      </a>
      <a name="step-5" href="#" [class.active]="steps.isActive(5)" (click)="$event.preventDefault(); stepSet(5)">
        <span>05</span> Deaf Client
      </a>
      <a name="step-6" href="#" [class.active]="steps.isActive(6)" (click)="$event.preventDefault(); stepSet(6)">
        <span>06</span> Preferences
      </a>
      <a name="step-7" href="#" [class.active]="steps.isActive(7)" (click)="$event.preventDefault(); stepSet(7)">
        <span>07</span> Billing details
      </a>
    </section>
  </div>
  <section>
    <div class="progress-bar" [style.width.%]="steps.progress"></div>

    <form (ngSubmit)="onCreateBooking()">
      <form [hidden]="!steps.isActive(1)" #bookingFormStep1="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Customer
                <span class="title-link"
                  ><span class="hidden-mobile">fields with</span> <span class="red"> *</span> are required</span
                >
              </div>
              <div class="form content">
                <label *ngIf="isDisableForClientOrgBookOfficer">
                  <span
                    >This booking can’t be changed anymore. Please contact the booking office<br />in case changes to
                    this booking are needed. <br /><br
                  /></span>
                </label>
                <label *ngIf="!isUserOrgRepORIndClientTemp()">
                  Are you making a booking for an individual client or organisation? <span class="red">*</span>
                </label>
                <fieldset name="bookingDetailFieldset" [disabled]="isDisableForClientOrgBookOfficer">
                  <div class="medium-12">
                    <div class="row-wrapper">
                      <div class="checkbox-group">
                        <div class="row-user">
                          <img src="assets/img/client.png" id="client" alt="client" />
                          <input
                            type="radio"
                            id="bookingForIndividualClient"
                            name="IndividualClient"
                            value="IndividualClient"
                            [(ngModel)]="bookingModel.bookable_type"
                            (change)="onBookingForChange()"
                            [disabled]="!(!forEdit && isUserAdminORBookOfficer) || isDisabledForAdmin"
                          />
                          <label for="bookingForIndividualClient">Client</label>
                        </div>
                        <div class="row-user">
                          <img src="assets/img/organization.png" id="organization" alt="organization" />
                          <input
                            type="radio"
                            id="bookingForOrganisationalRepresentative"
                            name="OrganisationalRepresentative"
                            value="OrganisationalRepresentative"
                            [(ngModel)]="bookingModel.bookable_type"
                            (change)="onBookingForChange()"
                            [disabled]="!(!forEdit && isUserAdminORBookOfficer) || isDisabledForAdmin"
                          />
                          <label for="bookingForOrganisationalRepresentative">Organisation</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separate"></div>
                  <div class="form-field">
                    <label *ngIf="!isUserOrgRepORIndClientTemp()">
                      Please select the
                      {{ bookingModel.bookable_type === 'IndividualClient' ? 'client' : 'organisation' }}
                      <span class="red"> * </span>
                      <span mdTooltip="Select the Client/Org"><span class="inline-icon-tip"></span></span>
                    </label>
                    <p-autoComplete
                      name="bookable"
                      [style]="{width: '100%'}"
                      required
                      [disabled]="!(!forEdit && isUserAdminORBookOfficer)"
                      #bookableModel="ngModel"
                      [(ngModel)]="bookable"
                      [suggestions]="allClientsOrg$ | async"
                      [placeholder]="'Start typing...'"
                      (completeMethod)="allClientsOrgSubject.next($event.query)"
                      (onSelect)="onBookingSelectionChange($event)"
                      field="displayName"
                      [class.input-warning]="bookableModel.touched && bookable?.disabled"
                    >
                      <ng-template let-bookable pTemplate="item">
                        <span>{{ bookable.displayName }}</span>
                      </ng-template>
                    </p-autoComplete>
                    <span *ngIf="bookableModel.touched && bookableModel.hasError('required')" class="inline-icon error">
                      <span>input is required</span>!
                    </span>
                    <span *ngIf="bookableModel.touched && bookable?.disabled" class="inline-icon warning">!</span>
                    <div *ngIf="bookableModel.touched && bookable?.disabled" class="inline-warning">
                      <span>WARNING: This user is disabled.  Please check with a manager to ensure that it is ok to book under this account.</span>
                    </div>
                  </div>
                </fieldset>
                <div class="separate"></div>
                <div class="medium-12" *ngIf="!forEdit">
                  <div class="row-wrapper">
                    <div class="batch-upload">
                      <b>Batch Upload</b>
                      <input
                        type="file"
                        ng2FileSelect
                        (change)="handleBulkUploadSelect($event)"
                        [uploader]="bulkUploader"
                        name="bulk-uploader"
                        id="bulk-uploader"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="medium-3"></div>
            </div>
            <div class="form right"></div>
          </div>
        </div>
      </form>

      <form [hidden]="!steps.isActive(2)" #bookingFormStep2="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Service Type
                <span class="title-link"
                  ><span class="hidden-mobile">fields with</span> <span class="red"> *</span> are required</span
                >
              </div>
              <div class="form content">
                <div class="medium-12">Booking Requested By</div>
                <div class="row-wrapper" *ngIf="!isRequestedByExpanded()">
                  <div class="row-client">
                    <div class="medium-4 form field">
                      <div>{{ bookingModel.requested_by.first_name }} {{ bookingModel.requested_by.last_name }}</div>
                      <!--<div>{{ bookingModel.requested_by.email }}</div>
                      <div>{{ bookingModel.requested_by.mobile_number }}</div>
                      <div>{{ bookingModel.requested_by.phone_number }}</div>-->
                    </div>
                    <div class="medium-4 form field">
                      <button
                        name="changeRequestedBy"
                        type="button"
                        class="button contact-btn"
                        (click)="expandRequestedBy()"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isRequestedByExpanded()" class="edit-client">
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="requestedByFirstName">
                          Contact first name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the First Name"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="requestedByFirstName"
                          name="requestedByFirstName"
                          type="text"
                          placeholder="Enter the Request By First Name"
                          [readonly]="isDisabledForOrgRepIndClient"
                          (click)="fireNotification($event)"
                          [(ngModel)]="bookingModel.requested_by.first_name"
                          #requestedByFirstNameModel="ngModel"
                          [rangeLength]="[2, 20]"
                          required
                        />
                        <span
                          *ngIf="
                            requestedByFirstNameModel.touched &&
                            !requestedByFirstNameModel.hasError('required') &&
                            !requestedByFirstNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            requestedByFirstNameModel.touched &&
                            (requestedByFirstNameModel.hasError('required') ||
                              requestedByFirstNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="requestedByLastName">
                          Contact last name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Last Name"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="requestedByLastName"
                          name="requestedByLastName"
                          type="text"
                          placeholder="Enter the Request By Last Name"
                          [readonly]="isDisabledForOrgRepIndClient"
                          (click)="fireNotification($event)"
                          [(ngModel)]="bookingModel.requested_by.last_name"
                          #requestedByLastNameModel="ngModel"
                          [rangeLength]="[2, 20]"
                          required
                        />
                        <span
                          *ngIf="
                            requestedByLastNameModel.touched &&
                            !requestedByLastNameModel.hasError('required') &&
                            !requestedByLastNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            requestedByLastNameModel.touched &&
                            (requestedByLastNameModel.hasError('required') ||
                              requestedByLastNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <!--<div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="requestedByEmail">
                          Contact email
                          <span mdTooltip="Enter the email address"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="requestedByEmail"
                          name="requestedByEmail"
                          type="email"
                          placeholder="Enter the Request By Email"
                          [readonly]="isDisabledForOrgRepIndClient"
                          (click)="fireNotification($event)"
                          [(ngModel)]="bookingModel.requested_by.email"
                          #requestedByEmailModel="ngModel"
                          [email]="bookingModel.requested_by.email !== '' && bookingModel.requested_by.email != null"
                        />
                        <span
                          *ngIf="requestedByEmailModel.touched && !requestedByEmailModel.hasError('email')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="requestedByEmailModel.touched && requestedByEmailModel.hasError('email')"
                          class="inline-icon error"
                          ><span>Email is required</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="requestedByMobile">
                          Contact mobile
                          <span mdTooltip="Enter the mobile number"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="requestedByMobile"
                          name="requestedByMobile"
                          type="tel"
                          placeholder="Enter the Request By Email"
                          [readonly]="isDisabledForOrgRepIndClient"
                          (click)="fireNotification($event)"
                          [ngModel]="bookingModel.requested_by.mobile_number | removeSpace"
                          (ngModelChange)="bookingModel.requested_by.mobile_number = $event"
                          #requestedByMobileModel="ngModel"
                          digits
                          [rangeLength]="[10, 11]"
                        />
                        <span
                          *ngIf="requestedByMobileModel.touched && !requestedByMobileModel.hasError('email')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="requestedByMobileModel.touched && requestedByMobileModel.hasError('rangeLength')"
                          class="inline-icon error"
                        >
                          <span>Minimum 10 digits required.</span>!
                        </span>
                        <span
                          *ngIf="requestedByMobileModel.touched && requestedByMobileModel.hasError('digits')"
                          class="inline-icon error"
                        >
                          <span>Enter digits only.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="requestedByPhone">
                          Contact phone
                          <span mdTooltip="Enter the phone number"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="requestedByPhone"
                          name="requestedByPhone"
                          type="tel"
                          placeholder="Enter the Request By Email"
                          [readonly]="isDisabledForOrgRepIndClient"
                          (click)="fireNotification($event)"
                          [ngModel]="bookingModel.requested_by.phone_number | removeSpace"
                          (ngModelChange)="bookingModel.requested_by.phone_number = $event"
                          #requestedByPhoneModel="ngModel"
                          digits
                          [rangeLength]="[10, 11]"
                        />
                        <span
                          *ngIf="requestedByPhoneModel.touched && !requestedByPhoneModel.hasError('email')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="requestedByPhoneModel.touched && requestedByPhoneModel.hasError('rangeLength')"
                          class="inline-icon error"
                        >
                          <span>Minimum 10 digits required.</span>!
                        </span>
                        <span
                          *ngIf="requestedByPhoneModel.touched && requestedByPhoneModel.hasError('digits')"
                          class="inline-icon error"
                        >
                          <span>Enter digits only.</span>!
                        </span>
                      </div>
                    </div>
                  </div>-->
                  <div class="row-wrapper">
                    <div class="medium-12">
                      <!--<button
                        type="button"
                        class="contact-btn default"
                        (click)="
                          closeRequestedBy(
                            requestedByFirstNameModel,
                            requestedByLastNameModel,
                            requestedByEmailModel,
                            requestedByMobileModel,
                            requestedByPhoneModel
                          )
                        "
                      >-->
                      <button
                        type="button"
                        class="contact-btn default"
                        (click)="closeRequestedBy(requestedByFirstNameModel, requestedByLastNameModel)"
                      >
                        <span>⌃</span> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="medium-12">
                  <p name="serviceMsg">Will this booking be onsite or remote?</p>
                </div>
                <div class="row-wrapper booking-type">
                  <button
                    type="button"
                    name="btnOnsite"
                    [disabled]="isDisabledForLinkedClient || (!isUserAdminORBookOfficer && forEdit)"
                    md-button
                    [class.active]="bookingModel.method_type === 'onsite'"
                    (click)="bookingModel.method_type = 'onsite'"
                  >
                    <i class="icon-service-interpreting"></i>
                    <span>On Site</span>
                  </button>
                  <button
                    type="button"
                    name="btnVri"
                    [disabled]="isDisabledForLinkedClient || (!isUserAdminORBookOfficer && forEdit)"
                    md-button
                    [class.active]="bookingModel.method_type === 'vri'"
                    (click)="bookingModel.method_type = 'vri'"
                  >
                    <i class="icon-service-vri"></i>
                    <span>VRI</span>
                  </button>
                </div>
                <div class="separate"></div>
                <div class="medium-12">
                  <p name="interpreterMsg">What kind of service(s) does the client need? Select multiple if relevant</p>
                  <div class="checkbox-group mb-1em">
                    <div class="checkbox-item">
                      <input
                        type="checkbox"
                        id="cbAuslan"
                        name="cbAuslan"
                        [(ngModel)]="interpreterKinds.Auslan"
                        #cbAuslan
                        (change)="serviceTypeChange('Auslan', cbAuslan)"
                        [disabled]="(interpreterKinds.Auslan && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                      />
                      <label for="cbAuslan" (click)="isAutoInviting()">Auslan</label>
                    </div>
                    <div class="checkbox-item">
                      <input
                        type="checkbox"
                        id="cbDeaf"
                        name="cbDeaf"
                        [(ngModel)]="interpreterKinds.Deaf"
                        #cbDeaf
                        (change)="serviceTypeChange('Deaf', cbDeaf)"
                        [disabled]="(interpreterKinds.Deaf && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                      />
                      <label for="cbDeaf" (click)="isAutoInviting()">Deaf</label>
                    </div>
                    <div class="checkbox-item">
                      <input
                        type="checkbox"
                        id="cbDeafBlind"
                        name="cbDeafBlind"
                        [(ngModel)]="interpreterKinds.DeafBlind"
                        #cbDeafBlind
                        (change)="serviceTypeChange('DeafBlind', cbDeafBlind)"
                        [disabled]="(interpreterKinds.DeafBlind && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                      />
                      <label for="cbDeafBlind" (click)="isAutoInviting()">Deaf Blind</label>
                    </div>
                    <div class="checkbox-item">
                      <input
                        type="checkbox"
                        id="cbCaptioning"
                        name="cbCaptioning"
                        [(ngModel)]="interpreterKinds.Captioning"
                        #cbCaptioning
                        (change)="serviceTypeChange('Captioning', cbCaptioning)"
                        [disabled]="(interpreterKinds.Captioning && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                      />
                      <label for="cbCaptioning" (click)="isAutoInviting()">Captioning</label>
                    </div>
                    <div class="checkbox-item">
                      <input
                        type="checkbox"
                        id="cbNotetaking"
                        name="cbNotetaking"
                        [(ngModel)]="interpreterKinds.Notetaking"
                        #cbNotetaking
                        (change)="serviceTypeChange('Notetaking', cbNotetaking)"
                        [disabled]="(interpreterKinds.Notetaking && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                      />
                      <label for="cbNotetaking" (click)="isAutoInviting()">Notetaking</label>
                    </div>
                    <div class="checkbox-item">
                      <input
                        type="checkbox"
                        id="cbOtherLanguage"
                        name="cbOtherLanguage"
                        [(ngModel)]="interpreterKinds.OtherLanguage"
                        #cbOtherLanguage
                        (change)="serviceTypeChange('OtherLanguage', cbOtherLanguage)"
                        [disabled]="
                          (interpreterKinds.OtherLanguage && forEdit) || (!isUserAdminORBookOfficer && forEdit)
                        "
                      />
                      <label for="cbOtherLanguage" (click)="isAutoInviting()">Other Language Needs</label>
                    </div>
                  </div>
                  <div class="checkbox-group mb-1em" name="deafBlindInterpreterTypes" *ngIf="interpreterKinds.DeafBlind">
                    <div class="checkbox-group">
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbVisualFrame"
                          name="cbVisualFrame"
                          [(ngModel)]="interpreterKinds.VisualFrame"
                          #cbVisualFrame
                          (change)="serviceTypeChange('VisualFrame', cbVisualFrame)"
                          [disabled]="
                            (interpreterKinds.VisualFrame && forEdit) || (!isUserAdminORBookOfficer && forEdit)
                          "
                        />
                        <label for="cbVisualFrame">Visual Frame</label>
                      </div>
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbTactile"
                          name="cbTactile"
                          [(ngModel)]="interpreterKinds.Tactile"
                          #cbTactile
                          (change)="serviceTypeChange('Tactile', cbTactile)"
                          [disabled]="(interpreterKinds.Tactile && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                        />
                        <label for="cbTactile">Tactile</label>
                      </div>
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbPlatform"
                          name="cbPlatform"
                          [(ngModel)]="interpreterKinds.Platform"
                          #cbPlatform
                          (change)="serviceTypeChange('Platform', cbPlatform)"
                          [disabled]="(interpreterKinds.Platform && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                        />
                        <label for="cbPlatform">Platform</label>
                      </div>
                    </div>
                  </div>

                  <div name="otherLanguageTypes" *ngIf="interpreterKinds.OtherLanguage">
                    <div>
                      Please specify your other language needs <span class="grey">Select multiple if relevant</span>
                    </div>

                    <div class="checkbox-group">
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbASL"
                          name="cbASL"
                          [(ngModel)]="interpreterKinds.ASL"
                          #cbAsl
                          (change)="serviceTypeChange('ASL', cbAsl)"
                          [disabled]="(interpreterKinds.ASL && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                        />
                        <label for="cbASL">ASL</label>
                      </div>
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbBSL"
                          name="cbBSL"
                          [(ngModel)]="interpreterKinds.BSL"
                          #cbBsl
                          (change)="serviceTypeChange('BSL', cbBsl)"
                          [disabled]="(interpreterKinds.BSL && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                        />
                        <label for="cbBSL">BSL</label>
                      </div>
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbISL"
                          name="cbISL"
                          [(ngModel)]="interpreterKinds.ISL"
                          #cbIsl
                          (change)="serviceTypeChange('ISL', cbIsl)"
                          [disabled]="(interpreterKinds.ISL && forEdit) || (!isUserAdminORBookOfficer && forEdit)"
                        />
                        <label for="cbISL">ISL</label>
                      </div>
                    </div>

                    <div class="checkbox-group">
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbSignedEnglish"
                          name="cbSignedEnglish"
                          [(ngModel)]="interpreterKinds.SignedEnglish"
                          #cbSignedEnglish
                          (change)="serviceTypeChange('SignedEnglish', cbSignedEnglish)"
                          [disabled]="
                            (interpreterKinds.SignedEnglish && forEdit) || (!isUserAdminORBookOfficer && forEdit)
                          "
                        />
                        <label for="cbSignedEnglish">Signed English</label>
                      </div>
                      <div class="checkbox-item">
                        <input
                          type="checkbox"
                          id="cbIndigenousSign"
                          name="cbIndigenousSign"
                          [(ngModel)]="interpreterKinds.IndigenousSign"
                          #cbIndigenousSign
                          (change)="serviceTypeChange('IndigenousSign', cbIndigenousSign)"
                          [disabled]="
                            (interpreterKinds.IndigenousSign && forEdit) || (!isUserAdminORBookOfficer && forEdit)
                          "
                        />
                        <label for="cbIndigenousSign">Indigenous Sign</label>
                      </div>
                    </div>
                  </div>

                  <br />

                  <div class="medium-12" *ngFor="let interpreterKindField of InterpreterKindsFields">
                    <div class="form-field" *ngIf="interpreterKinds[interpreterKindField.name]">
                      <label>
                        Number of {{ interpreterKindField.label }} <span class="red">* </span>
                        <span
                          mdTooltip="For most bookings over 1 hour, OH&S requires that we book 2 {{
                            interpreterKindField.label
                          }} interpreters unless breaks will be given"
                          ><i class="inline-icon-tip"></i
                        ></span>
                      </label>
                      <input
                        [attr.name]="interpreterKindField.name + 'Field'"
                        [name]="interpreterKindField.name + 'Field'"
                        type="text"
                        placeholder="Enter the numbers of {{ interpreterKindField.label }} required"
                        [readonly]="isDisabledForOrgRepIndClient"
                        (click)="fireNotification($event); isAutoInviting()"
                        #interpreterKindModel="ngModel"
                        [ngModel]="bookingModel[interpreterKindField.value] | removeSpace"
                        (ngModelChange)="bookingModel[interpreterKindField.value] = $event"
                        type="tel"
                        min="1"
                        max="100"
                        required
                        digits
                      />
                      <span
                        *ngIf="
                          interpreterKindModel.touched &&
                          !interpreterKindModel.hasError('required') &&
                          !interpreterKindModel.hasError('digits')
                        "
                        class="inline-icon inline-icon-valid valid"
                      ></span>
                      <span
                        *ngIf="interpreterKindModel.touched && interpreterKindModel.hasError('min')"
                        class="inline-icon error"
                      >
                        <span>Minimum value should be 1.</span>!
                      </span>
                      <span
                        *ngIf="interpreterKindModel.touched && interpreterKindModel.hasError('max')"
                        class="inline-icon error"
                      >
                        <span>Max value should be 100.</span>!
                      </span>
                      <span
                        *ngIf="
                          interpreterKindField.requireCount &&
                          bookingModel[interpreterKindField.value] < interpreterKindField.requireCount &&
                          isMoreInterpreterNeeded()
                        "
                        class="inline-icon error"
                      >
                        <span
                        >You may require more than {{ interpreterKindField.requireCount - 1 }} {{ interpreterKindField.label }} for this booking.</span
                        >!
                      </span>
                      <span
                        *ngIf="
                          interpreterKindModel.touched &&
                          (interpreterKindModel.hasError('required') || interpreterKindModel.hasError('digits'))
                        "
                        class="inline-icon error"
                      >
                        <span>Minimum 1 digit required.</span>!
                      </span>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
              </div>
            </div>
            <div class="form right">
              <div class="inform face">
                <header>On Site</header>
                <span>The interpreter will be onsite with all parties present</span>
              </div>
              <div class="inform remote">
                <header>VRI/Remote</header>
                <span>The interpreter/captioner will connect via videolink - they will not be present onsite</span>
              </div>
              <div class="inform deaf">
                <header>Deaf</header>
                <span
                  >A Deaf interpreter works in tandem with an Auslan interpreter and is deaf themselves. They work with
                  deaf clients who are not fluent in Auslan or need extra communication help</span
                >
              </div>
              <div class="inform number">
                <header>Number of interpreters</header>
                <span
                  >For most bookings over 1 hour, OH&S requires that we book 2 Auslan interpreters unless breaks will be
                  given</span
                >
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [hidden]="!steps.isActive(3)" #bookingFormStep3="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Appointment <span class="hidden-mobile">Details</span
                ><span class="title-link"
                  ><span class="hidden-mobile">fields with</span> <span class="red"> *</span> are required</span
                >
              </div>
              <div class="form content">
                <div class="medium-12">
                  <div class="form-field">
                    <label>Date of appointment <span class="red">*</span></label>
                    <p-calendar
                      name="dpDate"
                      placeholder="Start date of appointment"
                      [readonlyInput]="isDisabledForOrgRepIndClient"
                      (click)="fireNotification($event)"
                      (onFocus)="isAutoInviting()"
                      [(ngModel)]="bookingDate"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      dateFormat="dd/mm/yy"
                      (ngModelChange)="resetRecurringDays()"
                      (onSelect)="resetRecurringDays()"
                      required
                      #dpDate="ngModel"
                      dateOnly="true"
                    ></p-calendar>
                    <span
                      *ngIf="dpDate.touched && !dpDate.hasError('required')"
                      class="inline-icon inline-icon-valid valid"
                    ></span>
                    <span *ngIf="dpDate.touched && dpDate.hasError('required')" class="inline-icon error">
                      <span>Start Date is required</span>!
                    </span>
                    <span *ngIf="bookingDate < minDate" class="inline-icon error">
                      <span>Date is in the past. Please change the date</span>!
                    </span>
                    <span *ngIf="dpDate.touched && bookingDate > maxDate" class="inline-icon error">
                      <span>Please change the date to within 5 years</span>!
                    </span>
                  </div>
                </div>
                <div class="row-wrapper">
                  <div class="medium-3 withPadding">
                    <div class="form-field">
                      <label>Start time <span class="red">*</span></label>
                      <p-calendar
                        name="dpEventDate"
                        [(ngModel)]="bookingStartTime"
                        [readonlyInput]="isDisabledForOrgRepIndClient"
                        (onFocus)="isAutoInviting()"
                        placeholder="Starting time"
                        [timeOnly]="true"
                        hourFormat="12"
                        [stepMinute]="5"
                        [defaultDate]="defaultDateTime"
                        (onBlur)="setBookingTime('start', $event)"
                        (ngModelChange)="updateDuration()"
                        required
                        #dpEventDate="ngModel"
                      ></p-calendar>
                      <span
                        *ngIf="dpEventDate.touched && !dpEventDate.hasError('required')"
                        class="inline-icon inline-icon-valid valid"
                      ></span>
                      <span *ngIf="dpEventDate.touched && dpEventDate.hasError('required')" class="inline-icon error">
                        <span>Start Time is required</span>!
                      </span>
                    </div>
                  </div>

                  <div class="medium-3 withPadding narrow">
                    <div class="form-field">
                      <label>End time <span class="red">*</span></label>
                      <p-calendar
                        name="dpEventEndTime"
                        [(ngModel)]="bookingEndTime"
                        [readonlyInput]="isDisabledForOrgRepIndClient"
                        (onFocus)="isAutoInviting()"
                        placeholder="Ending time"
                        [timeOnly]="true"
                        hourFormat="12"
                        [stepMinute]="5"
                        [defaultDate]="defaultDateTime"
                        (onBlur)="setBookingTime('end', $event)"
                        (ngModelChange)="updateDuration()"
                        required
                        #dpEventEndTime="ngModel"
                      ></p-calendar>
                      <span
                        *ngIf="dpEventEndTime.touched && !dpEventEndTime.hasError('required')"
                        class="inline-icon inline-icon-valid valid"
                      ></span>
                      <span
                        *ngIf="dpEventEndTime.touched && dpEventEndTime.hasError('required')"
                        class="inline-icon error"
                      >
                        <span>End Time is required</span>!
                      </span>
                      <span *ngIf="bookingEndTime < bookingStartTime" class="inline-icon error">
                        <span>End time can't be before start time</span>!
                      </span>
                    </div>
                  </div>

                  <div class="medium-3 withPadding">
                    <label>Duration </label>
                    <div id="duration" class="duration disabled" type="time">{{ bookingDuration }}</div>
                  </div>

                  <div class="medium-3 withPadding" *ngIf="!forEdit">
                    <div class="recurring">
                      <label for="recurring">Recurring? </label>
                      <label class="switch">
                        <input
                          type="checkbox"
                          name="recurring-booking"
                          id="recurring"
                          [(ngModel)]="isRecurringBooking"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngIf="isRecurringBooking">
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <label for="frequency">Frequency</label>
                      <select
                        id="frequency"
                        [(ngModel)]="bookingModel.frequency"
                        name="recurring_booking_frequency"
                        [required]="isRecurringBooking"
                      >
                        <option value="weekly">Weekly</option>
                        <option value="biweekly">Every 2 weeks</option>
                        <option value="fourweekly">Every 4 weeks</option>
                      </select>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label>End Date <span class="red">*</span></label>
                        <p-calendar
                          name="recurrence_end_date"
                          placeholder="End date"
                          [(ngModel)]="bookingModel.recurrence_end_date"
                          [minDate]="minDateForRecurrenceEnd"
                          [required]="isRecurringBooking"
                          dateFormat="dd/mm/yy"
                          #dpRecurringEndDate="ngModel"
                        >
                        </p-calendar>
                        <span
                          *ngIf="dpRecurringEndDate.touched && !dpRecurringEndDate.hasError('required')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="dpRecurringEndDate.touched && dpRecurringEndDate.hasError('required')"
                          class="inline-icon error"
                        >
                          <span>End Date is required</span>!
                        </span>
                        <span
                          *ngIf="dpRecurringEndDate.touched && dpRecurringEndDate.hasError('minDate')"
                          class="inline-icon error"
                        >
                          <span>End date can't be before start date</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="medium-12">
                    <div class="medium-12">
                      <label>Repeat on</label>
                      <div class="checkbox-group mb-1em">
                        <div *ngFor="let day of repeat_days" class="checkbox-item checkbox-inline">
                          <input
                            id="{{ 'cb' + day.value }}"
                            type="checkbox"
                            [name]="day.value"
                            [(ngModel)]="day.selected"
                            [disabled]="isRecurrenceDayCheckboxDisabled(day)"
                          />
                          <label for="{{ 'cb' + day.value }}">{{ day.display }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div [hidden]="isVenueAddressExpand()" class="medium-12">
                  <div class="row-wrapper">
                    <div class="medium-6">
                      <label>Address<span class="red"> * </span></label>
                    </div>

                    <div class="medium-6">
                      <a
                        *ngIf="!isVenueAddressExpand()"
                        name="useOrganisationAddress"
                        href="#"
                        (click)="$event.preventDefault(); setStandardVenueAddress()"
                        ><span class="top-link">Use organisation address</span></a
                      >
                    </div>
                  </div>

                  <input
                    type="text"
                    name="searchAddress"
                    placeholder="Start typing to find your address"
                    [readonly]="isDisabledForLinkedClient"
                    #searchAddress
                  />
                  <span class="help">
                    Can't find your address? Click
                    <a name="expandVenueAddress" href="#" (click)="$event.preventDefault(); expandVenueAddress(true)">
                      <span class="green"> here </span>
                    </a>
                    to type manually
                  </span>
                </div>
                <div [hidden]="!isVenueAddressExpand()" class="medium-12">
                  <div class="row-wrapper">
                    <div class="medium-6">
                      <label>Address <span class="red">* </span></label>
                    </div>

                    <div class="medium-6">
                      <a
                        *ngIf="isVenueAddressExpand()"
                        style="float: right;"
                        name="useOrganisationAddress"
                        href="#"
                        (click)="$event.preventDefault(); setStandardVenueAddress()"
                        ><span class="top-link">Use organisation address</span></a
                      >
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="venueUnitNumber">
                          Unit number
                          <span mdTooltip="Enter the Unit Number">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="venueUnitNumber"
                          name="venueUnitNumber"
                          type="text"
                          placeholder="Enter the Unit Number"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          (blur)="calculateVenueDistance()"
                          [(ngModel)]="bookingModel.venue.unit_number"
                          #venueUnitNumberModel="ngModel"
                        />
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="venueStreetNumber">
                          Street number
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Street Number">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="venueStreetNumber"
                          name="venueStreetNumber"
                          type="text"
                          placeholder="Enter the Street Number"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          (blur)="calculateVenueDistance()"
                          [ngModel]="bookingModel.venue.street_number | removeSpace"
                          (ngModelChange)="bookingModel.venue.street_number = $event"
                          #venueStreetNumberModel="ngModel"
                          [rangeLength]="[1, 10]"
                          required
                        />
                        <span
                          *ngIf="
                            venueStreetNumberModel.touched &&
                            !venueStreetNumberModel.hasError('required') &&
                            !venueStreetNumberModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            venueStreetNumberModel.touched &&
                            (venueStreetNumberModel.hasError('required') ||
                              venueStreetNumberModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 1 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="venueStreetName">
                          Street name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Street Name">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="venueStreetName"
                          name="venueStreetName"
                          type="text"
                          placeholder="Enter the Street Name"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          (blur)="calculateVenueDistance()"
                          [(ngModel)]="bookingModel.venue.street_name"
                          #venueStreetNameModel="ngModel"
                          [rangeLength]="[2, 30]"
                          required
                        />
                        <span
                          *ngIf="
                            venueStreetNameModel.touched &&
                            !venueStreetNameModel.hasError('required') &&
                            !venueStreetNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            venueStreetNameModel.touched &&
                            (venueStreetNameModel.hasError('required') || venueStreetNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="venueSuburb">
                          Suburb
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Suburb">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="venueSuburb"
                          name="venueSuburb"
                          type="text"
                          placeholder="Enter the Suburb"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          (blur)="calculateVenueDistance()"
                          [(ngModel)]="bookingModel.venue.suburb"
                          #venueSuburbModel="ngModel"
                          [rangeLength]="[3, 30]"
                          required
                        />
                        <span
                          *ngIf="
                            venueSuburbModel.touched &&
                            !venueSuburbModel.hasError('required') &&
                            !venueSuburbModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            venueSuburbModel.touched &&
                            (venueSuburbModel.hasError('required') || venueSuburbModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="venuePostCode">
                          Post code
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Post Code">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="venuePostCode"
                          name="venuePostCode"
                          type="tel"
                          placeholder="Enter the Post Code"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          (blur)="calculateVenueDistance()"
                          [ngModel]="bookingModel.venue.post_code | removeSpace"
                          (ngModelChange)="bookingModel.venue.post_code = $event"
                          #venuePostCodeModel="ngModel"
                          [rangeLength]="[3, 30]"
                          required
                          digits
                        />
                        <span
                          *ngIf="
                            venuePostCodeModel.touched &&
                            !venuePostCodeModel.hasError('required') &&
                            !venuePostCodeModel.hasError('rangeLength') &&
                            !venuePostCodeModel.hasError('digits')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            venuePostCodeModel.touched &&
                            (venuePostCodeModel.hasError('required') ||
                              venuePostCodeModel.hasError('rangeLength') ||
                              venuePostCodeModel.hasError('digits'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 4 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="venueState">
                          State
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Post Code">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <select
                          id="venueState"
                          name="venueState"
                          aria-label="A"
                          [disabled]="isDisabledForLinkedClient"
                          [(ngModel)]="bookingModel.venue.state"
                          (change)="calculateVenueDistance()"
                          #venueStateModel="ngModel"
                          required
                        >
                          <option *ngFor="let state_name of StatesNames" [value]="state_name">{{ state_name }}</option>
                        </select>
                        <span
                          *ngIf="venueStateModel.touched && !venueStateModel.hasError('required')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="venueStateModel.touched && venueStateModel.hasError('required')"
                          class="inline-icon error"
                        >
                          <span>Booking state is required</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <span class="help">
                    Click
                    <a href="#" (click)="$event.preventDefault(); expandVenueAddress(false)">
                      <span class="green"> here </span>
                    </a>
                    to detect automatically
                  </span>
                </div>
                <div class="medium-12">
                  <div class="row-wrapper">
                    <div class="medium-6">
                      <label>
                        Specific Location
                        <span mdTooltip="i.e. Level number, business name, or where the interpreter should report">
                          <i class="inline-icon-tip"></i
                        ></span>
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="venueAddress"
                    placeholder="i.e. Level number, business name, or where the interpreter should report"
                    [readOnly]="isDisabledForLinkedClient"
                    [(ngModel)]="bookingModel.venue.title"
                  />
                </div>
                <ng-container
                  *ngIf="
                    interpreterKinds.Captioning || interpreterKinds.Notetaking || bookingModel.method_type === 'vri'
                  "
                >
                  <div class="separate"></div>
                  <div class="medium-12">
                    <div class="row-wrapper">
                      <div class="medium-6">
                        <label style="font-size: 18px">Technical Details</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="grid-wrapper" *ngIf="interpreterKinds.Captioning" name="captioningAdditionalFields">
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techFirstName">Tech contact first name</label>
                            <input
                              id="techFirstName"
                              name="techFirstName"
                              type="text"
                              placeholder="Enter Tech Contact First Name"
                              [readOnly]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.tech_contact_first_name"
                            />
                          </div>
                        </div>
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techLastName">Tech contact last name</label>
                            <input
                              id="techLastName"
                              name="techLastName"
                              type="text"
                              placeholder="Enter Tech Contact Last Name"
                              [readOnly]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.tech_contact_last_name"
                            />
                          </div>
                        </div>
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techEmail">Tech contact email</label>
                            <input
                              id="techEmail"
                              name="techEmail"
                              type="email"
                              placeholder="Enter tech contact email address"
                              [readOnly]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.tech_contact_email"
                              #techEmail="ngModel"
                              [email]="
                                bookingModel.tech_contact_email !== '' && bookingModel.tech_contact_email != null
                              "
                            />
                            <span
                              *ngIf="
                                techEmail.touched && bookingModel.tech_contact_email && !techEmail.hasError('email')
                              "
                              class="inline-icon inline-icon-valid valid"
                            ></span>
                            <span *ngIf="techEmail.touched && techEmail.hasError('email')" class="inline-icon error"
                              ><span>Email is invalid</span>!
                            </span>
                          </div>
                        </div>
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="contactMobile">Tech contact number</label>
                            <input
                              id="techMobile"
                              name="techMobile"
                              type="tel"
                              placeholder="Enter Tech Contact Number"
                              [readOnly]="isDisabledForLinkedClient"
                              [ngModel]="bookingModel.tech_contact_phone_number | removeSpace"
                              (ngModelChange)="bookingModel.tech_contact_phone_number = $event"
                              #techMobileModel="ngModel"
                              digits
                              [rangeLength]="[10, 11]"
                            />
                            <span
                              *ngIf="
                                techMobileModel.touched &&
                                bookingModel.tech_contact_phone_number &&
                                (!techMobileModel.hasError('digits') && !techMobileModel.hasError('rangeLength'))
                              "
                              class="inline-icon inline-icon-valid valid"
                            ></span>
                            <span
                              *ngIf="techMobileModel.touched && techMobileModel.hasError('rangeLength')"
                              class="inline-icon error"
                            >
                              <span>Minimum 10 digits required.</span>!
                            </span>
                            <span
                              *ngIf="techMobileModel.touched && techMobileModel.hasError('digits')"
                              class="inline-icon error"
                            >
                              <span>Enter digits only.</span>!
                            </span>
                          </div>
                        </div>
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techAudioSource">Audio source</label>
                            <select
                              id="techAudioSource"
                              name="techAudioSource"
                              [disabled]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.audio_source"
                            >
                              <option *ngFor="let audioSource of techAudioSources" [value]="audioSource">{{
                                audioSource
                              }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="grid-wrapper"
                        *ngIf="interpreterKinds.Captioning || bookingModel.method_type === 'vri'"
                        name="captioningAndVriAdditionalFields"
                      >
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techPlatform">Tech platform</label>
                            <select
                              id="techPlatform"
                              name="techPlatform"
                              [disabled]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.tech_platform"
                            >
                              <option *ngFor="let platform of techPlatforms" [value]="platform">{{ platform }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techLoginIDLink">Login ID link</label>
                            <input
                              id="techLoginIDLink"
                              name="techLoginIDLink"
                              [readOnly]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.login_id_link"
                              type="text"
                              placeholder="Enter Login ID Link"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="grid-wrapper" *ngIf="bookingModel.method_type === 'vri'" name="vriAdditionalFields">
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techHardware">Hardware</label>
                            <select
                              id="techHardware"
                              name="techHardware"
                              [disabled]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.hardware"
                            >
                              <option *ngFor="let hardware of techHardware" [value]="hardware">{{ hardware }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techWhoWillInitiateCall">Who will initiate the call</label>
                            <input
                              id="techWhoWillInitiateCall"
                              name="techWhoWillInitiateCall"
                              [readOnly]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.who_will_initiate_call"
                              type="text"
                              placeholder="Enter Who Will Initiate The Call"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="grid-wrapper" *ngIf="interpreterKinds.Notetaking">
                        <div class="medium-6">
                          <div class="form-field">
                            <label for="techHowWouldYouLikeToReceiveNotes">How would you like to receive notes</label>
                            <select
                              id="techHowWouldYouLikeToReceiveNotes"
                              name="techHowWouldYouLikeToReceiveNotes"
                              [disabled]="isDisabledForLinkedClient"
                              [(ngModel)]="bookingModel.how_would_you_like_to_receive_notes"
                            >
                              <option value="Digitally">Digitally</option>
                              <option value="Hand Written">Hand Written</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="separate"></div>
                <div class="medium-12">
                  <div class="row-wrapper">
                    <div class="medium-6">
                      <label style="font-size: 18px">Contact Person at Booking</label>
                    </div>
                  </div>
                  <label class="reducedHeight">
                    <p name="contactMsg">{{
                      isUserAdminORBookOfficer
                        ? 'Do you want to use the standard contact detail?'
                        : 'Are you the contact person?'
                      }}</p>
                    <span
                      mdTooltip="Interpreters will refer to this information at arrival. Please provide details fo the best contact person at the booking"
                    >
                      <i class="inline-icon-tip"></i>
                    </span>
                  </label>
                  <div class="row-wrapper">
                    <div class="checkbox-group">
                      <input
                        id="currentUserIsContactYes"
                        type="radio"
                        name="currentUserIsContactYes"
                        [value]="true"
                        [disabled]="isDisabledForOrgRepIndClient"
                        [(ngModel)]="currentUserIsContact"
                        (change)="onPrimaryContactSelectionChange()"
                      /><label for="currentUserIsContactYes">Yes</label>
                      <input
                        id="currentUserIsContactNo"
                        type="radio"
                        name="currentUserIsContactNo"
                        [value]="false"
                        [disabled]="isDisabledForOrgRepIndClient"
                        [(ngModel)]="currentUserIsContact"
                        (change)="onPrimaryContactSelectionChange()"
                      /><label for="currentUserIsContactNo">No</label>
                    </div>
                  </div>
                  <div class="separate"></div>
                  <div class="row-wrapper" *ngIf="!isPrimaryContactExpanded()">
                    <div class="row-client">
                      <div class="medium-4 form field">
                        <div>
                          {{ bookingModel.primaryContact.first_name }} {{ bookingModel.primaryContact.last_name }}
                        </div>
                        <div>{{ bookingModel.primaryContact.email }}</div>
                        <div>{{ bookingModel.primaryContact.mobile_number }}</div>
                      </div>
                      <div class="medium-4 form field">
                        <button
                          name="changeContact"
                          type="button"
                          class="button contact-btn"
                          (click)="changePrimaryContact()"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isPrimaryContactExpanded()" class="edit-client">
                    <div class="row-wrapper">
                      <div class="medium-6 withPadding">
                        <div class="form-field">
                          <label for="contactFirstName">
                            Contact first name
                            <span class="red"> * </span>
                            <span mdTooltip="Enter Contact First Name"> <i class="inline-icon-tip"></i></span>
                          </label>
                          <input
                            id="contactFirstName"
                            name="contactFirstName"
                            type="text"
                            placeholder="Enter Contact First Name"
                            [readOnly]="isDisabledForLinkedClient"
                            (click)="fireNotification($event)"
                            [(ngModel)]="bookingModel.primaryContact.first_name"
                            #contactFirstNameModel="ngModel"
                            [rangeLength]="[2, 20]"
                            required
                          />
                          <span
                            *ngIf="
                              contactFirstNameModel.touched &&
                              !contactFirstNameModel.hasError('required') &&
                              !contactFirstNameModel.hasError('rangeLength')
                            "
                            class="inline-icon inline-icon-valid valid"
                          ></span>
                          <span
                            *ngIf="
                              contactFirstNameModel.touched &&
                              (contactFirstNameModel.hasError('required') ||
                                contactFirstNameModel.hasError('rangeLength'))
                            "
                            class="inline-icon error"
                          >
                            <span>Minimum 2 characters required.</span>!
                          </span>
                        </div>
                      </div>
                      <div class="medium-6 withPadding">
                        <div class="form-field">
                          <label for="contactLastName">
                            Contact last name
                            <span class="red"> * </span>
                            <span mdTooltip="Enter Contact Last Name"> <i class="inline-icon-tip"></i> </span
                          ></label>
                          <input
                            id="contactLastName"
                            name="contactLastName"
                            type="text"
                            placeholder="Enter Contact Last Name"
                            [readOnly]="isDisabledForLinkedClient"
                            (click)="fireNotification($event)"
                            [(ngModel)]="bookingModel.primaryContact.last_name"
                            #contactLastNameModel="ngModel"
                            [rangeLength]="[2, 20]"
                            required
                          />
                          <span
                            *ngIf="
                              contactLastNameModel.touched &&
                              !contactLastNameModel.hasError('required') &&
                              !contactLastNameModel.hasError('rangeLength')
                            "
                            class="inline-icon inline-icon-valid valid"
                          ></span>
                          <span
                            *ngIf="
                              contactLastNameModel.touched &&
                              (contactLastNameModel.hasError('required') ||
                                contactLastNameModel.hasError('rangeLength'))
                            "
                            class="inline-icon error"
                          >
                            <span>Minimum 2 characters required.</span>!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row-wrapper">
                      <div class="medium-6 withPadding">
                        <div class="form-field">
                          <label for="contactEmail">
                            Contact email
                            <span class="red"> * </span>
                            <span mdTooltip="Enter contact email address"> <i class="inline-icon-tip"></i> </span
                          ></label>
                          <input
                            id="contactEmail"
                            name="contactEmail"
                            type="email"
                            placeholder="Enter contact email address"
                            [readOnly]="isDisabledForLinkedClient"
                            [(ngModel)]="bookingModel.primaryContact.email"
                            #contactEmailModel="ngModel"
                            required
                            email
                          />
                          <span
                            *ngIf="
                              contactEmailModel.touched &&
                              (!contactEmailModel.hasError('required') && !contactEmailModel.hasError('email'))
                            "
                            class="inline-icon inline-icon-valid valid"
                          ></span>
                          <span
                            *ngIf="
                              contactEmailModel.touched &&
                              (contactEmailModel.hasError('required') || contactEmailModel.hasError('email'))
                            "
                            class="inline-icon error"
                            ><span>Email is required</span>!
                          </span>
                        </div>
                      </div>
                      <div class="medium-6 withPadding">
                        <div class="form-field">
                          <label for="contactMobile">
                            Contact number
                            <span class="red"> * </span>
                            <span mdTooltip="Enter Contact Number"> <i class="inline-icon-tip"></i> </span
                          ></label>
                          <input
                            id="contactMobile"
                            name="contactMobile"
                            type="tel"
                            placeholder="Enter Contact Number"
                            [readOnly]="isDisabledForLinkedClient"
                            [ngModel]="bookingModel.primaryContact.mobile_number | removeSpace"
                            (ngModelChange)="bookingModel.primaryContact.mobile_number = $event"
                            #contactMobileModel="ngModel"
                            required
                            digits
                            [rangeLength]="[10, 11]"
                          />
                          <span
                            *ngIf="
                              contactMobileModel.touched &&
                              (!contactMobileModel.hasError('required') &&
                                !contactMobileModel.hasError('digits') &&
                                !contactMobileModel.hasError('rangeLength'))
                            "
                            class="inline-icon inline-icon-valid valid"
                          ></span>
                          <span
                            *ngIf="
                              contactMobileModel.touched &&
                              (contactMobileModel.hasError('rangeLength') || contactMobileModel.hasError('required'))
                            "
                            class="inline-icon error"
                          >
                            <span>Minimum 10 digits required.</span>!
                          </span>
                          <span
                            *ngIf="contactMobileModel.touched && contactMobileModel.hasError('digits')"
                            class="inline-icon error"
                          >
                            <span>Enter digits only.</span>!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row-wrapper">
                      <div class="medium-12">
                        <button
                          type="button"
                          class="contact-btn default"
                          (click)="
                            closePrimaryContact(
                              contactFirstNameModel,
                              contactLastNameModel,
                              contactEmailModel,
                              contactMobileModel
                            )
                          "
                        >
                          <span>⌃</span> Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="medium-12">
                  <label for="parking">
                    Parking availability
                    <span mdTooltip="If parking is provided or readily available nearby please provide details">
                      <i class="inline-icon-tip"></i>
                    </span>
                  </label>
                  <input
                    id="parking"
                    type="text"
                    name="parking_type"
                    [readOnly]="isDisabledForLinkedClient"
                    [(ngModel)]="bookingModel.venue.parking_type"
                  />
                </div>
              </div>
            </div>
            <div class="form right">
              <div class="inform specific-location">
                <header>Specific location</header>
                <span>i.e. Level number, business name, or where the interpreter should report</span>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [hidden]="!steps.isActive(4)" #bookingFormStep4="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Appointment <span class="hidden-mobile">Detail</span
                ><span class="title-link"
                  ><span class="hidden-mobile">fields with</span> <span class="red"> *</span> are required</span
                >
              </div>
              <div class="form content">
                <div class="row-wrapper">
                  <div class="medium-6 withPadding">
                    <div class="form-field">
                      <label for="natureOfAppointment">
                        Nature of appointment
                        <span class="red"> * </span>
                        <span mdTooltip="Select nature of Appointment">
                          <i class="inline-icon-tip"></i>
                        </span>
                      </label>
                      <select
                        id="natureOfAppointment"
                        (change)="natureOfAppointmentChange()"
                        [disabled]="isDisabledForOrgRepIndClient"
                        [(ngModel)]="bookingModel.raw_nature_of_appointment"
                        #natureOfAppointmentModel="ngModel"
                        name="natureOfAppointment"
                        aria-label="A"
                        required
                      >
                        <option *ngFor="let item of (appointmentTypes | enumVal)" [value]="item.value">
                          {{ item.value.toUpperCase() }}
                        </option>
                      </select>
                      <span
                        *ngIf="natureOfAppointmentModel.touched && !natureOfAppointmentModel.hasError('required')"
                        class="inline-icon inline-icon-valid valid"
                      ></span>
                      <span
                        *ngIf="natureOfAppointmentModel.touched && natureOfAppointmentModel.hasError('required')"
                        class="inline-icon error"
                        >!
                      </span>
                    </div>
                  </div>
                  <div class="medium-6 withPadding">
                    <div class="form-field">
                      <label for="specificNatureOfAppointment">
                        What will be discussed
                        <span class="red"> * </span>
                        <span mdTooltip="Select specific nature of Appointment">
                          <i class="inline-icon-tip"></i>
                        </span>
                      </label>
                      <select
                        id="specificNatureOfAppointment"
                        [(ngModel)]="bookingModel.specific_nature_of_appointment"
                        [disabled]="isDisabledForOrgRepIndClient"
                        #specificNatureOfAppointmentModel="ngModel"
                        required
                        name="specificNatureOfAppointment"
                        aria-label="A"
                      >
                        <option
                          *ngFor="let item of (specificAppointmentTypes | enumVal)"
                          [value]="
                            item.value
                              .replace(' (Please specify in Notes)', '')
                              .replace(' (Please specify length in Notes)', '')
                          "
                        >
                          {{ item.value.toUpperCase() }}
                        </option>
                      </select>
                      <span
                        *ngIf="
                          specificNatureOfAppointmentModel.touched &&
                          !specificNatureOfAppointmentModel.hasError('required')
                        "
                        class="inline-icon inline-icon-valid valid"
                      ></span>
                      <span
                        *ngIf="
                          specificNatureOfAppointmentModel.touched &&
                          specificNatureOfAppointmentModel.hasError('required')
                        "
                        class="inline-icon error"
                        >!
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row-wrapper">
                  <div class="medium-6 withPadding">
                    <div class="form-field">
                      <label for="attendeesCount">
                        Number of people attending
                        <span mdTooltip="Enter the Number of people attending the meeting">
                          <i class="inline-icon-tip"></i>
                        </span>
                      </label>
                      <input
                        id="attendeesCount"
                        [ngModel]="bookingModel.venue.expected_attendance | removeSpace"
                        (ngModelChange)="bookingModel.venue.expected_attendance = $event"
                        name="attendeesCount"
                        #attendeesCountModel="ngModel"
                        placeholder="Enter the Number of people attending the meeting"
                        type="tel"
                        min="1"
                        digits
                      />
                      <span
                        *ngIf="attendeesCountModel.touched && attendeesCountModel.hasError('min')"
                        class="inline-icon error"
                      >
                        <span>Minimum value should be 1.</span>!
                      </span>
                      <span
                        *ngIf="
                          attendeesCountModel.value != null &&
                          attendeesCountModel.touched &&
                          !attendeesCountModel.hasError('min') &&
                          !attendeesCountModel.hasError('digits')
                        "
                        class="inline-icon inline-icon-valid valid"
                      ></span>
                      <span
                        *ngIf="attendeesCountModel.touched && attendeesCountModel.hasError('digits')"
                        class="inline-icon error"
                      >
                        <span>Only digits allowed.</span>!
                      </span>
                    </div>
                  </div>
                  <div class="medium-6 withPadding">
                    <label class="reducedHeight">
                      Do you want to use your standard notes for the interpreter?
                    </label>
                    <div class="row-wrapper">
                      <div class="checkbox-group">
                        <input
                          id="isStandardSpecialInstructionYes"
                          type="radio"
                          name="isStandardSpecialInstructionYes"
                          [value]="true"
                          [(ngModel)]="isStandardInstruction"
                          [disabled]="isDisabledForLinkedClient"
                          (change)="onSpecialInstruction()"
                        /><label for="isStandardSpecialInstructionYes">Yes</label>
                        <input
                          id="isStandardSpecialInstructionNo"
                          type="radio"
                          name="isStandardSpecialInstructionNo"
                          [value]="false"
                          [(ngModel)]="isStandardInstruction"
                          [disabled]="isDisabledForLinkedClient"
                          (change)="onSpecialInstruction()"
                          checked
                        /><label for="isStandardSpecialInstructionNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="medium-12">
                  <div class="form-field" *ngIf="isUserAdminORBookOfficer">
                    <label for="invitationSummary">
                      Invitation Summary
                    </label>
                    <textarea
                      id="invitationSummary"
                      placeholder="Enter Invitation Summary"
                      rows="5"
                      [readOnly]="isDisabledForLinkedClient"
                      name="invitationSummary"
                      [(ngModel)]="bookingModel.invitation_summary"
                    ></textarea>
                  </div>
                </div>
                <div class="medium-12">
                  <div class="form-field">
                    <label for="specialInstructions">
                      Notes for the Interpreter (brief description of what will be discussed)
                      <span class="red"> * </span>
                      <span
                        mdTooltip="Brief description of what will be discussed during the booking, and any special instructions for the interpreter. This information will be shared with the interpreter"
                      >
                        <i class="inline-icon-tip"></i>
                      </span>
                    </label>
                    <textarea
                      id="specialInstructions"
                      placeholder="e.g. type of meeting, topic of discussion, where to report"
                      rows="5"
                      [readOnly]="isDisabledForLinkedClient"
                      name="specialInstructions"
                      [(ngModel)]="bookingModel.special_instructions"
                      #notes="ngModel"
                      [required]="bookingModel.raw_nature_of_appointment === 'Other'"
                      [minlength]="bookingModel.raw_nature_of_appointment === 'Other' ? 2 : 0"
                    ></textarea>

                    <span *ngIf="notes.hasError('required') || notes.hasError('minlength')" class="inline-icon error">
                      <span>Please specify in Note for the Interpreter</span>!
                    </span>
                  </div>
                </div>
                <div class="medium-12">
                  <label for="notes">
                    Confidential notes for the booking office
                    <span
                      mdTooltip="Preferences, notes for the booking office. This information will be not shared with the interpreter"
                    >
                      <i class="inline-icon-tip"></i>
                    </span>
                  </label>
                  <textarea
                    id="notes"
                    [(ngModel)]="bookingModel.notes"
                    [readOnly]="isDisabledForLinkedClient"
                    (click)="fireNotification($event)"
                    name="important_notes"
                    type="text"
                    placeholder="e.g. 'please use my preferred interpreter list, book a Level 3 / Certified Interpreter'"
                  ></textarea>
                </div>
                <!--<div class="medium-12">
                  <label>Confidential notes for the Booking Office <span class="inline-icon-tip"></span></label>
                  <textarea name="" placeholder="Add note" rows="5"></textarea>
                </div>-->
                <div class="medium-12">
                  <label>
                    Will this be filmed/recorded/live streamed?
                  </label>
                  <div class="row-wrapper">
                    <div class="checkbox-group">
                      <input
                        type="radio"
                        id="bookingRecordedYes"
                        name="bookingRecordedYes"
                        [disabled]="isDisabledForLinkedClient"
                        [(ngModel)]="bookingModel.isBookingBeRecorded"
                        [value]="true"
                      /><label for="bookingRecordedYes">Yes</label>
                      <input
                        type="radio"
                        id="bookingRecordedNo"
                        name="bookingRecordedNo"
                        [disabled]="isDisabledForLinkedClient"
                        [(ngModel)]="bookingModel.isBookingBeRecorded"
                        [value]="false"
                        [defaultChecked]="true"
                      /><label for="bookingRecordedNo">No</label>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="medium-12">
                  <div class="row-wrapper">
                    <div class="medium-6">
                      <label style="font-size: 18px">Job Documents</label>
                    </div>
                  </div>
                  <div class="row-wrapper layout-wrap">
                    <div class="job-item">
                      <i class="icon-add-file"></i>
                      <label for="document-upload">Choose files</label>
                      <input
                        id="document-upload"
                        name="uploader"
                        type="file"
                        ng2FileSelect
                        [uploader]="uploader"
                        (change)="handleFileSelect($event)"
                        [disabled]="isDisabledForLinkedClient"
                      />
                    </div>
                    <div class="job-item" *ngFor="let item of uploader.queue; let i = index">
                      <button name="{{ 'btnRemoveDocument_' + (i + 1) }}" (click)="removeDocument(item)" type="button">
                        <i class="icon-close"></i>
                      </button>
                      <i class="icon-attachment"></i>
                      <span>{{ item?.file?.name }}</span>
                    </div>
                    <div class="job-item" *ngFor="let docItem of oldDocuments; let i = index">
                      <button
                        name="{{ 'btnRemoveOldDoc_' + (i + 1) }}"
                        (click)="removeOldDocument(docItem.id)"
                        type="button"
                      >
                        <i class="icon-close"></i>
                      </button>
                      <i class="icon-attachment"></i>
                      <span
                        ><a download href="{{ docItem.url }}">{{ docItem.file_name }}</a></span
                      >
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
              </div>
            </div>
            <div class="form right">
              <div class="inform notes">
                <header>Notes for the interpreter (brief description of what will be discussed)</header>
                <span
                  >Brief description of what will be discussed during the booking, and any special instructions for the
                  interpreter. This information will be shared with the interpreter</span
                >
              </div>
              <div class="inform brief">
                <header>Confidential notes for the booking office</header>
                <span
                  >Preferences, notes for the booking office. This information will be not shared with the
                  interpreter</span
                >
              </div>
              <div class="inform recorded">
                <header>Will this be filmed/recorded/live streamed?</header>
                <span
                  >Interpreters need to know if they may be filmed or recorded, so they can give consent to this and/or
                  the possibility of their work being shared or published at a later date.</span
                >
              </div>
              <div class="inform job">
                <header>Job Document</header>
                <span
                  >Please upload any documents that will be useful for the interpreter to prepare, i.e. agenda, speech
                  notes, presentation slides, etc.</span
                >
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [hidden]="!steps.isActive(5)" #bookingFormStep5="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Deaf Client
                <span class="title-link">
                  <span class="hidden-mobile"> fields with</span> <span class="red"> * </span> are required
                </span>
              </div>
              <div class="form content">
                <div class="medium-12">
                  <label class="reducedHeight">
                    Are you the deaf client?
                    <span mdTooltip="Please choose Yes/No">
                      <i class="inline-icon-tip"></i>
                    </span>
                  </label>
                  <div class="row-wrapper">
                    <div class="checkbox-group">
                      <input
                        id="currentUserIsClientYes"
                        type="radio"
                        name="currentUserIsClientYes"
                        [value]="true"
                        [disabled]="isDisabledForOrgRepIndClient || clientAvailability"
                        [(ngModel)]="currentUserIsClient"
                        (change)="onClientSelectionChange()"
                      /><label for="currentUserIsClientYes">Yes</label>
                      <input
                        id="currentUserIsClientNo"
                        type="radio"
                        name="currentUserIsClientNo"
                        [value]="false"
                        [disabled]="isDisabledForOrgRepIndClient || clientAvailability"
                        [(ngModel)]="currentUserIsClient"
                        (change)="onClientSelectionChange()"
                      /><label for="currentUserIsClientNo">No</label>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="row-wrapper" *ngIf="!isDeafClientExpanded()">
                  <div class="row-client">
                    <div class="medium-4 form field">
                      <div style="width: 150px;">
                        {{ bookingModel.deaf_person.first_name }} {{ bookingModel.deaf_person.last_name }}
                        <!--<span class="linked">Linked</span>-->
                      </div>
                      <div>{{ bookingModel.deaf_person.email }}</div>
                      <div>{{ bookingModel.deaf_person.mobile_number }}</div>
                    </div>
                    <div class="medium-4 form field">
                      <button name="changeClient" type="button" class="button" (click)="changeDeafClient()">Change</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isDeafClientExpanded()" class="edit-client">
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientFirstName">
                          Deaf client first name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter deaf person first name">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="clientFirstName"
                          name="clientFirstName"
                          type="text"
                          placeholder="Enter deaf person first name"
                          [readonly]="isIndClient() || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [(ngModel)]="bookingModel.deaf_person.first_name"
                          (ngModelChange)="isClientAvailable = null"
                          #clientFirstNameModel="ngModel"
                          [rangeLength]="[2, 20]"
                          required
                        />
                        <span
                          *ngIf="
                            clientFirstNameModel.touched &&
                            !clientFirstNameModel.hasError('required') &&
                            !clientFirstNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            clientFirstNameModel.touched &&
                            (clientFirstNameModel.hasError('required') || clientFirstNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientLastName">
                          Deaf client last name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter deaf person last name">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="clientLastName"
                          name="clientLastName"
                          type="text"
                          placeholder="Enter deaf person last name"
                          [readonly]="isIndClient() || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [(ngModel)]="bookingModel.deaf_person.last_name"
                          (ngModelChange)="isClientAvailable = null"
                          #clientLastNameModel="ngModel"
                          [rangeLength]="[2, 20]"
                          required
                        />
                        <span
                          *ngIf="
                            clientLastNameModel.touched &&
                            !clientLastNameModel.hasError('required') &&
                            !clientLastNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            clientLastNameModel.touched &&
                            (clientLastNameModel.hasError('required') || clientLastNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientEmail">
                          Deaf client email
                          <span mdTooltip="Enter deaf person email address"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="clientEmail"
                          name="clientEmail"
                          type="email"
                          placeholder="Enter deaf person email address"
                          [readOnly]="isDisabledForLinkedClient || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [(ngModel)]="bookingModel.deaf_person.email"
                          (ngModelChange)="isClientAvailable = null"
                          #contactEmailModel="ngModel"
                          email="bookingModel.deaf_person.email !== ''"
                        />
                        <span
                          *ngIf="contactEmailModel.touched &&
                          !contactEmailModel.hasError('required') && !contactEmailModel.hasError('email')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="contactEmailModel.touched &&
                          (contactEmailModel.hasError('required') || contactEmailModel.hasError('email'))"
                          class="inline-icon error"
                          ><span>Email is invalid</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientMobile">
                          Deaf client number
                          <span mdTooltip="Enter Contact Number"> <i class="inline-icon-tip"></i> </span
                        ></label>
                        <input
                          id="clientMobile"
                          name="clientMobile"
                          type="tel"
                          placeholder="Enter deaf person Number"
                          [readOnly]="isDisabledForLinkedClient || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [ngModel]="bookingModel.deaf_person.mobile_number | removeSpace"
                          (ngModelChange)="bookingModel.deaf_person.mobile_number = $event; isClientAvailable = null"
                          #clientMobileModel="ngModel"
                          digits
                          [rangeLength]="[10, 11]"
                        />
                        <span
                          *ngIf="clientMobileModel.touched && !clientMobileModel.hasError('email')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="clientMobileModel.touched && clientMobileModel.hasError('rangeLength')"
                          class="inline-icon error"
                        >
                          <span>Minimum 10 digits required.</span>!
                        </span>
                        <span
                          *ngIf="clientMobileModel.touched && clientMobileModel.hasError('digits')"
                          class="inline-icon error"
                        >
                          <span>Enter digits only.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper layout-align-end" *ngIf="(bookingModel.bookable_type === 'OrganisationalRepresentative' && !isIndClient()) || isUserOrgRep()">
                    <div class="medium-9" name="clientAvailability" *ngIf="hasClientId && !isIndClient() && isClientAvailable">
                      <p>
                        Do you want to link the client to this booking?
                        <span mdTooltip="By linking a booking to a client, the client will be able to see the details of the booking. They won't be able to make any changes to the booking.">
                          <i class="inline-icon-tip"></i>
                        </span>
                      </p>
                      <div class="row-wrapper">
                        <div class="checkbox-group">
                          <input
                            id="clientAvailabilityYes"
                            type="radio"
                            name="clientAvailabilityYes"
                            [value]="true"
                            [(ngModel)]="clientAvailability"
                            (change)="onChangeLinkedClient()"
                            [disabled]="isDisabledForOrgRepIndClient"
                          /><label for="clientAvailabilityYes">Yes</label>
                          <input
                            id="clientAvailabilityNo"
                            type="radio"
                            name="clientAvailabilityNo"
                            [value]="false"
                            [(ngModel)]="clientAvailability"
                            (change)="onChangeLinkedClient()"
                            [disabled]="isDisabledForOrgRepIndClient"
                          /><label for="clientAvailabilityNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="medium-3 withPadding">
                      <div class="form-field">
                        <button
                          name="clientAvailabilitySearch"
                          class="button client-search"
                          (click)='checkClientAvailability(
                            clientFirstNameModel,
                            clientLastNameModel,
                            contactEmailModel
                          )'
                          [disabled]="disableSearch() || clientAvailability"
                          [ngStyle]="{'opacity':(disableSearch() || clientAvailability) ? 0.5 : 1}">Search</button>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="isClientAvailable != null" [ngClass]="{'note_green': isClientFound, 'note_red': !isClientFound}">{{availabilityMsg.toUpperCase()}}</p>
                  <div class="row-wrapper">
                    <div class="medium-12">
                      <button
                        type="button"
                        class="contact-btn default"
                        (click)="
                          closeDeafClient(
                            clientFirstNameModel,
                            clientLastNameModel,
                            contactEmailModel,
                            clientMobileModel
                          )
                        "
                      >
                        <span>⌃</span> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="row-wrapper" *ngIf="!isDeafClientExtraExpanded()">
                  <div class="row-client">
                    <div class="medium-4 form field">
                      <div class="row-wrapper">
                        <div class="item-client">
                          <div class="text-normal">NDIS ID</div>
                          <div class="text-normal">EAF ID</div>
                          <div class="text-normal">UR Number</div>
                        </div>
                        <div class="item-client">
                          <div class="client-ids">{{ bookingModel.deaf_person.ndis_id }}</div>
                          <div class="client-ids">{{ bookingModel.deaf_person.eaf }}</div>
                          <div class="client-ids">{{ bookingModel.deaf_person.ur_number }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="medium-4 form field">
                      <button name="changeClientExtra" type="button" class="button" (click)="changeDeafClientExtra()">Change</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isDeafClientExtraExpanded()" class="edit-client">
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientNDIS">
                          NDIS ID
                          <span mdTooltip="Enter deaf person NDIS ID">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="clientNDIS"
                          name="clientNDIS"
                          type="tel"
                          placeholder="Enter deaf person NDIS ID"
                          [readonly]="isDisabledForOrgRepIndClient || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [(ngModel)]="bookingModel.deaf_person.ndis_id"
                          (ngModelChange)="isClientAvailable = null"
                        />
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientEAF">
                          EAF ID
                          <span mdTooltip="Enter deaf person EAF ID">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="clientEAF"
                          name="clientEAF"
                          type="tel"
                          placeholder="Enter deaf person EAF ID"
                          [readonly]="isDisabledForOrgRepIndClient || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [(ngModel)]="bookingModel.deaf_person.eaf"
                          (ngModelChange)="isClientAvailable = null"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="clientEAF">
                          UR NUMBER
                          <span mdTooltip="Enter deaf person UR NUMBER">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="clientUR"
                          name="clientUR"
                          type="tel"
                          placeholder="Enter deaf person UR number"
                          [readonly]="isDisabledForOrgRepIndClient || clientAvailability"
                          (click)="fireNotification($event, clientAvailability)"
                          [(ngModel)]="bookingModel.deaf_person.ur_number"
                          (ngModelChange)="isClientAvailable = null"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-12">
                      <button type="button" class="contact-btn default" (click)="closeDeafClientExtra()">
                        <span>⌃</span> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
              </div>
            </div>
            <div class="form right">
              <div class="inform print-invoice">
                <header>ID number</header>
                <span>These will appear on the invoice</span>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [hidden]="!steps.isActive(6)" #bookingFormStep6="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Preferences
                <span class="title-link">
                  <span class="hidden-mobile">fields with</span><span class="red"> * </span> are required
                </span>
              </div>
              <div class="form content">
                <div class="row-wrapper">
                  <div class="medium-5">
                    <div class="pref-item">
                      <p name="preferredInterpreterQuestion">Do you have preferred interpreters for this booking?</p>
                      <div class="row-wrapper">
                        <div class="checkbox-group">
                          <input
                            id="preferredInterpretersYes"
                            type="radio"
                            name="preferredInterpretersYes"
                            [value]="true"
                            [(ngModel)]="showPreferred"
                            (change)="onPreferredInterpreterChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                          />
                          <label for="preferredInterpretersYes">Yes</label>
                          <input
                            id="preferredInterpretersNo"
                            type="radio"
                            name="preferredInterpretersNo"
                            [value]="false"
                            [(ngModel)]="showPreferred"
                            (change)="onPreferredInterpreterChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                            [defaultChecked]="true"
                          />
                          <label for="preferredInterpretersNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="showPreferred && hasPreferred" class="pref-item">
                      <p name="preferredProfileInterpreterQuestion">Do you want to set the preferred interpreters that you have set in your profile?</p>
                      <div class="row-wrapper">
                        <div class="checkbox-group">
                          <input
                            type="radio"
                            id="profilePreferredInterpretersYes"
                            name="profilePreferredInterpretersYes"
                            [value]="true"
                            [(ngModel)]="showProfilePreferred"
                            (change)="onProfilePreferredChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                          />
                          <label for="profilePreferredInterpretersYes">Yes</label>
                          <input
                            type="radio"
                            id="profilePreferredInterpretersNo"
                            name="profilePreferredInterpretersNo"
                            [value]="false"
                            [(ngModel)]="showProfilePreferred"
                            (change)="onProfilePreferredChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                            [defaultChecked]="true"
                          />
                          <label for="profilePreferredInterpretersNo">No</label>
                        </div>
                      </div>
                    </div>
                    <app-add-interpreters
                      *ngIf="showPreferred"
                      [isPreferred]="true"
                      [isHidden]="true"
                      [selectedInterpreters]="oldInterpreterPreference"
                      [isEditable]="!isDisabledForOrgRepIndClient"
                    ></app-add-interpreters>
                  </div>
                  <div class="medium-1"></div>
                  <div class="medium-5">
                    <div class="pref-item">
                      <p name="blockedInterpreterQuestion">Do you have blocked interpreters for this booking?</p>
                      <div class="row-wrapper">
                        <div class="checkbox-group">
                          <input
                            type="radio"
                            id="blockedInterpretersYes"
                            name="blockedInterpretersYes"
                            [value]="true"
                            [(ngModel)]="showBlocked"
                            (change)="onBlockedChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                          />
                          <label for="blockedInterpretersYes">Yes</label>
                          <input
                            type="radio"
                            id="blockedInterpretersNo"
                            name="blockedInterpretersNo"
                            [value]="false"
                            [(ngModel)]="showBlocked"
                            (change)="onBlockedChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                            [defaultChecked]="true"
                          />
                          <label for="blockedInterpretersNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="showBlocked && hasBlocked" class="pref-item">
                      <p name="blockedProfileInterpreterQuestion">Do you want to set the blocked interpreters that you have set in your profile?</p>
                      <div class="row-wrapper">
                        <div class="checkbox-group">
                          <input
                            type="radio"
                            id="blockedProfileInterpretersYes"
                            name="blockedProfileInterpretersYes"
                            [value]="true"
                            [(ngModel)]="showProfileBlocked"
                            (change)="onProfileBlockedChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                          />
                          <label for="blockedProfileInterpretersYes">Yes</label>
                          <input
                            type="radio"
                            id="blockedProfileInterpretersNo"
                            name="blockedProfileInterpretersNo"
                            [value]="false"
                            [(ngModel)]="showProfileBlocked"
                            (change)="onProfileBlockedChange()"
                            [disabled]="isDisabledForOrgRepIndClient"
                            [defaultChecked]="true"
                          />
                          <label for="blockedProfileInterpretersNo">No</label>
                        </div>
                      </div>
                    </div>
                    <app-add-interpreters
                      *ngIf="showBlocked"
                      [isPreferred]="false"
                      [isEditable]="!isDisabledForOrgRepIndClient"
                      [isHidden]="true"
                      [selectedInterpreters]="oldInterpreterPreference"
                    >
                    </app-add-interpreters>
                  </div>
                </div>

                <div class="separate"></div>
                Certifications?
                <p class="description">Do you require one of the following? Select multiple if relevant</p>

                <div class="checkbox-group">
                  <div class="checkbox-item">
                    <input type="checkbox" id="cbBlueCard" name="cbBlueCard" [(ngModel)]="bookingModel.cbBlueCard" />
                    <label for="cbBlueCard">Blue CARD</label>
                  </div>
                  <div class="checkbox-item">
                    <input
                      type="checkbox"
                      id="cbYellowCard"
                      name="cbYellowCard"
                      [(ngModel)]="bookingModel.cbYellowCard"
                    /><label for="cbYellowCard">Yellow CARD</label>
                  </div>
                  <div class="checkbox-item">
                    <input
                      type="checkbox"
                      id="cbPoliceCheck"
                      name="cbPoliceCheck"
                      [(ngModel)]="bookingModel.cbPoliceCheck"
                    /><label for="cbPoliceCheck">Police Check</label>
                  </div>
                  <div class="checkbox-item">
                    <input type="checkbox" id="cbForm27A" name="cbForm27A" [(ngModel)]="bookingModel.cbForm27A" /><label
                      for="cbForm27A"
                      >Form 27A</label
                    >
                  </div>
                  <div class="checkbox-item">
                    <input
                      type="checkbox"
                      id="cbImmunisation"
                      name="cbImmunisation"
                      [(ngModel)]="bookingModel.cbImminisations"
                    /><label for="cbImmunisation">Immunisation</label>
                  </div>
                  <div class="checkbox-item">
                    <input
                      type="checkbox"
                      id="cbWorkWithChildCheck"
                      name="cbWorkWithChildCheck"
                      [(ngModel)]="bookingModel.cbworkWithChildCheck"
                    /><label for="cbWorkWithChildCheck">Working with children check</label>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="medium-6">
                  <label>Gender</label>
                  <div class="row-wrapper">
                    <div class="checkbox-group">
                      <input
                        type="radio"
                        id="male1"
                        name="male"
                        value="male"
                        [(ngModel)]="bookingModel.gender_preference"
                        [disabled]="isDisabledForOrgRepIndClient"
                      />
                      <label for="male1">Male</label>
                      <input
                        type="radio"
                        id="male2"
                        name="female"
                        value="female"
                        [(ngModel)]="bookingModel.gender_preference"
                        [disabled]="isDisabledForOrgRepIndClient"
                      />
                      <label for="male2">Female</label>
                      <input
                        type="radio"
                        id="male3"
                        name="no_preference"
                        value="no_preference"
                        [(ngModel)]="bookingModel.gender_preference"
                        [disabled]="isDisabledForOrgRepIndClient"
                        [defaultChecked]="true"
                      />
                      <label for="male3">Do not have a preference</label>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
              </div>
            </div>
            <div class="form right">
              <div class="inform interpreter">
                <header>Interpreter Preference</header>
                <span
                  >You can add your preferred and blocked interpreters to the booking form, this information will be
                  used to help the booking office match an interpreter to your booking</span
                >
              </div>
              <div class="inform preferred">
                <header>Preferred interpreters</header>
                <span
                  >Preferred interpreters will be contacted first for their availability. If they are not available we
                  may contact other suitable interpreters</span
                >
              </div>
              <div class="inform blocked">
                <header>Blocked interpreters</header>
                <span>Blocked interpreters will not be contacted or assigned to your booking</span>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [hidden]="!steps.isActive(7)" #bookingFormStep7="ngForm">
        <div class="container">
          <div class="medium-12">
            <div class="form left">
              <div class="title">
                Billing details<span class="title-link"
                  ><span class="hidden-mobile">fields with</span> <span class="red"> *</span> are required</span
                >
              </div>
              <div class="form content">
                <div class="row-wrapper" *ngIf="!isBillingContactExpanded()">
                  <div class="row-client">
                    <div class="medium-4  form field">
                      <div>
                        {{ bookingModel.client.organisation_primary_contact.first_name }}
                        {{ bookingModel.client.organisation_primary_contact.last_name }}
                      </div>
                      <div>{{ bookingModel.client.organisation_primary_contact.email }}</div>
                      <div>{{ bookingModel.client.organisation_primary_contact.mobile_number }}</div>
                    </div>
                    <div class="medium-4 form field">
                      <button name="changeBillingContact" type="button" class="button" (click)="changeBillingContact()">Change</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isBillingContactExpanded()" class="edit-client">
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingFirstName">
                          Contact first name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter billing person first name">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingFirstName"
                          name="billingFirstName"
                          type="text"
                          placeholder="Enter your first name"
                          [readonly]="isDisabledForLinkedClient"
                          [(ngModel)]="bookingModel.client.organisation_primary_contact.first_name"
                          #billingFirstNameModel="ngModel"
                          [rangeLength]="[2, 20]"
                          required
                        />
                        <span
                          *ngIf="
                            billingFirstNameModel.touched &&
                            !billingFirstNameModel.hasError('required') &&
                            !billingFirstNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingFirstNameModel.touched &&
                            (billingFirstNameModel.hasError('required') ||
                              billingFirstNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingLastName">
                          Contact last name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter billing person last name">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingLastName"
                          name="billingLastName"
                          type="text"
                          placeholder="Enter your last name"
                          [readonly]="isDisabledForLinkedClient"
                          [(ngModel)]="bookingModel.client.organisation_primary_contact.last_name"
                          #billingLastNameModel="ngModel"
                          [rangeLength]="[2, 20]"
                          required
                        />
                        <span
                          *ngIf="
                            billingLastNameModel.touched &&
                            !billingLastNameModel.hasError('required') &&
                            !billingLastNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingLastNameModel.touched &&
                            (billingLastNameModel.hasError('required') || billingLastNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingEmail">
                          Contact email
                          <span class="red"> * </span>
                          <span mdTooltip="Enter billing person email">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingEmail"
                          name="billingEmail"
                          type="email"
                          placeholder="Enter your email"
                          [readonly]="isDisabledForLinkedClient"
                          [(ngModel)]="bookingModel.client.organisation_primary_contact.email"
                          #billingEmailModel="ngModel"
                          email
                          required
                        />
                        <span
                          *ngIf="billingEmailModel.touched && !billingEmailModel.hasError('email')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingEmailModel.touched &&
                            (billingEmailModel.hasError('required') || billingEmailModel.hasError('email'))
                          "
                          class="inline-icon error"
                        >
                          <span>Email is required</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingNumber">
                          Contact number
                          <span class="red"> * </span>
                          <span mdTooltip="Enter billing person number">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingNumber"
                          name="billingNumber"
                          type="text"
                          placeholder="Enter your number"
                          [readonly]="isDisabledForLinkedClient"
                          [ngModel]="bookingModel.client.organisation_primary_contact.phone_number | removeSpace"
                          (ngModelChange)="bookingModel.client.organisation_primary_contact.phone_number = $event"
                          #billingNumberModel="ngModel"
                          [rangeLength]="[10, 11]"
                          required
                          digits
                        />
                        <span
                          *ngIf="
                            billingNumberModel.touched &&
                            !billingNumberModel.hasError('rangeLength') &&
                            !billingNumberModel.hasError('digits') &&
                            !billingNumberModel.hasError('required')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingNumberModel.touched &&
                            (billingNumberModel.hasError('rangeLength') || billingNumberModel.hasError('required'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 10 digits required.</span>!
                        </span>
                        <span
                          *ngIf="billingNumberModel.touched && billingNumberModel.hasError('digits')"
                          class="inline-icon error"
                        >
                          <span>Enter digits only.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-12">
                      <button
                        type="button"
                        class="contact-btn default"
                        (click)="
                          closeBillingContact(
                            billingFirstNameModel,
                            billingLastNameModel,
                            billingEmailModel,
                            billingNumberModel
                          )
                        "
                      >
                        <span>⌃</span> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="separate"></div>
                <div class="row-wrapper" *ngIf="!isBillingDetailExpanded()">
                  <div class="row-client">
                    <div class="medium-4 form field">
                      <div class="">Billing Address</div>
                      <div class="">
                        {{ bookingModel.client.organisation_billing_account.organisation_billing_address.unit_number }}
                        {{
                          bookingModel.client.organisation_billing_account.organisation_billing_address.street_number
                        }}
                        {{ bookingModel.client.organisation_billing_account.organisation_billing_address.street_name }}
                      </div>
                      <div class="">
                        {{ bookingModel.client.organisation_billing_account.organisation_billing_address.suburb }},
                        {{ bookingModel.client.organisation_billing_account.organisation_billing_address.state }}
                        {{ bookingModel.client.organisation_billing_account.organisation_billing_address.post_code }}
                      </div>
                    </div>
                    <div class="medium-4 form field">
                      <button name="changeBillingAddress" type="button" class="button" (click)="changeBillingDetail()">Change</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isBillingDetailExpanded()" class="edit-client">
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingUnitNumber">
                          Unit number
                          <span mdTooltip="Enter the Unit Number">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingUnitNumber"
                          name="billingUnitNumber"
                          type="text"
                          placeholder="Enter the Unit Number"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          [(ngModel)]="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.unit_number
                          "
                          #billingUnitNumberModel="ngModel"
                        />
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingStreetNumber">
                          Street number
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Street Number">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingStreetNumber"
                          name="billingStreetNumber"
                          type="text"
                          placeholder="Enter the Street Number"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          [ngModel]="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.street_number
                              | removeSpace
                          "
                          (ngModelChange)="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.street_number = $event
                          "
                          #billingStreetNumberModel="ngModel"
                          [rangeLength]="[1, 10]"
                          required
                        />
                        <span
                          *ngIf="
                            billingStreetNumberModel.touched &&
                            !billingStreetNumberModel.hasError('required') &&
                            !billingStreetNumberModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingStreetNumberModel.touched &&
                            (billingStreetNumberModel.hasError('required') ||
                              billingStreetNumberModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 1 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingStreetName">
                          Street name
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Street Name">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingStreetName"
                          name="billingStreetName"
                          type="text"
                          placeholder="Enter the Street Name"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          [(ngModel)]="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.street_name
                          "
                          #billingStreetNameModel="ngModel"
                          [rangeLength]="[2, 30]"
                          required
                        />
                        <span
                          *ngIf="
                            billingStreetNameModel.touched &&
                            !billingStreetNameModel.hasError('required') &&
                            !billingStreetNameModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingStreetNameModel.touched &&
                            (billingStreetNameModel.hasError('required') ||
                              billingStreetNameModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingSuburb">
                          Suburb
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Suburb">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingSuburb"
                          name="billingSuburb"
                          type="text"
                          placeholder="Enter the Suburb"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          [(ngModel)]="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.suburb
                          "
                          #billingSuburbModel="ngModel"
                          [rangeLength]="[3, 30]"
                          required
                        />
                        <span
                          *ngIf="
                            billingSuburbModel.touched &&
                            !billingSuburbModel.hasError('required') &&
                            !billingSuburbModel.hasError('rangeLength')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingSuburbModel.touched &&
                            (billingSuburbModel.hasError('required') || billingSuburbModel.hasError('rangeLength'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 2 characters required.</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingPostCode">
                          Post code
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Post Code">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <input
                          id="billingPostCode"
                          name="billingPostCode"
                          type="tel"
                          placeholder="Enter the Post Code"
                          [readonly]="isDisabledForLinkedClient"
                          (click)="fireNotification($event)"
                          [ngModel]="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.post_code
                              | removeSpace
                          "
                          (ngModelChange)="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.post_code = $event
                          "
                          #billingPostCodeModel="ngModel"
                          [rangeLength]="[3, 30]"
                          required
                          digits
                        />
                        <span
                          *ngIf="
                            billingPostCodeModel.touched &&
                            !billingPostCodeModel.hasError('required') &&
                            !billingPostCodeModel.hasError('rangeLength') &&
                            !billingPostCodeModel.hasError('digits')
                          "
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="
                            billingPostCodeModel.touched &&
                            (billingPostCodeModel.hasError('required') ||
                              billingPostCodeModel.hasError('rangeLength') ||
                              billingPostCodeModel.hasError('digits'))
                          "
                          class="inline-icon error"
                        >
                          <span>Minimum 4 characters required.</span>!
                        </span>
                      </div>
                    </div>
                    <div class="medium-6 withPadding">
                      <div class="form-field">
                        <label for="billingState">
                          State
                          <span class="red"> * </span>
                          <span mdTooltip="Enter the Booking State">
                            <i class="inline-icon-tip"></i>
                          </span>
                        </label>
                        <select
                          id="billingState"
                          name="billingState"
                          aria-label="A"
                          [disabled]="isDisabledForLinkedClient"
                          [(ngModel)]="
                            bookingModel.client.organisation_billing_account.organisation_billing_address.state
                          "
                          #billingStateModel="ngModel"
                          required
                        >
                          <option *ngFor="let state_name of StatesNames" [value]="state_name">{{ state_name }}</option>
                        </select>
                        <span
                          *ngIf="billingStateModel.touched && !billingStateModel.hasError('required')"
                          class="inline-icon inline-icon-valid valid"
                        ></span>
                        <span
                          *ngIf="billingStateModel.touched && billingStateModel.hasError('required')"
                          class="inline-icon error"
                        >
                          <span>Booking state is required</span>!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row-wrapper">
                    <div class="medium-12">
                      <button
                        type="button"
                        class="contact-btn default"
                        (click)="
                          closeBillingDetail(
                            billingUnitNumberModel,
                            billingStreetNumberModel,
                            billingStreetNameModel,
                            billingSuburbModel,
                            billingPostCodeModel,
                            billingStateModel
                          )
                        "
                      >
                        <span>⌃</span> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="medium-12">
                  <label for="reference"
                    >Reference
                    <span mdTooltip="Enter External Reference Number!">
                      <i class="inline-icon-tip"></i>
                    </span>
                  </label>
                  <input
                    id="reference"
                    type="text"
                    name="reference"
                    placeholder="Enter Reference"
                    [readOnly]="isDisabledForLinkedClient"
                    [(ngModel)]="bookingModel.client.organisation_billing_account.external_reference"
                  />
                </div>
                <ng-container *ngIf="checkUserAdminORBookOfficer()">
                  <div class="separate"></div>
                  <div class="medium-12">
                    <label for="general_notes">General notes</label>
                    <textarea
                      id="general_notes"
                      [(ngModel)]="bookingModel.general_notes"
                      name="general_notes"
                      type="text"
                    ></textarea>
                  </div>
                </ng-container>
                <ng-container *ngIf="checkUserAdminORBookOfficer() && !forEdit">
                  <div class="separate"></div>
                  <div class="medium-12">
                    <label for="claim_notes">Claim notes</label>
                    <textarea
                      id="claim_notes"
                      [(ngModel)]="bookingModel.claim_notes"
                      name="claim_notes"
                      type="text"
                    ></textarea>
                  </div>
                </ng-container>
                <div class="separate"></div>
                <div
                  class="medium-12"
                  id="travel-cost-applicable"
                  name="travel-cost-applicable"
                  *ngIf="travelCostApplicable"
                >
                  This is a regional booking and travel costs might be billed when there is no regional interpreter
                  available.<br />
                  I give permission to book a metro interpreter if needed.
                </div>
                <div class="separate"></div>
                <div class="medium-12">
                  <div class="policy">
                    <input
                      type="checkbox"
                      id="policy"
                      name="policy"
                      [disabled]="isDisabledForLinkedClient"
                      [(ngModel)]="termsAndConditionAccepted"
                    />
                    <label name="tnc" for="policy">I agree to</label>
                    <a name="lnkTC" target="_blank" [href]="TermAndConditionUrl"><span class="green">Terms & Conditions</span></a>
                  </div>
                </div>
                <div class="separate"></div>
              </div>
            </div>
            <div class="form right">
              <div class="inform purchase">
                <header>Reference number</header>
                <span>i.e. purchase order, case number. This is for your records and will appear on the invoice</span>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="footer">
        <div class="field">
          <div class="footer-buttons">
            <div class="back">
              <button
                name="button-back"
                type="button"
                class="button back"
                [class.disabled]="!steps.isPrevious()"
                (click)="steps.previous()"
              >&larr; Back</button>
            </div>
            <button *ngIf="steps.isNext()" name="button-next" type="button" class="button next" (click)="stepNext()">Next &rarr;</button>
            <button
              *ngIf="isBookingFormValid() || steps.isActive(7)"
              name="button-finish"
              type="submit"
              class="button finish"
              [hidden]="bookingModel.bookable_type === 'OrganisationalRepresentative' && isIndClient()"
            >FINISH</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</section>
