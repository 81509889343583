<div class="row small-collapse" ngForm="interpreterForm" #interpreterForm="ngForm">
  <div class="row-wrapper">
    <div class="medium-6 columns">
      <div class="form-field">
        <label>NAATI ID *</label>
        <input type="tel" placeholder="Please enter your NAATI Id"
                [(ngModel)]="userModel.naati_id" name="naati_id"
                #naati_id="ngModel" required>

        <span *ngIf="naati_id.touched && !naati_id.errors?.required;"
              class="inline-icon inline-icon-valid valid"></span>
        <span *ngIf="naati_id.touched && naati_id.errors?.required;"
              class="inline-icon error">
          <span>NAATI ID is required.</span>!
        </span>
      </div>
    </div>
     <div class="medium-6 columns">
      <div class="form-field">
        <label>NAATI VALIDITY START DATE</label>
        <input type="date" placeholder="Please enter NAATI validity start date"
                [ngModel]="userModel.naati_validity_start_date"
               (ngModelChange)="userModel.naati_validity_start_date = $event"
               name="naati_validity_start_date" 
                #naati_validity_start_date="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>NAATI VALIDITY END DATE</label>
        <input type="date" placeholder="Please enter NAATI validity end date"
                [ngModel]="userModel.naati_validity_end_date" 
               (ngModelChange)="userModel.naati_validity_end_date = $event"
               name="naati_validity_end_date"
                #naati_validity_end_date="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>DATE OF BIRTH *</label>
        <input type="date" class="valid" placeholder="Date of Birth"
               [ngModel]="userModel.date_of_birth" (ngModelChange)="userModel.date_of_birth = $event"
               name="date_of_birth" 
                #date_of_birth="ngModel"
               required>

        <span *ngIf="date_of_birth.touched && !date_of_birth.errors?.required;"
              class="inline-icon inline-icon-valid valid"></span>
        <span *ngIf="date_of_birth.touched && date_of_birth.errors?.required;"
              class="inline-icon error">
          <span>Date of birth is required.</span>!
        </span>
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>COMM. PREFERENCE *</label>
        <select placeholder="Enter the communication preference" aria-label="A"
                [(ngModel)]="userModel.communication_preference" name="comm_pref"
                #comm_pref="ngModel" required>
          <option value="sms_only">SMS</option>
          <option value="email_only">EMAIL</option>
          <option value="email_and_sms">SMS AND EMAIL</option>
        </select>
        <span *ngIf="comm_pref.touched && !comm_pref.errors?.required && !comm_pref.errors?.rangeLength;" class="inline-icon inline-icon-valid valid"></span>
        <span *ngIf="comm_pref.touched && (comm_pref.errors?.required || comm_pref.errors?.rangeLength);" class="inline-icon error">

        <span>This field is required.</span>!
      </span>
      </div>
    </div>
   
    <div class="medium-6 columns">
      <div class="form-field">
        <label>SKILL LEVEL *</label>
        <select placeholder="Enter the Skill level" aria-label="A" [(ngModel)]="userModel.skill_level"
                name="skill_level" #skill_level="ngModel" required>

          <option *ngFor="let skill_name of ['Certified Conference Interpreter','Certified Specialist Interpreter - Health & Legal','Certified Specialist Interpreter - Health', 'Certified Specialist Interpreter - Legal', 'Certified Interpreter', 'Certified Provisional Interpreter', 'Recognised Practising', 
          'Professional Level', 'Paraprofessional Level', 'Recognised', 'Captioning', 'Notetaking']"

                   [value]="skill_name">{{skill_name.toUpperCase()}}</option>

        </select>
        <span *ngIf="skill_level.touched && !skill_level.errors?.required && !skill_level.errors?.rangeLength;" class="inline-icon inline-icon-valid valid"></span>
        <span *ngIf="skill_level.touched && (skill_level.errors?.required || skill_level.errors?.rangeLength);" class="inline-icon error">

          <span>This field is required.</span>!
        </span>
      </div>
    </div>
  </div>
</div>

<br>
<app-address  [parentForm]="interpreterForm" [address]="userModel.address_attributes" #addressForm="ctAddressForm" [canCalculateDistance]="canCalculateDistance" [userModel]="userModel"></app-address>

<ng-container *ngIf="isUserAdminORBookOfficer()">
    <div class="medium-6 columns">
        <div class="form-field">
          <label>DATE APPROVED</label>
          <input type="date" placeholder="Please enter approve date"
                  [ngModel]="userModel.date_approved"
                 (ngModelChange)="userModel.date_approved = $event"
                 name="date_approved" #date_approved="ngModel">
        </div>
    </div>
    <div class="medium-12 columns">
        <div class="form-field">
            <label>INTERPRETER NOTES
            </label>
            <textarea name="interpreter_notes" [(ngModel)]="userModel.booking_office_notes" cols="30" rows="10"></textarea>
        </div>
    </div>
</ng-container>

<div class="row small-collapse">
  <div class="row-wrapper">
    <div class="medium-6 columns">
      <div class="form-field">

      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isUserAdminORBookOfficer()">
  <header>
    <h4 class="text-center">CERTIFICATIONS</h4>
  </header>
  <div name="cert_section" class="row-wrapper" style="margin-bottom: 45%;">
    <div class="medium-6 columns">
      <div class="form-field">
        <label>Working with children check</label>
        <input type="text" placeholder="Please enter working with children check"
                [(ngModel)]="userModel.working_with_childrens_check" name="working_with_child_check"
                #working_with_child_check="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>Blue card</label>
        <input type="text" placeholder="Please enter blue card"
                [(ngModel)]="userModel.blue_card" name="blue_card"
                #blue_card="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>Yellow card</label>
        <input type="text" placeholder="Please enter yellow card"
                [(ngModel)]="userModel.yellow_card" name="yellow_card"
                #yellow_card="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>Immunisation</label>
        <input type="text" placeholder="Please enter immunisation"
                [(ngModel)]="userModel.immunisations" name="immunisations"
                #immunisations="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>Police check</label>
        <input type="text" placeholder="Please enter police check"
                [(ngModel)]="userModel.police_check" name="police_check"
                #police_check="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>From 27A</label>
        <input type="text" placeholder="Please enter from 27a"
                [(ngModel)]="userModel.from_27a" name="from_27a"
                #from_27a="ngModel">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>Recorded Booking</label>
        <input type="text" placeholder="Please enter recoeded booking"
                [(ngModel)]="userModel.are_you_okay_with_bookings_that_will_be_recorded_or_published" name="recorded_booking"
                #recorded_booking="ngModel">
      </div>
    </div>
  </div>
</ng-container>
<div class="row small-collapse">
</div>
<ng-container *ngIf="displayCalendar && updateCalendar">
  <h1>BLOCKOUTS</h1>
  <angular2-fullcalendar id="mycal" #mycal [options]="calendarOptions"></angular2-fullcalendar>
  <button class= "padTop" name="modify_blockouts" [routerLink]="['/user-management/',-1,'block_out']">
    Add Blockout
  </button>
</ng-container>
