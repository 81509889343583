<header id="header">
	<img name="auslanLogo" alt="Auslan Connections" width="72" height="46" src="assets/img/auslan-logo.png">
	<nav *ngIf="userIsActive">
		<a id="lnkBooking" *ngIf="canShowLink('booking-management')" [ngClass]="{'active':isActiveLink(linkName.booking)}" (click)="setActiveLink(linkName.booking)" [routerLink]="['/booking-management']"><span><i class="icon-bookings"></i> BOOKINGS</span></a>
		<a id="lnkMessages" name='lnkMessages' *ngIf="canShowLink('messages') && isCurrentUserNotIndividualClientOrOrg()"
		   [ngClass]="{'active':isActiveLink(linkName.messages)}"
		   (click)="setActiveLink(linkName.messages)" [routerLink]="['/users/',getActiveUserId(),'inbox']">
			<div *ngIf="isCurrentUserAdminOrBookingOfficer()" class="messages-ticker" [ngStyle]="{'left': isAdmin()}">{{unreadMessageThreads}}</div>
			<span><i class="icon-messages"></i> MESSAGES</span>
		</a>
		<a id="lnkReport" *ngIf="canShowLink('reports')" [ngClass]="{'active':isActiveLink(linkName.reports)}" (click)="setActiveLink(linkName.reports)" [routerLink]="['/reports']"><span><i class="icon-reports"></i> REPORTS </span></a>
	</nav>

	<a *ngIf="userIsActive && canShowLink('create-booking')" id="lnkNewBooking" [ngClass]="{'active':isActiveLink(linkName.booking)}" (click)="setActiveLink(linkName.booking);refreshBooking()" class="button" [routerLink]="['/booking-management','create-booking']">+ New Booking</a>

	<section class="group-dropdown" *ngIf="userIsActive">
		<ul *ngIf="subOrganisationFilter.isVisibleToUser(currentUser)" class="dsq-service-dropdown dropdown menu click-handler" data-disable-hover="true" data-click-open="true" data-dropdown-menu="" onclick="this.classList.toggle('opened');">
			<li>
				<a [attr.class]="subOrganisationFilter.selected?.id"></a>
				<ul class="menu right-side green">
					<li *ngFor="let subOrganisation of subOrganisationFilter.unselectedList" (click)="subOrganisationFilter.select(subOrganisation)"><a [attr.class]="subOrganisation.id"></a></li>
				</ul>
			</li>
		</ul>
		<ul class="dropdown menu click-handler" data-disable-hover="true" data-click-open="true" data-dropdown-menu="" onclick="this.classList.toggle('opened');">
			<li>
				<a class = "ImgSize" id="lnkProfile" [ngClass]="{'active':isActiveLink(linkName.profile)}"
				   (click)="setActiveLink(linkName.profile)">
					<img src={{getPicturePath()}}>{{fullName}}</a>
				<ul class="menu right-side green">
					<li><a id="lnkProfile1" [ngClass]="{'active':isActiveLink(linkName.profile)}" (click)="setActiveLink(linkName.profile)" [routerLink]="['/user-management','profile']"><i class="icon-user"></i> Profile</a></li>
					<li><a id="lnkSettings" *ngIf="canShowLink('user-management')" [ngClass]="{'active':isActiveLink(linkName.usermanagement)}" (click)="setActiveLink(linkName.usermanagement)" [routerLink]="['/user-management']"><i class="icon-settings"></i> Users</a></li>
					<li><a id="lnkLogout" (click)='userLogout()'><i class="icon-logout"></i> Log Out</a></li>
				</ul>
			</li>
		</ul>
	</section>
</header>
