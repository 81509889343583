<form  ngForm="payrollTimeFields" #payrollTimeFields="ngForm">
    <footer class="payroll-card__footer common-form">
        <div class="payroll-card__measure">
            <span class="payroll-card__measure-name">Inter. time*</span>
            <div class="form-field">
                <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'interpreterTime_' + index}}" #interpreterTime="ngModel" [readonly]="isReadOnly"
                    [placeholder]="interpTimePlaceHolder" class="payroll-card__measure-input" [(ngModel)]="payrollInvoice.interpreting_time" pattern="(\d+|\d+(\.|\:)\d+)">
                <span *ngIf="interpreterTime.touched && (interpreterTime.errors?.pattern);"
                    class="inline-icon error">
                    <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
                </span>
            </div>
        </div>
        <div class="payroll-card__measure">
            <span class="payroll-card__measure-name">Prep. time</span>
            <div class="form-field">
                <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'prepTime_' + index}}" #prepTime="ngModel" [readonly]="isReadOnly"
                    [placeholder]="prepTimePlaceHolder" class="payroll-card__measure-input" [(ngModel)]="payrollInvoice.preparation_time" pattern="(\d+|\d+(\.|\:)\d+)">
                <span *ngIf="prepTime.touched && (prepTime.errors?.pattern);"
                    class="inline-icon error">
                    <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
                </span>
            </div>
        </div>
        <div class="payroll-card__measure">
            <span class="payroll-card__measure-name">KM*</span>
            <div class="form-field">
                <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'kiloMeters_' + index}}" #kiloMeters="ngModel" [readonly]="isReadOnly"
                    [placeholder]="kmPlaceHolder" class="payroll-card__measure-input" [(ngModel)]="payrollInvoice.distance" pattern="(\d+|\d+(\.|\:)\d+)">
                <span *ngIf="kiloMeters.touched && (kiloMeters.errors?.pattern);"
                    class="inline-icon error">
                    <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
                </span>
            </div>
        </div>
        <div class="payroll-card__measure">
            <span class="payroll-card__measure-name">Travel time*</span>
            <div class="form-field">
                <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'travelTime_' + index}}" #travelTime="ngModel" [readonly]="isReadOnly"
                    [placeholder]="travelTimePlaceHolder" class="payroll-card__measure-input" [(ngModel)]="payrollInvoice.travel_time" pattern="(\d+|\d+(\.|\:)\d+)">
                <span *ngIf="travelTime.touched && (travelTime.errors?.pattern);"
                    class="inline-icon error">
                    <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
                </span>
            </div>
        </div>
    </footer>
    <footer class="payroll-card__footer common-form">
        <div class="payroll-card__measure" *ngIf='isNG'>
            <span class="payroll-card__measure-name">Inter. time NG*</span>
            <div class="form-field">
                <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'interTimeNG_' + index}}" #interTimeNG="ngModel" [readonly]="isReadOnly"
                    id="{{(isClient ? 'client_' : 'interpreter_') + 'interTimeNG_' + index}}"
                    [placeholder]="travelTimePlaceHolder" class="payroll-card__measure-input" [(ngModel)]="payrollInvoice.interpreting_time_night" pattern="(\d+|\d+(\.|\:)\d+)">
                <span *ngIf="interTimeNG.touched && (interTimeNG.errors?.pattern);"
                    class="inline-icon error">
                    <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
                </span>
            </div>
        </div>
        <div class="payroll-card__measure" *ngIf='isWKN'>
            <span class="payroll-card__measure-name">Inter. time WKN*</span>
            <div class="form-field">
                <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'interTimeWKN_' + index}}" #interTimeWKN="ngModel" [readonly]="isReadOnly"
                    id="{{(isClient ? 'client_' : 'interpreter_') + 'interTimeWKN_' + index}}"
                    [placeholder]="travelTimePlaceHolder" class="payroll-card__measure-input" [(ngModel)]="payrollInvoice.interpreting_time_weekend" pattern="(\d+|\d+(\.|\:)\d+)">
                <span *ngIf="interTimeWKN.touched && (interTimeWKN.errors?.pattern);"
                    class="inline-icon error">
                    <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
                </span>
            </div>
        </div>
    </footer>
</form>
