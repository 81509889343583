<footer class="payroll-card__footer common-form" ngForm="payrollAdditionalCostFields" #payrollAdditionalCostFields="ngForm">
    <div class="payroll-card__measure">
        <span class="payroll-card__measure-name">Transfers</span>
        <div class="form-field">
            <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'interpreterTransfers_' + index}}" #interpreterTransfers="ngModel" [readonly]="isReadOnly"
                   [placeholder]="interpTransfersPlaceHolder" class="payroll-card__measure-input" [ngModel]="payrollInvoice?.transfers"
                   (ngModelChange)="payrollInvoice.transfers = $event"
                   pattern="\$?(\d+|\d+(\.)\d+)">
            <span *ngIf="interpreterTransfers.touched && (interpreterTransfers.errors?.pattern);"
                   class="inline-icon error">
                   <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
            </span>
        </div>
    </div>
    <div class="payroll-card__measure">
        <span class="payroll-card__measure-name">Meals</span>
        <div class="form-field">
            <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'meals_' + index}}" #meals="ngModel" [readonly]="isReadOnly"
                   [placeholder]="mealsPlaceHolder" class="payroll-card__measure-input" [ngModel]="payrollInvoice?.meals"
                   (ngModelChange)="payrollInvoice.meals = $event"
                   pattern="\$?(\d+|\d+(\.|\:)\d+)">
            <span *ngIf="meals.touched && (meals.errors?.pattern);"
                   class="inline-icon error">
                   <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
            </span>
        </div>
    </div>
    <div class="payroll-card__measure">
        <span class="payroll-card__measure-name">Accom.</span>
        <div class="form-field">
            <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'accom_' + index}}" #accom="ngModel" [readonly]="isReadOnly"
                   [placeholder]="accomPlaceHolder" class="payroll-card__measure-input" [ngModel]="payrollInvoice?.accommodations"
                   (ngModelChange)="payrollInvoice.accommodations = $event"
                   pattern="\$?(\d+|\d+(\.|\:)\d+)">
            <span *ngIf="accom.touched && (accom.errors?.pattern);"
                   class="inline-icon error">
                   <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
            </span>
        </div>
    </div>
    <div class="payroll-card__measure">
        <span class="payroll-card__measure-name">Flights</span>
        <div class="form-field">
            <input type="text" name="{{(isClient ? 'client_' : 'interpreter_') + 'flights_' + index}}" #flights="ngModel" [readonly]="isReadOnly"
                   [placeholder]="flightsPlaceHolder" class="payroll-card__measure-input" [ngModel]="payrollInvoice?.flights"
                   (ngModelChange)="payrollInvoice.flights = $event"
                   pattern="\$?(\d+|\d+(\.)\d+)">
            <span *ngIf="flights.touched && (flights.errors?.pattern);"
                   class="inline-icon error">
                   <span>Oops! Only numerical values, "." and ":" are allowed.</span>!
            </span>
        </div>
    </div>
</footer>
