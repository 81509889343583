<header>
    <h4 class="text-center">ORGANISATION DETAILS</h4>
</header>
<div ngForm="orgRepForm" #orgRepForm="ngForm" class="row small-collapse">
    <div class="row-wrapper">
        <div class="medium-6 columns">
            <div class="form-field">
                <label>ABN * </label>
                <input type="tel" class="valid" placeholder="Enter ABN"
                       [readonly]="isDuplicate"
                       [ngModel]="userModel.abn" (ngModelChange)="userModel.abn=$event" name="business_abn" #business_abn="ngModel"
                       [rangeLength]="[11, 11]" digit required>
                <span *ngIf="business_abn.touched && !business_abn.errors?.required && !business_abn.errors?.rangeLength;"
                      class="inline-icon inline-icon-valid valid"></span>
                <span *ngIf="business_abn.touched && (business_abn.errors?.required || business_abn.errors?.rangeLength);"
                      class="inline-icon error">
          <span>11 Digits are characters required.</span>!</span>
            </div>
        </div>
        <div class="medium-6 columns">
            <div class="form-field">
                <label>ORGANISATION'S NAME * </label>
                <input type="text" class="valid" placeholder="Enter the name of the Organisation"
                       [readonly]="isDuplicate"
                       [(ngModel)]="userModel.organisation_name" name="business_name" #business_name="ngModel" required>
                <span *ngIf="business_name.touched && !business_name.errors?.required"
                      class="inline-icon inline-icon-valid valid"></span>
                <span *ngIf="business_name.touched && business_name.errors?.required" class="inline-icon error">
          <span>Organisation name is required</span>!
        </span>
            </div>
        </div>
        <!-- div class="medium-6 columns">
          <div class="form-field">
            <label>Organisation's Group Email</label>
            <input type="email" class="valid" placeholder="Enter the group email of the Organisation"
                    [(ngModel)]="userModel.group_email" name="business_group_email" #business_group_email="ngModel" required>
            <span *ngIf="business_group_email.touched && !business_group_email.errors?.required" class="inline-icon inline-icon-valid valid"></span>
            <span *ngIf="business_group_email.touched && business_group_email.errors?.required" class="inline-icon error">
              <span>Organisation group email is required</span>!
            </span>
          </div>
        </div -->
        <div class="medium-6 columns">
            <div class="form-field">
                <label>ORGANISATION'S BRANCH OFFICE</label>
                <input type="text" placeholder="Enter the branch office of the Organisation"
                       [readonly]="isDuplicate"
                       [(ngModel)]="userModel.branch_office" name="business_branch_office"
                       #business_branch_office="ngModel">
            </div>
        </div>
        <div class="medium-6 columns">
            <div class="form-field">
                <label>PREFFERED CONTACT METHOD</label>
                <select name="preferred_contact_method"  [disabled]="isDuplicate"
                        [(ngModel)]="userModel.organisation_billing_account.preferred_contact_method">
                    <option value="email">EMAIL</option>
                    <option value="phone">PHONE</option>
                </select>
            </div>
        </div>
        <div *ngIf="isEdit" class="medium-6 columns">
            <div class="form-field">
                <label>COMM. PREFERENCE *</label>
                <select name="comm_pref"  [disabled]="isDuplicate"
                        [(ngModel)]="userModel.preferred_contact_method">
                    <option value="email_only">EMAIL</option>
                </select>
            </div>
        </div>
        <div *ngIf="checkUserAdminORBookOfficer()" class="medium-6 columns">
            <div class="form-field">
                <label>ACCOUNT NUMBER</label>
                <input type="text" placeholder="Enter your account number"
                        [ngModel]="userModel.linked_account_number | removeSpace"
                        (ngModelChange)="userModel.linked_account_number=$event" name="linked_account_number" #linked_account_number="ngModel">
            </div>
        </div>
    </div>
</div>


<br>

<app-address [isReadOnly]="isDuplicate" [prefix]="address_title" [address]="userModel.address_attributes" [parentForm]="orgRepForm" [canCalculateDistance]="false"></app-address>

<div class="row small-collapse">
    <div class="row-wrapper">
        <div class="medium-6 columns">
            <div class="form-field">
                <label>IS YOUR BILLING ADDRESS THE SAME AS YOUR ORGANISATION'S ADDRESS?</label>
                <md-checkbox  [disabled]="isDuplicate"
                              type="checkbox"
                             [(ngModel)]="userModel.billingAddressIsSame"
                             (change)="billingAddressNotAsOrg()"
                             name="billingAddressIsDifferent_confirmation"
                             #billingAddressIsDifferent_confirmation="ngModel">Yes
                </md-checkbox>
            </div>
        </div>
    </div>
</div>

<br>
<app-billing-account [isReadOnly]="isDuplicate"
                     [primaryContact]="userModel.organisation_primary_contact"
                     [parentForm]="orgRepForm" [canCalculateDistance]="false"
                     [billingAddress]="userModel.organisation_billing_account.organisation_billing_address"></app-billing-account>


<div class="row small-collapse">
    <div class="row-wrapper">
        <div class="medium-12 columns">
            <div class="form-field">
                <label>EXT. REFERENCE NUM</label>
                <input name="ext-ref-no"  [readonly]="isDuplicate"
                        [(ngModel)]="userModel.organisation_billing_account.external_reference" placeholder="Enter External Reference Number">
            </div>
        </div>
        <div class="medium-6 columns">
            <div class="form-field">
                <label>HOW DID YOU FIND OUT ABOUT AUSLAN CONNECTIONS?</label>
                <select  [disabled]="isDuplicate"
                         [(ngModel)]="userModel.reffered_by">
                    <option value="A DEAF PERSON TOLD ME">A DEAF PERSON TOLD ME</option>
                    <option value="AN INTERPRETER TOLD ME">AN INTERPRETER TOLD ME</option>
                    <option value="INTERNET SEARCH ENGINE">INTERNET SEARCH ENGINE</option>
                    <option value="GOOGLE">GOOGLE</option>
                    <option value="YELLOW PAGES">YELLOW PAGES</option>
                    <option value="WORD OF MOUTH">WORD OF MOUTH</option>
                    <option value="OTHER">OTHER</option>
                </select>
            </div>
        </div>
        <div class="medium-12 columns">
            <div class="form-field">
                <label>IF OTHER THEN PLEASE DESCRIBE HERE</label>
                <textarea  [readonly]="isDuplicate"
                           [disabled]="userModel.reffered_by !== 'OTHER'" [(ngModel)]="userModel.reffered_other" cols="30" rows="10"
                          class="inline-icon inline-icon-txt"></textarea>
            </div>
        </div>
        <div class="medium-12 columns">
            <div class="form-field">
                <label>SPECIAL INSTRUCTIONS FOR INTERPRETERS</label>
                <textarea  [readonly]="isDuplicate"
                           type="text" class="inline-icon inline-icon-txt"
                          cols="30" rows="10"
                          [(ngModel)]="userModel.special_instructions" name="special_instructions"
                          #special_instructions="ngModel"></textarea>

            </div>
        </div>
    </div>
</div>
<br>
