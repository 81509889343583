<div class="screen-guard-content" [ngClass]="{protected: lock$ | async}" #eventsTarget>
  <ng-content></ng-content>
</div>

<div class="screen-guard-container" *ngIf="lock$ | async" tabindex="0">
  <div class="login-container">
    <h1>Screen Locked</h1>
    <form class="common-form" [formGroup]="form" (ngSubmit)="unlock()">
      <p class="message">
        You have been inactive for 30 mins and your screen has been locked for security. Please reenter your password
        below to resume your session. "if you refresh this page any unsaved changes will be lost"
      </p>
      <div class="form-field">
        <label for="password"><i class="icon-lock"></i> PASSWORD</label>
        <input id="password" type="password" placeholder="Enter your password" autofocus formControlName="password" />
      </div>

      <button class="button green">Unlock</button>
    </form>
  </div>
</div>
