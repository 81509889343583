<app-mobile-header title="PROFILE" backLink="/booking-management"></app-mobile-header>

<section id="submenu">
  <app-user-header id="main-submenu-links">
  </app-user-header>
</section>

<div id="page-common">
<main>
  <form #user_profile_form="ngForm"  class="common-form reduced-top side-pad">
    <div class="row small-collapse">
      <div class="row-wrapper">
        <div class="medium-6 columns">
          <div class="form-field">
            <label>FIRST NAME *	</label>

            <input type="text" class="valid" placeholder="" [(ngModel)]="userModel.first_name" name="first_name" #first_name="ngModel" [rangeLength]="[2, 20]" required>
            <span *ngIf="first_name.touched && !first_name.errors?.required && !first_name.errors?.rangeLength;" class="inline-icon inline-icon-valid valid"></span>
            <span *ngIf="first_name.touched && (first_name.errors?.required || first_name.errors?.rangeLength);" class="inline-icon error">
                      <span>Minimum 2 characters required.</span>!</span>
          </div>
        </div>
        <div class="medium-6 columns">
          <div class="form-field">
            <label>LAST NAME *
            </label>

            <input type="text" class="valid" placeholder="" [(ngModel)]="userModel.last_name" name="last_name" #last_name="ngModel" [rangeLength]="[2, 30]" required>
            <span *ngIf="last_name.touched && !last_name.errors?.required && !last_name.errors?.rangeLength;" class="inline-icon inline-icon-valid valid"></span>
            <span *ngIf="last_name.touched && (last_name.errors?.required || last_name.errors?.rangeLength);" class="inline-icon error">
                          <span>Minimum 2 characters required.</span>!</span>
          </div>
        </div>
        <ng-container *ngIf="userModel.type === 'IndividualClient' || userModel.type === 'OrganisationalRepresentative' || userModel.type === 'Interpreter'">
          <div class="medium-6 columns">
              <div class="form-field">
                  <label>GENDER <span *ngIf="userModel.type === 'Interpreter'" mdTooltip="We would like to know your gender since some bookings require a Male or a Female"><i class="inline-icon-tip"></i></span></label>
                  <select [(ngModel)]="userModel.gender" name="gender" #gender="ngModel">

                      <option *ngFor="let item of  ['Male','Female','Other']" [value]="item">{{item.toUpperCase()}}</option>

                  </select>
              </div>
          </div>
          <ng-container *ngIf="userModel.gender === 'Other'">
              <div class="medium-6 columns">
                  <div class="form-field">
                      <label>SPECIFY YOUR GENDER</label>
                      <input id="genderSpecify" name="genderSpecify" type="text" placeholder="Please specify your gender" [(ngModel)]="otherGender"
                          name="genderSpecify" #genderSpecify="ngModel">
                  </div>
              </div>
          </ng-container>
        </ng-container>
        <div class="medium-6 columns">
          <div class="form-field">
            <label>EMAIL *
            </label>
            <input type="email" class="inline-icon-user-email" id="email" placeholder="Enter your email"
            [(ngModel)]="userModel.email" name="email" #email="ngModel" email required>
            <span *ngIf="email.touched && !email.errors?.email;" class="inline-icon inline-icon-valid valid"></span>
            <span *ngIf="(email.dirty || email.touched) && (email.errors?.required || email.errors?.email);" class="inline-icon error">
              <span>Email is required</span>!</span>
          </div>
        </div>
        <div class="medium-6 columns">
          <div class="form-field">
            <label>MOBILE PHONE * </label>
            <input type="tel" phone_num="true" placeholder="" [ngModel]="userModel.mobile | removeSpace" (ngModelChange)="userModel.mobile=$event" name="mobile" #mobile="ngModel" required digits [rangeLength]="[10, 11]">
            <span *ngIf="mobile.touched && mobile.errors?.rangeLength;" class="inline-icon error">
              <span>Minimum 10 digits required.</span>!
            </span>
            <span *ngIf="mobile.touched && mobile.errors?.required;" class="inline-icon error">
              <span>Minimum 10 digits required.</span>!
            </span>
            <span *ngIf="mobile.touched && (mobile.errors?.digits || !(userModel.mobile));"
                  class="inline-icon error">
                    <span>Enter digits only.</span>!
                  </span>
            <span *ngIf="mobile.touched && !mobile.errors?.rangeLength && !mobile.errors?.digits  && (userModel.mobile) && (userModel.mobile.trim().length);"
                  class="inline-icon inline-icon-valid valid"></span>
          </div>
        </div>
        <div *ngIf="userModel.type === 'IndividualClient' || userModel.type === 'OrganisationalRepresentative'" class="medium-6 columns">
          <div class="form-field">
            <label>PHONE * </label>
            <input type="tel" phone_num="true" placeholder="" [ngModel]="userModel.phone" (ngModelChange)="userModel.phone=$event" name="phone" #phone="ngModel" required>
            <span *ngIf="phone.touched && !phone.errors?.required;" class="inline-icon inline-icon-valid valid"></span>
            <span *ngIf="phone.touched && phone.errors?.required;" class="inline-icon error">
                      <span>Minimum 6 digits required.</span>!</span>
          </div>
        </div>

        <ng-container *ngIf="(userModel.type === 'Administrator' || userModel.type === 'BookingOfficer' ) && userModel.verified">
        <div class="medium-6 columns">
          <div class="form-field">
            <label>STATUS</label>
            <select name="selStatus" [(ngModel)]="selectedStatus">
              <option *ngFor="let i of userStatusArray" [value]="i.name" >{{i.name}}</option>
            </select>
          </div>
        </div>
        </ng-container>
        <div class="medium-6 columns">
          <div class="form-field">
            <label>CHANGE PICTURE:</label>
            <input type="file" accept="image/*" (change)="handleFileSelect($event)"
                   name="uploader"/>
          </div>
        </div>
      </div>
    </div>
    <app-interpreter [displayCalendar]="true" *ngIf="apiCalled && userModel.type === 'Interpreter'" [userModel]="userModel"></app-interpreter>
    <app-ind-client *ngIf="userModel.type === 'IndividualClient'" [userModel]="userModel"></app-ind-client>
    <app-org-rep *ngIf="userModel.type === 'Organisation' || userModel.type === 'OrganisationalRepresentative'"
                 [isEdit]="true" [userModel]="userModel"></app-org-rep>

    <app-interpreter-box
            [isPreffered]="true"
            [isUserPage]="true"
            [selectedInterpreters]="userModel.prefferedInterpreters"
            *ngIf="userModel.type === 'IndividualClient' || userModel.type === 'Organisation' || userModel.type === 'OrganisationalRepresentative'"
    ></app-interpreter-box>
    <br>
    <app-interpreter-box
            [isPreffered]="false"
            [isUserPage]="true"
            [selectedInterpreters]="userModel.prefferedInterpreters"
            *ngIf="userModel.type === 'IndividualClient' || userModel.type === 'Organisation' || userModel.type === 'OrganisationalRepresentative'"
    ></app-interpreter-box>
    <br>
    <ng-container *ngIf="userModel.type === 'IndividualClient' || userModel.type === 'Organisation' || userModel.type === 'OrganisationalRepresentative'">
      <h4 class="text-center">CERTIFICATIONS</h4>
      <p class="p-top-mrg" name="cert_required">IS ONE OF THE FOLLOWING REQUIRED FOR THE INTERPRETERS THAT WILL BE WORKING ON YOUR BOOKINGS? PLEASE SELECT MULTIPLE IF RELEVANT</p>
        <div class="row" id="cert-types">
          <div class="medium-3 columns"> <md-checkbox name="cb_blue_card" #cb_blue_card [(ngModel)]="userModel.blueCard">Blue CARD</md-checkbox> </div>
          <div class="medium-3 columns"> <md-checkbox name="cb_yellow_card" #cb_yellow_card [(ngModel)]="userModel.yellowCard">YELLOW CARD</md-checkbox> </div>
          <div class="medium-3 columns"> <md-checkbox name="cb_imminisations" #cb_imminisations [(ngModel)]="userModel.immunisations">IMMUNISATIONS</md-checkbox> </div>
          <div class="medium-3 columns"> <md-checkbox name="cb_police_check" #cb_police_check [(ngModel)]="userModel.policeCheck">POLICE CHECK</md-checkbox> </div>
          <div class="medium-3 columns"> <md-checkbox name="cb_form_27a" #cb_form_27a [(ngModel)]="userModel.form27A">FORM 27A</md-checkbox> </div>
          <div class="medium-3 columns"> <md-checkbox name="cb_work_with_child_check" #cb_work_with_child_check [(ngModel)]="userModel.workWithChildCheck">WORKING WITH CHILDREN CHECK</md-checkbox> </div>
       </div>
       <div class="medium-12 columns div-top-mrg">
        <div class="form-field">
          <label name="certReqQue">WILL THE BOOKING BE RECORDED AND PUBLISHED/DISTRIBUTED?</label>
          <md-radio-group name="rdbookingRecorded" [(ngModel)]="userModel.isRecordedBooking">
            <md-radio-button name="rdbookingRecordedYes" [value]="true">YES</md-radio-button>
            <md-radio-button name="rdbookingRecordedNo" [value]="false">NO</md-radio-button>
          </md-radio-group>
        </div>
      </div>
    </ng-container>
    <br>
    <section class="actions">
      <button name="edit_user" (click)="editUser(user_profile_form)"> SAVE </button>
    </section>
  </form>
</main>
</div>
