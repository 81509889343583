<section id="page-common">
    <app-booking-header [showButtons]='false' [bookingModel]='bookingModel' [oldModel]='oldPayments'
                        [paymentModel]='payments'></app-booking-header>
    <section id="job-booking">
        <main>
            <form #payroll_form="ngForm" class="common-form reduced-top">
                <app-booking-info [selectedBookingModel]="bookingModel"></app-booking-info>

                <h2>Payroll Details</h2>
                <fieldset name="bookingPayrollFieldset">
                    <section *ngFor="let payroll of payments.payroll_attributes; let i = index" class="payroll-card">
                        <section class="payroll-card__interpreter">
                            <header class="payroll-card__header">Interpreter {{i+1}}: {{payroll.interpreter_first_name
                                +' '+payroll.interpreter_last_name}}<br/>
                                <span class="payroll_id">ID: {{payroll.id}}</span>
                            </header>
                            <section class="payroll-card__content">
                                <section class="common-form">
                                    PAYROLL AS
                                    <div class="form-field">
                                        <select name="{{'payrollAs_' + i}}" [(ngModel)]="payroll.payroll_as"
                                                class="menu custom gray" disabled>
                                            <option [value]="payroll.payroll_as">{{payroll.payroll_as.toUpperCase()}}
                                            </option>
                                        </select></div>
                                </section>
                                <section class="recommended_payroll">
                                    RECOMMENDED
                                    <span class="note note_block note_green recommended_payroll_as"
                                          id="recommended_payroll_as">{{payroll.recommended.payroll_as.toUpperCase()}}</span>
                                </section>
                                <section>
                                    <div class="payroll-card__option">
                                        <md-checkbox name="{{'cbPayInterpreter_' + i}}"
                                                     [(ngModel)]="payroll.pay_interpreter"
                                                     (change)="cbChanged('payroll_attributes','pay_interpreter',i)">
                                            Pay interpreter
                                            <span class="note note_block note_green note_small" *ngIf="payroll.recommended.pay_interpreter">RECOMMENDED</span>
                                            <span class="note note_block note_small" *ngIf="!payroll.recommended.pay_interpreter">NOT RECOMMENDED</span>
                                        </md-checkbox>
                                    </div>
                                    <div class="payroll-card__option payroll-card__option_with-offset">
                                        <md-checkbox name="{{'cbPayTravel_' + i}}" [(ngModel)]="payroll.pay_travel"
                                                     [disabled]="!payroll.pay_interpreter"
                                                     (change)="cbChanged('payroll_attributes','pay_travel',i)">
                                            Pay travel
                                            <span class="note note_block note_green note_small" *ngIf="payroll.recommended.pay_travel">RECOMMENDED</span>
                                            <span class="note note_block note_small" *ngIf="!payroll.recommended.pay_travel">NOT RECOMMENDED</span>
                                        </md-checkbox>
                                    </div>
                                </section>
                            </section>
                            <app-payroll-additional-costs [payrollInvoice]="payroll" [index]="i" [parentForm]="payroll_form"
                                         *ngIf='isDSQ'     [isReadOnly]="!payroll.pay_interpreter"></app-payroll-additional-costs>
                            <app-payroll-time [payrollInvoice]="payroll" [index]="i" [parentForm]="payroll_form"
                                              [isReadOnly]="!payroll.pay_interpreter"></app-payroll-time>
                            <app-booking-rate-calulation *ngIf='isDSQ' [payrollInvoice]="payroll" [index]="i" 
                            [parentForm]="payroll_form" [isReadOnly]="!payroll.pay_interpreter">
                                
                            </app-booking-rate-calulation>
                        </section>
                        <section *ngIf="payments.invoice_attributes" class="payroll-card__client">
                            <header id="client-invoice" class="payroll-card__header">Invoice {{i+1}}: {{payments.invoice_attributes[i].organisation_name}} for {{payroll.interpreter_first_name
                                + ' ' + payroll.interpreter_last_name}}<br/>
                                <span class="payroll_id">ID: {{payments.invoice_attributes[i].id}}</span>
                            </header>
                            <section class="payroll-card__content">
                                <section>
                                    <div class="payroll-card__option">
                                        <md-checkbox name="{{'cbInvoiceClient_' + i}}"
                                                     [(ngModel)]="payments.invoice_attributes[i].invoice_client"
                                                     (change)="cbChanged('invoice_attributes','invoice_client',i)">
                                            Invoice client
                                            <span class="note note_block note_green note_small" *ngIf="payments.invoice_attributes[i].recommended.invoice_client">RECOMMENDED</span>
                                            <span class="note note_block note_small" *ngIf="!payments.invoice_attributes[i].recommended.invoice_client">NOT RECOMMENDED</span>
                                        </md-checkbox>

                                    </div>
                                    <div class="payroll-card__option payroll-card__option_with-offset">
                                        <md-checkbox name="{{'cbChargeTravel_' +i}}"
                                                     [(ngModel)]="payments.invoice_attributes[i].charge_travel"
                                                     [disabled]="!payments.invoice_attributes[i].invoice_client"
                                                     (change)="cbChanged('invoice_attributes','charge_travel',i)">
                                            Charge travel
                                            <span class="note note_block note_green note_small" *ngIf="payments.invoice_attributes[i].recommended.charge_travel">RECOMMENDED</span>
                                            <span class="note note_block note_small" *ngIf="!payments.invoice_attributes[i].recommended.charge_travel">NOT RECOMMENDED</span>
                                        </md-checkbox>
                                    </div>
                                </section>
                            </section>
                            <app-payroll-additional-costs *ngIf='isDSQ' [payrollInvoice]="payments.invoice_attributes[i]" [index]="i" 
                            [parentForm]="payroll_form" [isReadOnly]="!payments.invoice_attributes[i].invoice_client"  [isClient]="true">
                                
                            </app-payroll-additional-costs>
                            <app-payroll-time [payrollInvoice]="payments.invoice_attributes[i]" [isClient]="true"
                                              [index]="i" [parentForm]="payroll_form"
                                              [isReadOnly]="!payments.invoice_attributes[i].invoice_client"></app-payroll-time>
                            <app-booking-rate-calulation *ngIf='isDSQ' [payrollInvoice]="payments.invoice_attributes[i]" [index]="i" 
                            [parentForm]="payroll_form" [isReadOnly]="!payments.invoice_attributes[i].invoice_client"  [isClient]="true">
                                
                            </app-booking-rate-calulation>
                        </section>
                    </section>
                    <section *ngIf="!payments.payroll_attributes.length" class="payroll-card" #emptyPayrollContainer>
                        <section class="payroll-card__interpreter">
                            <header class="payroll-card__header">No Interpreters were allocated to this booking</header>
                        </section>
                    </section>
                    <br>
                </fieldset>
                <fieldset>
                    <ng-container *ngIf="isCurrentUserAdmin() || isCurrentUserBO()">
                        <div class="medium-12">
                            <div class="form-field">
                                <h2>CLAIM NOTES</h2>
                                <textarea type="text" class="inline-icon inline-icon-txt"
                                          [(ngModel)]="bookingModel.claim_notes" cols="30" rows="10" name="claim_notes">
                                </textarea>
                            </div>
                        </div>
                    </ng-container>
                </fieldset>
                <div *ngIf="payments.payroll_attributes.length > 0" class="actions">
                    <button name="btnSave" (click)="updatePayment(payroll_form)">
                        {{isDSQ && isStateCompleteOrCancelCharge() ? "Claim & Save" : "Save"}}</button>
                </div>
                <ng-container *ngIf="isCurrentUserAdmin() || isCurrentUserBO()">
                    <h2 name="payment-history" class="hidden-mobile">Payroll History</h2>
                    <table id="changes-history" class="custom-small-table">
                        <thead>
                            <th width="20%">Date and Time</th>
                            <th width="30%">Action</th>
                            <th>Changes</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let version of payments.versions; let i=index">
                                <td name="data_created_at">
                                    {{version.created_at_iso | date:'shortTime'}} on
                                    {{version.created_at_iso | momentTZDate : bookingModel.venue.state : bookingModel.venue.post_code}}
                                </td>
                                <td>
                                    <img height="30px" width="30px" src="" alt="">
                                    <span class="display-flx" name="data_action">
                                        {{version.booking_event_formatted()}}
                                        <br/> {{version.full_name()}}
                                    </span>
                                </td>
                                <td>
                                    <div name="data_changes">
                                        <div>
                                            <span *ngIf="version.model !== 'Booking'" style="font-style: oblique; font-weight: bolder">{{version.model}} ID: {{version.object_id}}</span>
                                            <span *ngIf="version.model === 'Booking'" style="font-style: oblique; font-weight: bolder">{{version.model}} ID: {{bookingModel.id}}</span>
                                        </div>
                                        <div *ngFor="let change_item_key of version.change_set_formatted_hash()">
                                            <b>{{change_item_key[0]}}: </b>{{change_item_key[1][0]}}
                                            <b>to</b>
                                            <span>{{change_item_key[1][1]}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </form>
        </main>
    </section>
</section>
