<section id="static-flow">
    <section id="submenu">
        <nav>
            <a href="javascript:void(0);" (click)="infoClick()" [ngClass]="{'active' : isActive('booking-job') || isActive('job-detail')}">Booking info</a>
            <a href="javascript:void(0);" *ngIf="!isCurrentUserInterpreter()" (click)="bookingDetailClick()" [ngClass]="{'active' : isActive('edit-booking')}">Booking details</a>
            <a href="javascript:void(0);" *ngIf="isCurrentUserAdminOrBookingOfficer() && !isActiveState('Cancelled_no_charge')" name="linkPayroll" (click)="payrollClick()" [ngClass]="{'active' : isActive('payroll-billing')}">Payroll & Billing</a>
        </nav>
        <div *ngIf='showButtons'>
            <button *ngIf='isCurrentUserAdminOrBookingOfficer() && !hasLinkId && !unlinkPress' (click)="linkBooking()" class="button inverse">Link booking</button>
            <button *ngIf='isCurrentUserAdminOrBookingOfficer() && hasLinkId' (click)="unlinkBooking()" class="button pink">Unlink</button>
            <button *ngIf='isCurrentUserAdminOrBookingOfficer() && isUnableToServiceOrCanceled_States()' (click)="undoCancelClick()" id="undoCancelBtn" class="button pink">Undo cancel</button>
            <button *ngIf='!isCancelOrUnable && !isCurrentUserIndividualClientOrOrg()' (click)="showDialogBoxClick(false)" class="button pink" name="unable_to_Service">Unable to Service</button>
            <button *ngIf="!isCancelOrUnable && isClientLinked" (click)="showDialogBoxClick(true)" class="button pink">Cancel Booking</button>
            <button *ngIf="isClientLinked" name="dupBtn" (click)="duplicateClick()" class="button inverse">Duplicate</button>
            <div class="sep"></div>
            <button (click)="saveClick()" name="saveBtn" [disabled]="!declinePressed && !invitePress && !autoInvitePress && !isAddedToQueue && !stopAutoInvitePress && !unAssignPress && !reAssignPress && !unlinkPress" class="button inverse">Save</button>
        </div>
        <div *ngIf="isCurrentUserInterpreter()">
            <button  [routerLink]="['/users/'+ getInterpreterId() +'/inbox/' + bookingModel.id]" class="button green">MESSAGE OFFICE</button>

            <button *ngIf="!disableReject && isInProgressOrAllocatedState()" (click)="showDialogBoxInterpreter(true)"
                    class="button pink">Decline
            </button>
            <div class="sep"></div>
            <button *ngIf="!disableAccept && isInProgressOrAllocatedState()" (click)="showDialogBoxInterpreter(false)"
                    class="button inverse">Accept
            </button>
        </div>
    </section>

    <section id="steps">
        <nav>
            <a href="javascript:void(0);" [ngClass]="{'active' : isActiveState('Requested'),
                     'passed' : isActiveState('Requested')}">Requested</a>
            <a href="javascript:void(0);" [ngClass]="{'active' : isActiveState('In_progress'),
                    'passed' : isActiveState('In_progress')}">In Progress</a>
            <a href="javascript:void(0);" [ngClass]="{'active' : isActiveState('Allocated'),
                    'passed' : isActiveState('Allocated')}">Allocated</a>
            <a href="javascript:void(0);" [ngClass]="{'active' : isActiveState('Service_completed') || isActiveState('Cancelled_chargeable')} ">
                {{isActiveState('Cancelled_chargeable') ? 'Cancelled Chargeable' : 'Service Completed' }}
            </a>
            <a href="javascript:void(0);" [ngClass]="{'active' :  isActiveState('Unable_to_service') || isActiveState('Cancelled_no_charge') || isActiveState('Claimed') || isActiveState('Cancelled_claimed') || isActiveState('Claimed_exported') || isActiveState('Cancelled_claimed_exported')} ">
                {{isActiveState('Cancelled_no_charge') ? 'Cancelled No Charge' :
                isActiveState('Unable_to_service') ? 'Unable To Service' : isActiveState('Claimed') ? 'Claimed' :
                isActiveState('Cancelled_claimed') ? 'Cancelled Claimed' : 'Finished' }}
            </a>
        </nav>
    </section>
</section>
