
	<section>
				<a id="lnkProfile" [ngClass]="{'active':  isActiveLink(linkName.profile)}" (click)="linkClick(linkName.profile, ['/user-management','profile'])" >Profile Info</a>

				<a id="lnkProfilePass"
				   [ngClass]="{'active':  isActiveLink(linkName.change_pass)}"
				   (click)="linkClick(linkName.change_pass, ['/user-management','secure_pass'])" >Change Password</a>

	</section>
			<section>
				<a id="lnkSettings" *ngIf="canShowLink('user-management')"
				   [ngClass]="{'active':  isActiveLink(linkName.usermanagement)}" (click)="linkClick(linkName.usermanagement,['/user-management'])" >Manage Users</a>

			</section>
