<app-mobile-header title="{{ param_id < 1 ? 'ADD BLOCKOUT' : 'EDIT BLOCKOUT'}}" backLink="/user-management/profile"></app-mobile-header>

<section id="submenu">
    <app-user-header id="main-submenu-links">
    </app-user-header>
</section>
<header class="text-center hidden-mobile">
    <div *ngIf="staff_availability;then content else other_content"></div>

    <ng-template #content>
        <h1>{{ param_id < 1 ? 'ADD STAFF AVAILABILITY' : 'EDIT STAFF AVAILABILITY'}}</h1>
    </ng-template>
    <ng-template #other_content>
        <h1>{{ param_id < 1 ? 'ADD BLOCKOUT' : 'EDIT BLOCKOUT'}}</h1>
    </ng-template>
    
</header>

<section id="page-add-blockout">
    <main>
        <form #user_blockout_form="ngForm" class="common-form compact with-mobile-transformation">
            <div class="row small-collapse">
                <div class="row-wrapper">
                    <div class="small-12 columns">
                        <div class="form-field">

                            <label>{{staff_availability? 'NAME AVAILABILITY *' : 'NAME BLOCKOUT *'}}</label>
                            <input type='text'
                                   name='blockout_name'
                                   placeholder="Enter {{staff_availability? 'Availability' : 'Blockout'}} Name"
                                   [(ngModel)]="availabilityBlock.name" [rangeLength]="[1,50]" required
                                   #blockout_name="ngModel"/>

                            <span *ngIf="blockout_name.touched &&  (!blockout_name.errors?.required && blockout_name.errors?.rangeLength);"
                                  class="inline-icon inline-icon-valid valid"></span>
                            <span *ngIf="blockout_name.touched && (blockout_name.errors?.required || blockout_name.errors?.rangeLength);"
                                  class="inline-icon error">
									<span>characters should be in between 1-50 .</span>!</span>
                        </div>
                    </div>
                    <div class="small-12 columns">
                        <div class="form-field">
                            <label>START DATE & TIME *</label>

                            <p-calendar name='dpEventDate_st' [(ngModel)]="start_time" (onClose)="onStartTimeChanged()"
                                        [stepMinute]="5" [defaultDate]="defaultDateTime" required #dpEventDate_st="ngModel"
                            showTime="true"  dateFormat="dd/mm/yy" hourFormat="12" (input)="checkMe($event)" (keydown.Tab)="assignMe('startTimeControl', $event)"></p-calendar>
                            
                            <span *ngIf="dpEventDate_st.touched &&  (!dpEventDate_st.errors?.required);"
                                  class="inline-icon inline-icon-valid valid"></span>
                            <span *ngIf="dpEventDate_st.touched && (dpEventDate_st.errors?.required);"
                                  class="inline-icon error">
									<span>Start Date is required.</span>!</span>
                        </div>
                    </div>
                    <div class="small-12 columns">
                        <div class="form-field">
                            <label>END TIME *</label>

                            <p-calendar name='dpEventDate_endtime' [(ngModel)]="end_time"  (onClose)="setDayMonthYear()"
                            [stepMinute]="5" [defaultDate]="defaultDateTime" 
                            required #dpEventDate_endtime="ngModel" [timeOnly]="true"
                             (input)="checkMe($event)" (onBlur)="assignMe('endTimeControl', $event)"
                                        hourFormat="12"></p-calendar>

                            <span *ngIf="dpEventDate_endtime.touched &&  (!dpEventDate_endtime.errors?.required);"
                                  class="inline-icon inline-icon-valid valid"></span>
                            <span *ngIf="dpEventDate_endtime.touched && (dpEventDate_endtime.errors?.required);"
                                  class="inline-icon error">
									<span>End Time is required.</span>!</span>
                            <span *ngIf="isTimeEqual()"
                                  class="inline-icon error">
									<span>End Time should be greater than start time</span>!</span>
                        </div>
                    </div>
                    <div class="small-12 columns">
                        <div class="form-field">
                            <label>RECURRING *</label>

                            <md-slide-toggle disabled="{{sideToggleCheck && param_id > 0? true: false}}"
                                    name='sldRecurring'
                                    [(ngModel)]="availabilityBlock.recurring" required #sldRecurring="ngModel">
                            </md-slide-toggle>
                        </div>
                    </div>
                    <div *ngIf="availabilityBlock.recurring">

                        <div class="small-12 columns">
                            <div class="form-field">
                                <label>FREQUENCY *</label>

                                <select disabled="{{sideToggleCheck && param_id > 0? true: false}}" [(ngModel)]="availabilityBlock.frequency" name="availabilityBlock_frequency" (change)="checkIsWeekely()"
                                        #availabilityBlock_frequency="ngModel" required
                                        aria-label="A">
                                    <option *ngFor="let frequency_name of ['daily', 'weekly']"
                                            [value]="frequency_name">{{frequency_name.toUpperCase()}}
                                    </option>

                                </select>
                                <span *ngIf="availabilityBlock_frequency.touched &&  (!availabilityBlock_frequency.errors?.required);"
                                class="inline-icon inline-icon-valid valid"></span>
                                <span *ngIf="availabilityBlock_frequency.touched && (availabilityBlock_frequency.errors?.required);"
                                class="inline-icon error">
                                  <span>Frequency is required.</span>!</span>
                            </div>
                        </div>
                        <div class="medium-12 columns" *ngIf="availabilityBlock.recurring">
                            <div *ngIf="isWeekely">
                                <div class='form-field'>
                                    <label>REPEAT ON*</label>
                                    <div style="margin-left: -16px;">
                                        <div *ngFor="let day of repeat_days let i = index" class='medium-2 columns'  style="width: 13.66667%;">
                                            <md-checkbox [(ngModel)]="day.selected" name="{{day.value}}" [disabled]="sideToggleCheck" [align]='right'
                                                [required]='isRecurringBooking'>
                                                {{day.display}}
                                            </md-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="small-12 columns">

                            <div class="form-field">
                                <label>END DATE *</label>

                                <p-calendar name='dpEventDate_end' [(ngModel)]="end_date"
                                            required #dpEventDate_end="ngModel" dateFormat="dd/mm/yy"></p-calendar>

                                <span *ngIf="dpEventDate_end.touched &&  (!dpEventDate_end.errors?.required);"
                                      class="inline-icon inline-icon-valid valid"></span>
                                <span *ngIf="dpEventDate_end.touched && (dpEventDate_end.errors?.required);"
                                      class="inline-icon error">
                                    <span>End Date is required.</span>!</span>
                                    
                                <span *ngIf="isEndDateGreterthenTostartDate(start_time, end_date)"
                                      class="inline-icon error">
									<span>End date should be equal or greater than start time</span>!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div >
            <div class="manage-buttons">
                <button name="cancel_blockout" (click)="getRoute()"  class="button decline">BACK</button>
                <button *ngIf="availabilityBlock.id > 0 && !(availabilityBlock.booking_id > 0)" name="delete_blockout" (click)="deleteBlockout()" class="button decline">DELETE</button>
                <button *ngIf="!(availabilityBlock.booking_id > 0) &&!sideToggleCheck" name="save_blockout" (click)="saveBlockouts(user_blockout_form)"  class="button">SAVE</button>
            </div>
        </div>
    </main>
</section>
