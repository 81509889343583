<section id="page-login" [ngClass]="vicDeafImg ? 'vicdeaf-img' : 'other-img'">
  <section>
    <aside>
      <div class="logo-container">
        <a href="#"><img src="assets/img/logo.png" alt=""/></a>
        <img class="dsq-service-logo" src="assets/img/dsq-service-logo.png" alt="" />
      </div>

      <form (ngSubmit)="onSubmit()" class="common-form" #loginForm="ngForm">
        <div class="form-field">
          <label><i class="icon-email"></i><span>EMAIL</span></label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            [(ngModel)]="model.email"
            name="email"
            #email="ngModel"
            email
            required
          />
          <span *ngIf="email.touched && email.valid" class="inline-icon inline-icon-valid valid"></span>
          <ng-container *ngIf="email.dirty || email.touched">
            <span *ngIf="email.errors?.required" class="inline-icon error">
              <span>This field is required.</span>!
            </span>
            <span *ngIf="email.invalid" class="inline-icon error"> <span>This field is invalid.</span>! </span>
          </ng-container>
        </div>

        <div class="form-field">
          <label><i class="icon-lock"></i> PASSWORD</label>
          <input
            id="pass"
            type="password"
            placeholder="Enter your password"
            [(ngModel)]="model.password"
            name="pass"
            #password="ngModel"
            required
            minlength="6"
          />
          <span *ngIf="password.touched && password.valid" class="inline-icon inline-icon-valid valid"></span>
          <ng-container *ngIf="password.dirty || password.touched">
            <span *ngIf="password.errors?.required" class="inline-icon error">
              <span>This field is required.</span>!
            </span>
            <span *ngIf="password.invalid" class="inline-icon error">
              <span>Minimum 6 characters required.</span>!
            </span>
          </ng-container>
        </div>
        <div align="center">
          <a name="lnkResetPass" [routerLink]="['/reset']">Forgot your password?</a><br />
          <button type="submit" [disabled]="loginForm.form.invalid" name="login_user" class="login button">
            Log In
          </button>
        </div>
        <div class="separator">
          <div>
            <span>or</span>
          </div>
        </div>
        <div align="center">
          <p>Don't have an account?</p>
          <a [routerLink]="['/register', 'pre']" class="signup button">CREATE AN ACCOUNT</a>
        </div>
        <p class="error" [innerHTML]="errorMessage"></p>
      </form>
    </aside>
    <main>
      <h1>INVESTING BACK INTO<br />THE DEAF COMMUNITY</h1>
    </main>
  </section>
</section>

<app-modal
  *ngIf="insecurePassword"
  [hasBackdrop]="true"
  title="Please Update Your Password"
  (close)="insecurePassword = false"
>
  <ng-template #content>
    <p>
      AC+ has upgraded security, and all passwords have been reset. Please
      <a target="_blank" href="http://auslanconnections.com.au/whatsnew/">click here</a> for more information, and
      <a (click)="changePassword()">click here</a>
      to change your password, according to our new password rules.
    </p>
  </ng-template>
</app-modal>
