<app-mobile-header title="BOOKINGS" [showBackLink]="false"></app-mobile-header>
<section id="page-jobs" class="no-margin-mobile no-padding-mobile">
  <app-booking-filter (appBookingFilter)="getPaginatedBooking()"></app-booking-filter>
  <app-booking-list (onPageEmit)="onPageEmit($event)" [p]="page" [totalItems]="totalItems" [bookingList]='bookings' (onBookingFilter)="getPaginatedBooking()"></app-booking-list>
</section>

<nav *ngIf="isCurrentUserInterpreter()" id="footer-mobile">
  <button (click)="setActiveFilter('all')" [ngClass]="{'active' : isActiveFilter('all')}"> All </button>
  <button (click)="setActiveFilter('allocated')" [ngClass]="{'active' : isActiveFilter('allocated')}"> Allocated </button>
  <button (click)="setActiveFilter('open')" [ngClass]="{'active' : isActiveFilter('open')}"> Open </button>
  <button (click)="setActiveFilter('new_invites')" [ngClass]="{'active' : isActiveFilter('new_invites')}"> New Invites </button>
</nav>