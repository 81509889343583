<div class="separator"></div>
<header>
    <h4 class="text-center">BILLING DETAILS</h4>
</header>
<div class="row" ngForm="billingFields" #billingFields="ngForm">
    <div class="row-wrapper">
        <div class="medium-6 columns">
            <div class="form-field">
                <label>CONTACT FIRST NAME * </label>

                <input type="text" class="inline-icon inline-icon-user-circle valid"
                       [readonly]="isReadOnly"
                       placeholder="Enter your first name"
                       [(ngModel)]="primaryContact.first_name" name="cn_first_name"
                       #cn_first_name="ngModel" [rangeLength]="[2, 20]" required>
                <span *ngIf="cn_first_name.touched && !cn_first_name.errors?.required && !cn_first_name.errors?.rangeLength;"
                      class="inline-icon inline-icon-valid valid"></span>
                <span *ngIf="cn_first_name.touched && (cn_first_name.errors?.required || cn_first_name.errors?.rangeLength);"
                      class="inline-icon error">
											<span>Minimum 2 characters required.</span>!
                </span>
            </div>
        </div>
        <div class="medium-6 columns">
            <div class="form-field">
                <label>CONTACT LAST NAME *</label>

                <input [readonly]="isReadOnly"
                       type="text" class="inline-icon inline-icon-user-circle valid" placeholder="Enter your last name"
                       [(ngModel)]="primaryContact.last_name" name="cn_last_name"
                       #cn_last_name="ngModel" [rangeLength]="[2, 30]" required>
                <span *ngIf="cn_last_name.touched && !cn_last_name.errors?.required && !cn_last_name.errors?.rangeLength;"
                      class="inline-icon inline-icon-valid valid"></span>
                <span *ngIf="cn_last_name.touched && (cn_last_name.errors?.required || cn_last_name.errors?.rangeLength);"
                      class="inline-icon error">
													<span>Minimum 2 characters required.</span>!</span>
            </div>
        </div>
        <div class="medium-6 columns">
            <div class="form-field">
                <label class="icon-email">
                    CONTACT EMAIL * </label>
                <input [readonly]="isReadOnly"
                       type="email" id="cn_email" placeholder="Enter your email" [(ngModel)]="primaryContact.email"
                       name="cn_email" #cn_email="ngModel" email
                       required>
                <span *ngIf="cn_email.touched && !cn_email.errors?.email;"
                      class="inline-icon inline-icon-valid valid"></span>
                <span *ngIf="(cn_email.dirty || cn_email.touched) && (cn_email.errors?.required || cn_email.errors?.email);"
                      class="inline-icon error">
										<span>Email is required</span>!</span>
            </div>
        </div>

        <div class="medium-6 columns">
            <div class="form-field">
                <label class="icon-email">
                    CONTACT NUMBER * </label>
                <input type="tel" [readonly]="isReadOnly"
                       id="phone" placeholder="Enter your contact number"
                       [ngModel]="primaryContact.phone_number | removeSpace" name="cn_phone" #cn_phone="ngModel" digits
                       (ngModelChange)="primaryContact.phone_number=$event"
                       [rangeLength]="[10, 11]"
                       required>

                <span *ngIf="cn_phone.touched && cn_phone.errors?.rangeLength;"
                      class="inline-icon error">
                                         <span>Minimum 10 digits required.</span>!
                                    </span>
                <span *ngIf="cn_phone.touched && cn_phone.errors?.required;" class="inline-icon error">
                                    <span>Minimum 10 digits required.</span>!
                                    </span>
                <span *ngIf="cn_phone.touched && (cn_phone.errors?.digits || !(primaryContact.phone_number));"
                      class="inline-icon error">
                                    <span>Enter digits only.</span>!
                                    </span>
                <span *ngIf="cn_phone.touched && !cn_phone.errors?.rangeLength && !cn_phone.errors?.digits  && (primaryContact.phone_number) && (primaryContact.phone_number.trim().length);"
                      class="inline-icon inline-icon-valid valid"></span>
            </div>
        </div>
    </div>
</div>
<br>
<app-address class="billing-address" [isReadOnly]="isReadOnly" [prefix]="address_title" [address]="billingAddress"
             [parentForm]="billingFields" [canCalculateDistance]="canCalculateDistance"></app-address>

