<div class="row small-collapse top">
    <div class="row-wrapper">
        <span>
            <b>Select the reason for cancellation</b>
        </span>
        <form name="cancelReasonForm" class="common-form reduced-bottom">
            <div class="columns medium-8">
                <div class="form-field">
                    <label for="cancel-reasons">
                        Reason
                    </label>
                    <select name="cancel_reasons" id="cancel-reasons" #cancel_reasons="ngModel" [(ngModel)]="selectedReason" (change)="onChange()"
                        required>
                        <option *ngFor="let reason of cancelReasons" [value]="reason">{{reason.toUpperCase()}}</option>
                    </select>
                    <span *ngIf="cancel_reasons.touched && !cancel_reasons.errors?.required;" class="inline-icon inline-icon-valid valid"></span>
                    <span *ngIf="cancel_reasons.touched && (cancel_reasons.errors?.required);" class="inline-icon error">!
                    </span>
                </div>
            </div>
            <div *ngIf="selectedReason === 'other'" class="columns medium-12">
                <div class="form-field">
                    <label>Please Specify</label>
                    <textarea rows="5" (keyup)="onChange()" name="other_reason" #other_reason="ngModel" [(ngModel)]="otherReason" placeholder="Enter the reason"></textarea>
                </div>
            </div>
        </form>
    </div>
</div>