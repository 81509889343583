<section id="page-common">
  <h1>
    <br> </h1>
  <section id="job-booking">
    <main>
      <form #report_form="ngForm" class="common-form reduced-top">
        <h2>Reporting</h2>
        <section class="payroll-card">
          <section class="payroll-card__content width-full">
            <section>
              <label>SELECT EXPORT</label>
              <select name="exportType" class="menu custom gray" [(ngModel)]="exportType" (change)="onChange()" required>
                <option value="master">Master Report</option>
                <option *ngIf="isBusinessVicdeaf();else dsq_options" value="Vicdeaf">Vicdeaf billing & payroll</option>
                <ng-template #dsq_options>
                  <option value="dsq_weekly_payroll">Payroll</option>
                  <option value="dsq_weekly_billing">Weekly Billing</option>
                  <option value="dsq_monthly">Monthly Billing</option>
                  <option value="dsq_org_monthly">Organisation Monthly Report</option>
                </ng-template>
              </select>
            </section>
            <section class="no-border width-date-section">
              <label>START DATE</label>
              <p-calendar name='startDate' [(ngModel)]="date_from" dateFormat="dd/mm/yy" dateOnly="true" required></p-calendar>
            </section>
            <section class="no-border width-date-section">
              <label>END DATE</label>
              <p-calendar name='endDate' [(ngModel)]="date_to" dateFormat="dd/mm/yy" dateOnly="true" required></p-calendar>
            </section>
            <section *ngIf="exportType === 'dsq_weekly_billing' || exportType === 'dsq_monthly' " class="no-border width-date-section">
              <label>SELECT OPTION</label>
              <md-checkbox name="cbDoExport" [(ngModel)]="isExported"> Apply Export Status </md-checkbox>
            </section>

            <section class="no-border">
              <div>
                <button name="btnExport" class="btn-margin" (click)="exportClick(report_form)">EXPORT</button>
              </div>
            </section>
          </section>
        </section>
        <ng-container *ngIf="exportType === 'dsq_org_monthly'">
          <section class="payroll-card" style="margin-top: 3%">
            <section class="payroll-card__content width-full">
              <section style="width: 25%">
                <label>SELECT ORGANISATION</label>
                <angular2-multiselect [data]="allOrgs" [(ngModel)]="selectedOrgs" name="org-multiselect"
                                      [settings]="dropdownSettings">
                </angular2-multiselect>
              </section>
              <section>
                <label style="padding-left: 13px">STATUS</label>
                <div class="row" name="status">
                  <md-checkbox class="medium-4" name="cbUTS" #cbUTS [(ngModel)]="_cbUTS" (change)="statusChange('unable_to_service',cbUTS)">Unable to Serve</md-checkbox>
                  <md-checkbox class="medium-4" name="cbCC" #cbCC [(ngModel)]="_cbCC" (change)="statusChange('cancelled_chargeable',cbCC)">Cancelled Chargeable</md-checkbox>
                  <md-checkbox class="medium-4" name="cbCNC" #cbCNC [(ngModel)]="_cbCNC" (change)="statusChange('cancelled_no_charge',cbCNC)">Cancelled no Charge</md-checkbox>
                  <md-checkbox class="medium-4" name="cbCnCl" #cbCnCl [(ngModel)]="_cbCnCl" (change)="statusChange('cancelled_claimed',cbCnCl)">Cancelled Claimed</md-checkbox>
                  <md-checkbox class="medium-4" name="cbCCE" #cbCCE [(ngModel)]="_cbCCE" (change)="statusChange('cancelled_claimed_exported',cbCCE)">Cancelled Claim Exported</md-checkbox>
                  <md-checkbox class="medium-4" name="cbCE" #cbCE [(ngModel)]="_cbCE" (change)="statusChange('claimed_exported',cbCE)">Claim Exported</md-checkbox>
                  <md-checkbox class="medium-4" name="cbSC" #cbSC [(ngModel)]="_cbSC" (change)="statusChange('service_completed',cbSC)">Service Completed</md-checkbox>
                  <md-checkbox class="medium-4" name="cbCl" #cbCl [(ngModel)]="_cbCl" (change)="statusChange('claimed',cbCl)">Claim</md-checkbox>
                </div>
              </section>
            </section>
          </section>
        </ng-container>
      </form>
    </main>
  </section>
</section>
