<div class="md-dialog add-interpreters">
  <md-dialog-content>
    <header>
      <h1>All Interpreters</h1>
      <section class="user-search-bar search-bar">
        <input type="text" class="search-bar-interpreter" name="search" id="search"
               (keyup.enter)="getPage(1)" [(ngModel)]="searchParams"
               (click)="'' !== searchParams && clearSearch()" placeholder="Search...">
        <button class="oval" (click)="getPage(1)"><i class="icon-glass"></i></button>

      </section>
      <button (click)="closeDialog()"><i class="icon-close"></i></button>
    </header>

    <main>
      <div *ngIf="state_where_most_bookings_occur;then content else other_content"></div>
      <ng-template #content>
        <p id="no_interpreter" *ngIf="apiHasbeenCalled && interpreterList.length === 0">There are currently no interpreters for {{state_where_most_bookings_occur}}</p>
        <section id="interpreters-list">
          <section name="interpreters" *ngFor="let interpreter of interpreterList | paginate: { itemsPerPage: 10, currentPage: currentPage, totalItems: totalItems } let ind = index;"
                   [ngClass]="{'selected': interpreter.interpreter_id === checkedInterpreter,
                   'interpreter_added': !isLocallyRemovedFromArray(interpreter.interpreter_id) && isAlreadyAdded(interpreter.interpreter_id)  === true}"
                   (click)="isInterpreterSelectable(interpreter.interpreter_id)">
            <img class="ImgSize" (click)="checkedInterpreter = interpreter.interpreter_id ;" src="{{interpreter.photo_url}}"  alt="">
            <span>{{interpreter.first_name}} {{interpreter.last_name}}</span>
          </section>
        </section>
      </ng-template>
      <ng-template #other_content>
        <p id="select_state_first">Please select the State where most of your bookings occur.</p>
      </ng-template>
    </main>
  </md-dialog-content>
  <div class="pagination">
    <div class="element element_inline ui-grid-col-6 left text-left" id="displayTxt" *ngIf="totalItems > 0">
      {{'Displaying ' + (((currentPage - 1) * 10) + 1) + ' - '
      + ( interpreterList.length < 10 ? totalItems : (currentPage * 10)) + ' of ' + totalItems + ' Interpreter' +
      (interpreterList.length > 1 ? 's' : '')}}
    </div>

    <div class="element element_inline ui-grid-col-6 right text-right">
      <pagination-controls class="my-pagination" *ngIf="totalItems >= 10"
                           (pageChange)="getPage($event)"></pagination-controls>
    </div>

  </div>

  <div>
    <md-dialog-actions>
      <button *ngIf="checkedInterpreter > -1" name="selectBtn" (click)="addSelectedInterpreter()" class="button"> SELECT </button>
      <button name="noBtn"  (click)="closeDialog()" class="button red"> CANCEL </button>
    </md-dialog-actions>
  </div>
</div>
	