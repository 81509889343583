<div id="page-signup-organisation" class="update-password">
  <header id="header">
    <a style="background: none;" href="/"
      ><img name="auslanLogo" alt="Auslan Connections" width="72" height="46" src="assets/img/auslan-logo.png" />
    </a>
  </header>

  <header id="header-mobile">
    <a style="background: none;" href="/"
      ><img name="auslanLogo" alt="Auslan Connections" width="72" height="46" src="assets/img/auslan-logo.png" />
    </a>
  </header>

  <section>
    <header>
      <h1>Update Password</h1>
      <p>Fields with * are required</p>
    </header>
    <app-user-password-form [resetPasswordMsg]="true" (success)="onSubmit()"></app-user-password-form>
  </section>
</div>
