<header id="header-mobile">
    <button *ngIf="showBackLink" [routerLink]="backLink" class="icon-back"></button>

    <h1 class="align-left" style="padding-left: 50px;">{{title}}</h1>

    <section>
        <a  *ngIf='isCurrentUserNotIndividualClientOrOrg()' [routerLink]="['/users/',getActiveUserId(),'inbox']" class="header__message"><i class="icon-messages"></i></a>
        <a  *ngIf='isUserIndividualClientOrOrgRep()' [routerLink]="['/booking-management','create-booking']" (click)="refreshBooking()"><span name="mobile-new-booking" class="rectangle new-icon">+ NEW</span></a>
        <ul class="dropdown menu click-handler" data-disable-hover="true" data-click-open="true" data-dropdown-menu onclick="this.classList.toggle('opened');">
            <li>
                <a class="header__profile" id="lnkProfile_mobile">
                    <img class="ImgSize" src={{getPicturePath()}}></a>
                <ul class="menu">
                    <li><a id="lnkIndProfile_mobile" [routerLink]="['/user-management','profile']"><i class="icon-user"></i>
                        Profile</a></li>
                    <li><a id="lnkLogout_mobile" (click)='userLogout()'><i class="icon-logout"></i>
                        Log Out</a></li>
                </ul>
            </li>
        </ul>
    </section>
</header>