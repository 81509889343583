<main class="hidden-mobile">
    <section class="jobs withOffset">
        <table id="jobs-responsive" class="custom">
            <thead>
            <tr>
                <th class="first-th" width="6%">
                    <span class="order" [ngClass]="getSortOrder('job')" (click)="sort('job')">Job</span>
                    <form #filterByBookingIds="ngForm" class="common-form compact"
                          (ngSubmit)="filter('booking_ids', filterByBookingIds.value.booking_ids)" novalidate>
                        <input type="text" name="booking_ids" (blur)="filter('booking_ids', filterByBookingIds.value.booking_ids)"  [(ngModel)]="bookingFilter.booking_ids">
                    </form>
                </th>
                <th width="7%" name="booking_status" *ngIf="isCurrentUserAdminOrBookingOfficer()">
                    <span class="order" [ngClass]="getSortOrder('booking_status')" (click)="sort('booking_status')">Status</span>
                    <ul class="dropdown menu custom" id="booking-status" data-dropdown-menu>
                        <li>
                            <a href="javascript:void(0)">{{underScoreToSpaces(filterStatus())}}</a>
                            <ul class="menu smaller">
                                <li *ngFor="let status of statusList()">
                                    <a href="javascript:void(0)"
                                       [ngClass]="isDropdownItemActive('booking_status', status)"
                                       (click)="filter('booking_status', status, true)">
                                       {{underScoreToSpaces(status)}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </th>
                <th>
                    <span class="order" [ngClass]="getSortOrder('state')" (click)="sort('state')">State</span>
                    <ul class="dropdown menu custom" id="booking-state" data-dropdown-menu>
                        <li>
                            <a href="javascript:void(0)">{{underScoreToSpaces(bookingFilter.state)}}</a>
                            <ul class="menu smaller">
                                <li *ngFor="let state of stateList()">
                                    <a href="javascript:void(0)"
                                       [ngClass]="isDropdownItemActive('state', state)"
                                       (click)="filter('state', state, true)">
                                      {{underScoreToSpaces(state)}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </th>
                <th width="13%">
                    <span class="order"
                          [ngClass]="getSortOrder('start_time')"
                          (click)="sort('start_time')">Date</span>
                    <div class="oneRow">
                        <span>From:</span>
                        <input type="date" name="date_from" [(ngModel)]="bookingFilter.date_from"
                               (blur)="filter('date_from', bookingFilter.date_from)"
                               (keyup.enter)="filter('date_from', bookingFilter.date_from)">
                    </div>
                    <div class="oneRow">
                        <span>To:</span>
                        <input type="date" name="date_to" [(ngModel)]="bookingFilter.date_to"
                        (blur)="filter('date_to', bookingFilter.date_to)"
                        (keyup.enter)="filter('date_to', bookingFilter.date_to)">
                    </div>
                </th>

                <th width="12%">
                    <span class="order"
                          [ngClass]="getSortOrder('organisation')"
                          (click)="sort('organisation')">Org</span>
                    <form #filterByOrg="ngForm" class="common-form compact"
                          (ngSubmit)="filter('organisation', filterByOrg.value.organisation)" novalidate>
                        <input type="text" name="organisation" (blur)="filter('organisation', filterByOrg.value.organisation)" [(ngModel)]="bookingFilter.organisation">
                    </form>
                </th>
                <th>
                    <span class="order"
                          [ngClass]="getSortOrder('booking_client')"
                          (click)="sort('booking_client')">Client</span>
                    <form #filterByClientName="ngForm" class="common-form compact"
                          (ngSubmit)="filter('client_name', filterByClientName.value.client_name)" novalidate>
                        <input type="text" name="client_name" (blur)="filter('client_name', filterByClientName.value.client_name)" [(ngModel)]="bookingFilter.client_name">
                    </form>
                </th>
                <th>
                    <span class="order"
                          [ngClass]="getSortOrder('suburb')"
                          (click)="sort('suburb')">Suburb</span>
                    <form #filterBySuburb="ngForm" class="common-form compact"
                          (ngSubmit)="filter('suburb', filterBySuburb.value.suburb)" novalidate>
                        <input type="text" name="suburb" (blur) = "filter('suburb', filterBySuburb.value.suburb)" [(ngModel)]="bookingFilter.suburb">
                    </form>
                </th>
                <th width="10%">
                    <span class="order" [ngClass]="getSortOrder('interpreter')" (click)="sort('interpreter')">Interpreter</span>
                    <form #filterByInterpreter="ngForm" class="common-form compact"
                          (ngSubmit)="filter('interpreter', filterByInterpreter.value.interpreter_name)" novalidate>
                        <input type="text" name="interpreter_name"  (blur) ="filter('interpreter', filterByInterpreter.value.interpreter_name)" [(ngModel)]="bookingFilter.interpreter_name">
                    </form>
                </th>
                <th width="10%">
                    <span class="order" [ngClass]="getSortOrder('booking_type')" (click)="sort('booking_type')">Nature</span>
                    <ul class="dropdown menu custom" id="booking-type" data-dropdown-menu>
                        <li>
                            <a href="javascript:void(0)">{{underScoreToSpaces(bookingFilter.booking_type)}}</a>
                            <ul class="menu smaller">
                                <li *ngFor="let booking_type of assignmentCategoryList()">
                                    <a href="javascript:void(0)"
                                       [ngClass]="isDropdownItemActive('booking_type', booking_type)"
                                       (click)="filter('booking_type', booking_type, true)">
                                      {{underScoreToSpaces(booking_type)}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </th>
                <th>
                    <span class="order" [ngClass]="getSortOrder('method_type')" (click)="sort('method_type')">Method</span>
                    <ul class="dropdown menu custom" id="method" data-dropdown-menu>
                        <li>
                            <a href="javascript:void(0)">{{filterMethodType(bookingFilter.method_type)}}</a>
                            <ul class="menu smaller">
                                <li *ngFor="let method_type of bookingMethodTypes()">
                                    <a href="javascript:void(0)" (click)="filter('method_type', method_type)"><i></i>{{filterMethodType(method_type)}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </th>
                <th>
                    <span class="order" [ngClass]="getSortOrder('service_type')" (click)="sort('service_type')">Service Type</span>
                    <ul class="dropdown menu custom" id="service-type" data-dropdown-menu>
                        <li>
                            <a href="javascript:void(0)">{{underScoreToSpaces(bookingFilter.service_type)}}</a>
                            <ul class="menu smaller">
                                <li *ngFor="let service_type of bookingServiceTypes()">
                                    <a href="javascript:void(0)"
                                       [ngClass]="isDropdownItemActive('service_type', service_type)"
                                       (click)="filter('service_type', service_type, true)">
                                      {{service_type}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </th>
                <th *ngIf="isCurrentUserAdminOrBookingOfficer()">
                    <span class="order" [ngClass]="getSortOrder('notice')" (click)="sort('notice')">Notice</span>
                </th>
                <!-- th>
                    <span class="order asc">Updated</span>
                </th -->
                <th *ngIf="isCurrentUserAllowed()" width="7%">
                    <span>Actions</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr [ngClass]="{'active': isSelectedBooking(booking.id), selected: bookingSelector.hasSelected(booking.id)}"
                *ngFor="let booking of bookingList | paginate: { itemsPerPage: +(selectedPageSize), currentPage: p, totalItems: totalItems } let i = index"
                (click)="setClickedRow(booking)">
                <td class="bookingID">
                    <div class="actions-container">

                        <span>{{booking.id | PrettyID}}</span>
                        <i *ngIf="booking.is_preferred" class="icon-requested preff-icon" style="color:#00c974"></i>

                        <span class="linkId"
                              id="{{'linkId_'+i}}"
                              (click)="linkIdClicked('#'+booking.link_id); $event.stopPropagation();"
                              *ngIf="isCurrentUserAdminOrBookingOfficer() && !!booking.link_id">
                            <a>#{{booking.link_id}}</a>

                        </span>
                    </div>
                </td>
                <td class="text-center" *ngIf="isCurrentUserAdminOrBookingOfficer()">
                    <i class="status"
                       [ngClass]="{'icon-small icon-small-green': booking.status === 'green', 'icon-small icon-small-red': booking.status === 'red', 'icon-small icon-small-yellow': booking.status === 'orange'}">
                    </i>
                </td>
                <td style="text-transform: capitalize">{{stateToString(booking.state)}}</td>
                <td>
                    <div class="job-date">
                        <span>
                            <i class="icon-time hidden-desktop"></i>
                            <span>
                                <span>{{booking.venue.start_time_iso | timeShort : booking.venue.state : booking.venue.post_code}} - {{booking.venue.end_time_iso | timeShort : booking.venue.state : booking.venue.post_code}}</span>
                                <span>
                                    {{booking.venue.start_time_iso | momentTZDate : booking.venue.state : booking.venue.post_code }}
                                </span>
                            </span>
                        </span>
                        <i *ngIf="isCurrentUserInvitedInterpreter(booking.interpreters) && !isCurrentUserAllowed()" name="icon_requested" class="icon-requested"></i>
                    </div>
                </td>
                <td class="truncated-text" title="{{booking.client.organisation_name}}">{{booking.client.organisation_name}}</td>
                <td class="truncated-text">{{isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted(booking) ?
                    booking.deaf_person.first_name +
                    ' ' +
                    booking.deaf_person.last_name : '***** ********' }}
                </td>
                <td class="truncated-text" title="{{booking.venue.suburb}}">{{booking.venue.suburb}}</td>
                <td class="truncated-text"  id='needs_tobe_fill'>
                    <ng-container *ngIf="booking.interpreters_required <= 2 && didInterpreterAccepted(booking.interpreters).length > 0 ;">
                        <span *ngFor="let user of didInterpreterAccepted(booking.interpreters)">
                                    <strong *ngIf="isCurrentUser(user.id); else sim_user_Block">
                                        {{user?.first_name  + ' ' + user?.last_name}}
                                    </strong>
                                    <ng-template #sim_user_Block>
                                        {{user?.first_name  + ' ' + user?.last_name[0] + '.'}}
                                    </ng-template>
									<br/>
								</span>
                    </ng-container>

                    <span *ngIf="stateToString(booking.state) !== 'Unable to service' && booking.interpreters_required >= 3 && booking.interpreters_required != didInterpreterAccepted(booking.interpreters).length ;">Needs to be filled ({{didInterpreterAccepted(booking.interpreters).length }}/{{booking.interpreters_required}})</span>
                    <span *ngIf="stateToString(booking.state) !== 'Unable to service' && booking.interpreters_required >= 3 && booking.interpreters_required == didInterpreterAccepted(booking.interpreters).length ;">Multiple ({{didInterpreterAccepted(booking.interpreters).length}}/{{booking.interpreters_required}})</span>
                    <span  name='isAllocated' *ngIf="stateToString(booking.state) !== 'Unable to service' && booking.interpreters_required == 2 && didInterpreterAccepted(booking.interpreters).length == 1 ;"><br/>To be filled </span>
                    <span *ngIf="stateToString(booking.state) !== 'Unable to service' && booking.interpreters_required == 2 && didInterpreterAccepted(booking.interpreters).length < 1 ;">To be filled<br/>To be filled </span>
                    <span *ngIf="stateToString(booking.state) !== 'Unable to service' && booking.interpreters_required == 1 && didInterpreterAccepted(booking.interpreters).length < 1 ;">To be filled </span>

                </td>
                <td class="truncated-text">
                    <ng-container *ngIf="booking.documents_attributes.length > 0">
                        <a href="javascript:void(0)" class="attach"><i class="icon-attach"></i></a>
                    </ng-container>
                    {{booking.raw_nature_of_appointment}}
                </td>
                <td>{{booking.method_name}}</td>
                <td class="truncated-text">{{booking.service_type}}</td>
                <!-- td><span class="underline">02-06 TS<br>7:15 A</span></td -->
                <td *ngIf="isCurrentUserAdminOrBookingOfficer()">{{calculateDaysAgo(booking.created_at, booking.venue.start_time_iso)}}</td>
                <td *ngIf="isCurrentUserAllowed()">
                    <div class="checkbox-group">
                        <div class="checkbox-item" *ngIf="isBookingInterpreterInvitedOrRejected(booking)">
                            <input
                                type="checkbox"
                                [attr.id]="'checkbox' + i"
                                [attr.name]="'checkbox' + i"
                                [ngModel]="bookingSelector.hasSelected(booking.id)"
                                (ngModelChange)="bookingSelector.toggle(booking.id)"
                                (click)="$event.stopPropagation()"
                            />
                            <label [attr.for]="'checkbox' + i" (click)="$event.stopPropagation()"></label>
                        </div>
                        <ul id="actions-list" class="dropdown menu custom actions" data-dropdown-menu>
                            <li>
                                <a href="javascript:void(0)"><i class="icon-more-icon"></i></a>
                                <ul class="menu right-side green">
                                    <li>
                                        <a [routerLink]="['/booking-management/' + booking.id + '/job-detail']">
                                            <i class="icon-check-mobile"></i>
                                            Accept
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/booking-management/' + booking.id + '/job-detail']">
                                            <i class="icon-help"></i>
                                            Tentative
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/booking-management/' + booking.id + '/job-detail']">
                                            <i class="icon-decline"></i>
                                            Decline
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </section>
</main>
<section class="hidden-desktop">
    <section id="page-common">
        <ul class="booking-mobile">
            <li class="booking-mobile__item" (click)="setClickedRow(booking)"
                [ngClass]="{selected: bookingSelector.hasSelected(booking.id)}"
                *ngFor="let booking of bookingList | paginate: { itemsPerPage: +(selectedPageSize), currentPage: p, totalItems: totalItems } let i = index">
                <section class="booking-mobile__date">
                    {{ booking.id | PrettyID }}
                    <i *ngIf="booking.is_preferred" class="icon-requested preff-icon" style="color:#00c974"></i>
                    <span data-title="Link ID" *ngIf="isCurrentUserAdminOrBookingOfficer() && !!booking.link_id" class="linkId">
                        <a>#{{booking.link_id}}</a>
                    </span>
                </section>
                <section class="booking-mobile__details">
                    <div class="booking-mobile__time">
                        <span class="booking-mobile__weekDay">{{to_weekDay(booking.venue.start_time_iso |  momentTZDate : booking.venue.state : booking.venue.post_code)[0]}}</span>
                        <span class="booking-mobile__day">{{to_weekDay(booking.venue.start_time_iso |  momentTZDate : booking.venue.state : booking.venue.post_code)[2]}}</span>
                        <span class="booking-mobile__month">{{to_weekDay(booking.venue.start_time_iso |  momentTZDate : booking.venue.state : booking.venue.post_code)[1]}}</span>
                        <span class="booking-mobile__time_bold">{{booking.venue.start_time_iso | timeShort : booking.venue.state : booking.venue.post_code}}
                        - {{booking.venue.end_time_iso | timeShort : booking.venue.state : booking.venue.post_code}}
                        </span>
                    </div>
                    <div class="booking-mobile__agent">
                        {{booking.client.organisation_name}}
                    </div>
                    <div class="booking-mobile__location">{{booking.venue.suburb}}</div>
                </section>
                <section *ngIf="isCurrentUserInterpreter()" class="booking-mobile__status">
                  <div class="checkbox-group">
                    <div class="checkbox-item" *ngIf="isBookingInterpreterInvitedOrRejected(booking)">
                      <input
                          type="checkbox"
                          [attr.id]="'checkbox' + i"
                          [attr.name]="'checkbox' + i"
                          [ngModel]="bookingSelector.hasSelected(booking.id)"
                          (ngModelChange)="bookingSelector.toggle(booking.id)"
                          (click)="$event.stopPropagation()"
                      />
                      <label [attr.for]="'checkbox' + i" (click)="$event.stopPropagation()"></label>
                    </div>
                    <span *ngIf="interpreterAllowed(booking, 'red')" class="badge badge_pink badge_wide badge_opaque50">OPEN</span>
                    <span *ngIf="stateToString(booking.state) === 'Cancelled chargeable' "
                          style="color: #FD4264">CANC.</span>
                    <span *ngIf="interpreterAllowed(booking,'gray')" class="badge badge_gray badge_wide badge_opaque50">OPEN</span>
                    <i *ngIf="interpreterAllowed(booking, 'green')" class="booking-mobile__icon icon-check-mobile"></i>
                  </div>
                </section>
            </li>
        </ul>
    </section>
</section>
<div class="pb-4em pagination">
    <ng-container *ngIf="bookingList.length && maxPaginationSize > 5 && totalItems > 0">
        <div class="element element_inline" id="displayTxt">
            {{'Displaying ' + (((p - 1) * (+selectedPageSize)) + 1) + ' - '
            + ( (p - 1) * (+selectedPageSize) + bookingList.length) + ' of ' + totalItems + ' Booking' +
            (bookingList.length > 1 ? 's' : '')}}
        </div>
        <div class="dropup">
            <button class="dropbtn"> {{selectedPageSize}} ^</button>
            <div class="dropup-content">
                <a *ngFor="let i of ['10','20','30','40','50']" (click)="onPageSizeChange(i)">{{i}}</a>
            </div>
        </div>
    </ng-container>
    <div class="element element_inline">
        <br>
        <pagination-controls  [maxSize]="maxPaginationSize"
                              previousLabel="Prev" class="my-pagination" *ngIf="totalItems > parseNumber(selectedPageSize)"
                             (pageChange)="setPage($event)"></pagination-controls>
    </div>
</div>
<div class="floating-bar" *ngIf="bookingSelector.count >= 1">
  <div class="action-group">
    <button class="button pink" *ngIf="canRejectMultiple()" (click)="rejectMultiples()">Decline {{bookingSelector.count}}</button>
    <button class="button inverse" (click)="acceptMultiples()">Accept {{bookingSelector.count}}</button>
  </div>
</div>
<div class="floating-bar-spacer"></div>
