<h2>Job Details</h2>
<table id="job-details-responsive" class="custom-small-table">
  <thead>
    <tr>
      <th>Job</th>
      <th>Date</th>
      <th>Time</th>
      <th>Method</th>
      <th>Service Type</th>
      <th>Org</th>
      <th *ngIf='!isCurrentUserAdminOrBookingOfficer() && !isClientOrOrgRep() && !isInterpreterStatusAccepted (selectedBookingModel)'>Suburb</th>
      <ng-container *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted (selectedBookingModel)'>
        <th>Client</th>
        <th *ngIf="(isCurrentUserAdminOrBookingOfficer() || isInterpreter()) && selectedBookingModel.deaf_person.ur_number">UR</th>
        <th>Address</th>
        <th>Venue</th>
      </ng-container>
      <th>Nature</th>
      <th>Specific</th>
      <th *ngIf="isCurrentUserAdminOrBookingOfficer() || (isInterpreter() && selectedBookingModel.invitation_summary)">Invitation Summary</th>
      <ng-container *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted (selectedBookingModel)'>
        <th>People</th>
        <th>Contact First Name</th>
        <th>Contact Last Name</th>
        <th>Contact Mobile</th>
        <ng-container *ngIf="isInterpreterStatusAccepted(selectedBookingModel)">
          <th *ngIf="isFieldHasValue(selectedBookingModel.tech_contact_first_name) &&
          isFieldHasValue(selectedBookingModel.tech_contact_last_name)">Tech Contact Name</th>
          <th *ngIf="isFieldHasValue(selectedBookingModel.tech_contact_email)">Tech Contact Email</th>
          <th *ngIf="isFieldHasValue(selectedBookingModel.tech_contact_phone_number)">Tech Contact Phone</th>
          <th *ngIf="isFieldHasValue(selectedBookingModel.audio_source)">Audio Source</th>
          <th *ngIf="isFieldHasValue(selectedBookingModel.tech_platform)">Tech Platform</th>
          <th *ngIf="isFieldHasValue(selectedBookingModel.login_id_link)">Login ID Link</th>
        </ng-container>
        <ng-container *ngIf="isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted(selectedBookingModel)">
          <th>Parking Availability</th>
        </ng-container>
        <th>Special Instructions</th>
        <ng-container *ngIf="isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() && isFieldHasValue(selectedBookingModel.notes)">
          <th>Notes</th>
        </ng-container>
        <ng-container *ngIf="showGenderPreference">
          <th>Gender Preference</th>
        </ng-container>
        <th name="_attach">Attached</th>
      </ng-container>
      <th *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep()'>Date Added</th>
      <ng-container *ngIf='isCurrentUserAdminOrBookingOfficer()'>
        <th>Days Notice</th>
        <th>Updated</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-title="JOB NUMBER">
        {{selectedBookingModel.id | PrettyID}}
        <i *ngIf="selectedBookingModel.is_preferred" class="icon-requested preff-icon" style="color:#00c974"></i>

        <span data-title="Link ID" *ngIf="isCurrentUserAdminOrBookingOfficer() && !!selectedBookingModel.link_id" class="linkId">
          <a>#{{selectedBookingModel.link_id}}</a>
        </span>
      </td>
      <td data-title="DATE"><span>{{selectedBookingModel.venue.start_time_iso | momentTZDate : selectedBookingModel.venue.state : selectedBookingModel.venue.post_code}}</span></td>
      <td data-title="TIME">
        <span>{{selectedBookingModel.venue.start_time_iso | timeShort : selectedBookingModel.venue.state : selectedBookingModel.venue.post_code}} - {{selectedBookingModel.venue.end_time_iso | timeShort : selectedBookingModel.venue.state : selectedBookingModel.venue.post_code}}</span>
      </td>
      <td data-title="METHOD">{{selectedBookingModel.method_name}}</td>
      <td data-title="SERVICE TYPE">{{selectedBookingModel.service_type}}</td>
      <td data-title="ORGANISATION">
        <a id="lnkOrgName"
                   [routerLink]="['/init']"
                   [queryParams]="{selectedRole: getQueryableRole('bookable_type'), uid: selectedBookingModel.bookable_id, edit_user:stringifyUser('bookable_id')}">
                   {{selectedBookingModel.client.organisation_name}}
                    </a>
      </td>
       <td class="_suburb" data-title="SUBURB" *ngIf='!isCurrentUserAdminOrBookingOfficer() && !isClientOrOrgRep() && !isInterpreterStatusAccepted (selectedBookingModel)'>
        <a target="_blank"
           href="{{'https://www.google.com.au/maps/place/' +selectedBookingModel.venue.suburb}}">
          {{selectedBookingModel.venue.suburb}}
        </a>
      </td>
      <ng-container  *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted (selectedBookingModel)'>
        <td class="_client" data-title="CLIENT">
          {{
          selectedBookingModel.deaf_person.first_name +
          ' ' +
          selectedBookingModel.deaf_person.last_name}}
        </td>
        <td id="urNumber" data-title="UR" *ngIf="(isCurrentUserAdminOrBookingOfficer() || isInterpreter()) && selectedBookingModel.deaf_person.ur_number">{{ selectedBookingModel.deaf_person.ur_number }}</td>
        <td class="_address" data-title="ADDRESS" *ngIf="isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted (selectedBookingModel)">
          <a target="_blank"
             href="{{'https://www.google.com.au/maps/place/' + selectedBookingModel.venue.street_number + ' ' + selectedBookingModel.venue.street_name + ' ' + selectedBookingModel.venue.suburb}}">
            {{selectedBookingModel.venue.unit_number? selectedBookingModel.venue.unit_number + '/' : ''}}{{selectedBookingModel.venue.street_number + ' ' + selectedBookingModel.venue.street_name + ' ' + selectedBookingModel.venue.suburb
            + ' ' + selectedBookingModel.venue.state + ' ' + (selectedBookingModel.venue.post_code > 0 ? selectedBookingModel.venue.post_code : '' ) }}
          </a>
        </td>
        <td class="_venue" data-title="VENUE">{{selectedBookingModel.venue.title}}</td>
      </ng-container>
      <td data-title="NATURE">{{selectedBookingModel.nature_of_appointment}}</td>
      <td data-title="SPECIFIC">{{selectedBookingModel.specific_nature_of_appointment}}</td>
      <td id="invitationSummary" data-title="INVITATION SUMMARY" *ngIf="isCurrentUserAdminOrBookingOfficer() || (isInterpreter() && selectedBookingModel.invitation_summary)" [innerHTML]="findAndReplaceWithLink(selectedBookingModel.invitation_summary)"></td>
      <ng-container *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted (selectedBookingModel)'>
        <td class="numberofpeople" data-title="NUMBER OF PEOPLE">
          {{selectedBookingModel.venue.expected_attendance}}
        </td>
        <td class="contactfirstname" data-title="CONTACT FIRST NAME">{{selectedBookingModel.primaryContact.first_name}}</td>
        <td class="contactlastname" data-title="CONTACT LAST NAME">{{selectedBookingModel.primaryContact.last_name}}</td>
        <td class="contactmobile" data-title="CONTACT MOBILE">{{selectedBookingModel.primaryContact.mobile_number}}</td>
        <ng-container *ngIf="isInterpreterStatusAccepted (selectedBookingModel)">
          <td *ngIf="isFieldHasValue(selectedBookingModel.tech_contact_first_name) && isFieldHasValue(selectedBookingModel.tech_contact_last_name)" class="tech_name" data-title="TECH CONTACT NAME">{{selectedBookingModel.tech_contact_first_name + ' ' + selectedBookingModel.tech_contact_last_name}}</td>
          <td *ngIf="isFieldHasValue(selectedBookingModel.tech_contact_email)" class="tech_email" data-title="TECH CONTACT EMAIL">{{selectedBookingModel.tech_contact_email}}</td>
          <td *ngIf="isFieldHasValue(selectedBookingModel.tech_contact_phone_number)" class="tech_phone" data-title="TECHCONTACT PHONE NUMBER">{{selectedBookingModel.tech_contact_phone_number}}</td>
          <td *ngIf="isFieldHasValue(selectedBookingModel.audio_source)" class="audio_source" data-title="AUDIO SOURCE">{{selectedBookingModel.audio_source}}</td>
          <td *ngIf="isFieldHasValue(selectedBookingModel.tech_platform)" class="tech_platform" data-title="TECH PLATFORM">{{selectedBookingModel.tech_platform}}</td>
          <td *ngIf="isFieldHasValue(selectedBookingModel.login_id_link)" class="login_link" data-title="LOGIN ID LINK" [innerHTML]="findAndReplaceWithLink(selectedBookingModel.login_id_link)"></td>
        </ng-container>
        <ng-container *ngIf="isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted(selectedBookingModel)">
          <td class="parking-availability" data-title="PARKING AVAILABILITY">{{selectedBookingModel.venue.parking_type}}</td>
        </ng-container>
        <td data-title="SPECIAL INSTRUCTIONS FOR INTERPRETERS" class="new-line specialinstruction" [innerHTML]="findAndReplaceWithLink(selectedBookingModel.special_instructions)">
        </td>
      </ng-container>
      <ng-container *ngIf="isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() && isFieldHasValue(selectedBookingModel.notes)">
        <td class="_notes" data-title="NOTES" [innerHTML]="findAndReplaceWithLink(selectedBookingModel.notes)"></td>
      </ng-container>
      <ng-container *ngIf="showGenderPreference">
        <td data-title="GENDER PREFERENCE" class="booking_gender_preference">{{selectedBookingModel.gender_preference}}</td>
      </ng-container>
      <ng-container *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep() || isInterpreterStatusAccepted (selectedBookingModel)'>
        <td align="center" data-title="ATTACHMENTS">
          <ul *ngIf="selectedBookingModel.documents_attributes.length > 0"
                           class="dropdown menu custom"
                           data-dropdown-menu>
            <li>
              <a class="icon-attach"></a>
              <ul class="menu">
                <li *ngFor="let attachment of selectedBookingModel.documents_attributes">
                  <a  download
                  href="{{attachment.url}}">
                    <i class="icon-attach"></i> {{attachment.file_name}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </td>
      </ng-container>
      <td data-title="DATE ADDED" *ngIf='isCurrentUserAdminOrBookingOfficer() || isClientOrOrgRep()'>{{selectedBookingModel.created_at | date}}</td>
      <ng-container *ngIf='isCurrentUserAdminOrBookingOfficer()'>
        <td data-title="DAYS NOTICED">{{calculateDaysAgo(selectedBookingModel.created_at, selectedBookingModel.venue.start_time_iso)}}</td>
        <td data-title="UPDATED"><span class="underline">{{selectedBookingModel.updated_at | date}}</span></td>
      </ng-container>
    </tr>
  </tbody>
</table>
