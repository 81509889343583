
<header>
  <h4 class="text-center">INDIVIDUAL CLIENT'S DETAILS</h4>
</header>
<div ngForm="indClientForm" #indClientForm="ngForm" class="row small-collapse">
  <div class="row-wrapper">
    <div class="medium-6 columns">
      <div class="form-field">
        <label>UR NUMBER</label>
        <input type="text" class="valid" placeholder="Enter UR Number"
                [(ngModel)]="userModel.ur_id" name="ur_id"
                #ur_id="ngModel"  digit>
    
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
          <label>EAF ID</label>
          <input type="text" class="valid" placeholder="Enter EAF ID"
                  [(ngModel)]="userModel.eaf_id" name="eaf_id"
                  #eaf_id="ngModel"  digit>
      
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>NDIS ID</label>
        <input type="text" class="valid" placeholder="Enter NDIS ID"
                [(ngModel)]="userModel.ndis_id" name="ndis_id"
                #ndis_id="ngModel"  digit>

      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>NDIS BUDGET LIMIT</label>
        <input type="tel" class="valid" placeholder="Enter NDIS BUDGET LIMIT"
                [(ngModel)]="userModel.ndis_budget_limit" name="ndis_budget_limit"
                #ndis_budget_limit="ngModel" >

      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>NDIS VALIDITY START DATE</label>
        <input type='date' name='dpEventDateStart'
               [ngModel]="userModel.ndis_validity_start_date" (ngModelChange)="userModel.ndis_validity_start_date = $event"
               #dpEventDateStart="ngModel" />


      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>NDIS VALIDITY END DATE</label>
        <input type='date' name='dpEventDateEnd'
               [ngModel]="userModel.ndis_validity_end_date" (ngModelChange)="userModel.ndis_validity_end_date = $event"
               #dpEventDateEnd="ngModel" />

      </div>
    </div>

    <div class="medium-6 columns">
      <div class="form-field">
        <label>COMM. PREFERENCE *</label>
        <select name="comm_pref" [(ngModel)]="userModel.preferred_contact_method">
          <option value="email_only">EMAIL</option>
          <option value="email_and_sms">SMS AND EMAIL</option>
        </select>
      </div>
    </div>
  </div>
</div>
<br>

<app-address [parentForm]="indClientForm" [prefix]="address_title" [address]="userModel.address_attributes" [canCalculateDistance]="canCalculateDistance"></app-address>

<div class="row small-collapse">
  <div class="row-wrapper">
    <div class="medium-6 columns">
      <div class="form-field">
        <label>{{'Is your billing address the same as your address?'.toUpperCase()}}</label>
        <md-checkbox type="checkbox"
                     [(ngModel)]="userModel.billingAddressIsSame" name="billingAddressIsDifferent_confirmation"
                     #billingAddressIsDifferent_confirmation="ngModel">Yes
        </md-checkbox>
      </div>
    </div>
  </div>
</div>


<app-billing-account [primaryContact]="userModel.individual_client_primary_contact"
                     [billingAddress]="userModel.individual_client_billing_account.organisation_billing_address"
                     [parentForm]="indClientForm" [canCalculateDistance]="canCalculateDistance"></app-billing-account>


<div class="row small-collapse">
  <div class="row-wrapper">
    <div class="medium-12 columns">
      <div class="form-field">
        <label>EXT. REFERENCE NUM</label>
        <input name="ext-ref-no" [(ngModel)]="userModel.individual_client_billing_account.external_reference" placeholder="Enter External Reference Number">
      </div>
    </div>
    <div class="medium-6 columns">
      <div class="form-field">
        <label>HOW DID YOU FIND OUT ABOUT US?</label>
        <select [(ngModel)]="userModel.reffered_by">
          <option value="A DEAF PERSON TOLD ME">A DEAF PERSON TOLD ME</option>
          <option value="AN INTERPRETER TOLD ME">AN INTERPRETER TOLD ME</option>
          <option value="INTERNET SEARCH ENGINE">INTERNET SEARCH ENGINE</option>
          <option value="GOOGLE">GOOGLE</option>
          <option value="YELLOW PAGES">YELLOW PAGES</option>
          <option value="WORD OF MOUTH">WORD OF MOUTH</option>
          <option value="OTHER">OTHER</option>
        </select>
      </div>
    </div>
    <div class="medium-12 columns">
      <div class="form-field">
        <label>IF OTHER THEN PLEASE DESCRIBE HERE</label>
        <textarea [disabled]="userModel.reffered_by !== 'OTHER'" [(ngModel)]="userModel.reffered_other" cols="30" rows="10"
                  class="inline-icon inline-icon-txt"></textarea>

      </div>
    </div>
    <div class="medium-12 columns">
      <div class="form-field">
        <label>SPECIAL INSTRUCTIONS FOR INTERPRETERS</label>
        <textarea type="text" class="inline-icon inline-icon-txt"
                  cols="30" rows="10"
                  [(ngModel)]="userModel.special_instructions" name="special_instructions"
                  #special_instructions="ngModel"></textarea>

      </div>
    </div>
  </div>
</div>
<br>
