<app-mobile-header title="MESSAGES" backLink="/booking-management" [showBackLink]="true"></app-mobile-header>


<section id="page-messages">
    <section *ngIf='isCurrentUserAdminOrBookingOfficer()' id="filters">
        <section class="search-bar">
            <button><i class="icon-glass"></i></button>
            <form #filterBySearch="ngForm" class="common-form compact" (ngSubmit)="searchInterpreter()" novalidate>
                <input type="text" name="search" id="search" (blur)="searchInterpreter()" [(ngModel)]="searchInterpreterQuery" (click)="'' !== searchInterpreterQuery && clear()" placeholder="Search Interpreter By Name">
            </form>
            <button name="btnSearch">
                <i (click)="searchInterpreter()" class="icon-glass"></i>
            </button>
        </section>
    </section>
    <main class="hidden-mobile">
        <h1>MESSAGES</h1>
    </main>
</section>
<section class="messages">
    <section *ngIf='isCurrentUserAdminOrBookingOfficer()'
         class="messages__conversations" [ngClass]="{'messages__conversations_withPagination': totalItems > 10}">
        <section class="messages__conversations-wrapper" id="conversations-wrapper">

        <ng-container
                    *ngFor="let mesg of messageThreads  | paginate: { itemsPerPage: 10, currentPage: messageThreadPage ,totalItems: totalItems } let i = index">
                <section class="messages__conversation"
                         [ngClass]="{'messages__conversation_active': i === selectedMessageThread}">
                        <div>
                            <md-checkbox name="checkRead_{{i+1}}" [disabled]="mesg.unread_messages_count === 0" [checked]="mesg.unread_messages_count === 0" (change)="isRead($event, mesg.id, i)"></md-checkbox>
                        </div>
                    <a name="{{mesg.user_name.replace(' ', '-')}}" (click)='showSingleMessageThread(i)'>
                        <div *ngIf='mesg.messages_count > 0'>
                            <span>{{mesg.last_messaging_time | date: 'h:mma' }}</span> |
                            <span>{{mesg.user_name}}</span>
                            <p class="messages__conversation-text">{{mesg.last_message_body.substring(0,75)}}</p>
                        </div>
                        <div *ngIf='mesg.messages_count < 1'>
                            <span>{{mesg.last_messaging_time | date: 'h:mma' }}</span> |
                            <span>{{mesg.user_name}}</span>
                            <p class="messages__conversation-text"> ..... </p>
                        </div>

                    </a>
                </section>
            </ng-container>

        </section>
    </section>
    <section class="messages__wrapper" [ngClass]="{'messages__wrapper_aside': isCurrentUserAdminOrBookingOfficer()}"
             id="messages-wrapper">
        <div class="text-center">
            <button name="load_previous_messages_btn" *ngIf="showLoadMore === true && messageCount > messagePage * 10"
                    (click)="loadMore()" class="button inline-block">Load Previous Messages
            </button>
        </div>
        <perfect-scrollbar (psYReachStart)="scrollReachedAtTop()" id="perfect_scrollbar"  (psYReachEnd)="scrollReachedAtBottom()" [config]="config" [scrollIndicators]="false">
            <ng-container *ngFor="let message of messages">

                <section class="messages__message" [ngClass]="{ 'messages__message_left' : message.sender.type !== 'Interpreter'
        , 'messages__message_right' : message.sender.type === 'Interpreter' }">

                    <figure class="messages__figure">
                        <img src="{{message.sender.photo_url}}" alt=""></figure>
                    <section class="messages__inner">
                        <div class="messages__title">
                            <strong class="messages__person">{{message.sender.first_name}} {{
                                message.sender.last_name.charAt(0)}}</strong>
                            <span class="messages__time">
                <i class="icon-time"></i> {{message.created_at | date: ' d MMM  y | h:mma' }}</span>
                        </div>
                        <p class="messages__text" *ngFor="let newContent of message.message_body.split('\n')">{{newContent}}</p>
                        <button *ngIf="hasTag(message)"
                                (click)="onTagClicked(message.tag)"
                                class="messages__button">
                            {{message.tag}}
                        </button>
                    </section>
                </section>
            </ng-container>
        </perfect-scrollbar>
    </section>
    <div *ngIf="totalItems > 10 && isCurrentUserAdminOrBookingOfficer()"
         class="element element_inline messages__pagination">
        <pagination-controls (pageChange)="getPage($event)" class="my-pagination ele"></pagination-controls>
    </div>
    <form class="messages__form" [ngClass]="{'messages__form_reduced':isCurrentUserAdminOrBookingOfficer()}"
          style="position: fixed;">
        <button *ngIf='isTagShow' class="button messages__formButton">{{message_tag }}
        </button>
        <section class="messages__formInner">
            <textarea [(ngModel)]="message_body" required (keyup)="checkEmpty()" name="message_body"
                      class="messages__formInput"></textarea>
            <button [disabled]="!message_body" name="messages__formSend" (click)="sendMessage()"
                    class="messages__formSend"></button>
        </section>
    </form>
</section>

