<ng-template #modal>
  <div class="md-dialog update-user-password">
    <md-dialog-content>
      <header>
        <h1 *ngIf="title">{{ title }}</h1>
        <button name="cancel-popup" (click)="closeDialog$.next()"><i class="icon-close"></i></button>
      </header>
      <main>
        <div>
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </main>
    </md-dialog-content>
    <div *ngIf="actions">
      <md-dialog-actions>
        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </md-dialog-actions>
    </div>
  </div>
</ng-template>
